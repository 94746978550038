import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BsCloudUpload } from "react-icons/bs";
import Pdf from "react-to-pdf";

import './style/os-imprime.css';
import logoCG from './assets/logo_cg_t.png';

const ref = React.createRef();

let _idOS;
let _planejador = "";
let aDentistaTelefone = [];
let aDentistaEmail = [];
let aIdDentista = [];
let _idUsuarioDentista = "";
let aItensConfirmados = [];

export default function Edit() {
   let _servico = "";

   let nomeArquivo = "OS_" + _idOS;
   let _idUsuario = getStorage("_idUsuario");

   let [form, setForm] = useState({});
   let [recordItens, setRecordItens] = useState([]);

   const params = useParams();
   const navigate = useNavigate();

   let RecordItens = (props) => (
         <div className="row pad-bottom0">
           <div className="col-7 pad-bottom0">
             <p id="itDescricao" className="pad-bottom0 pad-top0">{props.recordItem.descricao}</p>
           </div>

           <div className="col-2 pad-bottom0 keep-right">
             <p id="itQuantidade" className="pad-bottom0 pad-top0">{props.recordItem.quantidade}</p>
           </div>

           <div className="col-2 pad-bottom0 keep-right">
             <p id="itValor" className="pad-bottom0 pad-top0">{props.recordItem.itemPrecoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
           </div>

         </div>
   );

   function itensConfirmados() {
     let somaTotal = 0;
     return recordItens.map((recordItem) => {
       somaTotal = somaTotal + recordItem.itemPrecoTotal;
       document.getElementById("somaTotal").value = somaTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
       return (
         <RecordItens
           recordItem={recordItem}
           key={recordItem.idItem}
         />
       );
     });
   }

   function getStorage(prm) {
     let _prm = window.localStorage.getItem(prm);
     if(_prm === "null"){
       navigate("/login");
     }else{
       if(_prm) {
         return _prm;
       }else{
         navigate("/login");
       }
     }
   }

   function desenhaForm(value){
     let elemCG = document.getElementById('div-cg');
     let elemPO = document.getElementById('div-po');

     let prm;
     if( value.servicoOpcoes ) {
       prm = value.servicoOpcoes;
     }else{
       prm = value.param;
     }
     if( prm === "servicoCirurgiaGuiada" ){
       _servico = "Cirurguia Guiada";
     }
     if( prm === "servicoPrototipagemOssea" ){
       _servico = "Prototipagem Ossea";
     }
     if( prm === "servicoCirurgiaOrtognatica" ){
       _servico = "Cirurgia Ortognática";
     }
     if( prm === "servicoEnceramentoDigital" ){
       _servico = "Enceramento Digital";
     }
     if( prm === "servicoGuiaPeriodontal" ){
       _servico = "Guia Periodontal";
     }
     if( prm === "servicoGuiaEndodontico" ){
       _servico = "Guia Endodôntico";
     }
     if( prm === "servicoPlacaMiorrelaxante" ){
       _servico = "Placa Miorrelaxante";
     }
     if( prm === "servicoModeloImpresso" ){
       _servico = "Modelo Impresso";
     }
     if( prm === "servicoOutro" ){
       _servico = "Outros Serviços";
     }
     document.getElementById("servico").value = _servico;
   }

   useEffect(() => {
     async function fetchData() {

       let n = 0;

       _idOS = params.id.toString();
       let _tipoUsuario = getStorage("_tipoUsuario");
       let _idUsuario = getStorage("_idUsuario");

       const response = await fetch(`https://www.zaping.io:8448/os/` + _tipoUsuario + `/` + _idUsuario + `/` + _idOS);

       if (!response.ok) {
         toast.error('ERRO!');
         return;
       }

       let record = await response.json();

       let _dentista = record[0].idUsuarioDentista;
       document.getElementById("dentista").disabled = true;
       let _dentistaTelefone = record[0].dentistaTelefone;

       let nodes;
       if(_tipoUsuario === "dentista"){
         nodes = document.getElementById("area-dentista").getElementsByTagName('*');
         for(n = 0; n < nodes.length; n++){
           nodes[n].disabled = false;
         }
         nodes = document.getElementById("area-restante").getElementsByTagName('*');
         for(n = 0; n < nodes.length; n++){
           nodes[n].disabled = true;
           nodes[n].style.color = "black";
         }

         nodes = document.getElementById("area-dentista2").getElementsByTagName('*');
         for(n = 0; n < nodes.length; n++){
           nodes[n].disabled = false;
         }
       }else{
  //       nodes = document.getElementById("area-dentista").getElementsByTagName('*');
  //       for(n = 0; n < nodes.length; n++){
  //         nodes[n].disabled = false;
  //         nodes[n].style.color = "black";
  //       }
  //       nodes = document.getElementById("area-restante").getElementsByTagName('*');
  //       for(n = 0; n < nodes.length; n++){
  //         nodes[n].disabled = false;
  //       }
       }

       let _dentistaEmail = "";

       if(record[0].dentista){
         _idUsuarioDentista = record[0].idUsuarioDentista;
         _dentista = record[0].idUsuarioDentista;
         _dentistaTelefone = record[0].dentistaTelefone;
         _dentistaEmail = record[0].dentistaEmail;
       }

       record = record[0].resGetOS;

       if(record[0].faturamento){
         if(record[0].faturamento.itens){
           aItensConfirmados = record[0].faturamento.itens;
           setRecordItens(aItensConfirmados);
         }
       }else{
//         document.getElementById("botFaturar").disabled = true;
         aItensConfirmados = [];
         setRecordItens(aItensConfirmados);
       }

       if (!record) {
         toast.error('OS não Encontrada!');
         navigate("/os-lista");
         return;
       }

       setForm(record[0]);
       document.getElementById("dataOS").value = new Date(record[0].dataOS).toLocaleDateString('pt-BR');
        if(record[0].dataEnvioParaRadiologia){
          if(record[0].dataEnvioParaRadiologia.length > 0){
            document.getElementById("dataEnvioParaRadiologia").value = new Date(record[0].dataEnvioParaRadiologia).toLocaleDateString('pt-BR');
          }
        }
        if(record[0].dataEnvioParaPlanejador){
          if(record[0].dataEnvioParaPlanejador.length > 0){
            document.getElementById("dataEnvioParaPlanejador").value = new Date(record[0].dataEnvioParaPlanejador).toLocaleDateString('pt-BR');
          }
        }
        if(record[0].dataRecebimentoPlanejador){
          if(record[0].dataRecebimentoPlanejador.length > 0){
            document.getElementById("dataRecebimentoPlanejador").value = new Date(record[0].dataRecebimentoPlanejador).toLocaleDateString('pt-BR');
          }
        }
        if(record[0].dataConcluidoPeloPlanejador){
          if(record[0].dataConcluidoPeloPlanejador.length > 0){
            document.getElementById("dataConcluidoPeloPlanejador").value = new Date(record[0].dataConcluidoPeloPlanejador).toLocaleDateString('pt-BR');
          }
        }
        if(record[0].dataRevisaoPeloDentista){
          if(record[0].dataRevisaoPeloDentista.length > 0){
            document.getElementById("dataRevisaoPeloDentista").value = new Date(record[0].dataRevisaoPeloDentista).toLocaleDateString('pt-BR');
          }
        }
        if(record[0].dataAprovadoPeloDentista){
          if(record[0].dataAprovadoPeloDentista.length > 0){
            document.getElementById("dataAprovadoPeloDentista").value = new Date(record[0].dataAprovadoPeloDentista).toLocaleDateString('pt-BR');
          }
        }
        if(record[0].dataRecebidoPelaProducao){
          if(record[0].dataRecebidoPelaProducao.length > 0){
            document.getElementById("dataRecebidoPelaProducao").value = new Date(record[0].dataRecebidoPelaProducao).toLocaleDateString('pt-BR');
          }
        }
        if(record[0].dataDespachado){
          if(record[0].dataDespachado.length > 0){
            document.getElementById("dataDespachado").value = new Date(record[0].dataDespachado).toLocaleDateString('pt-BR');
          }
        }

       document.getElementById("idOS").disabled = true;
       document.getElementById("dataEnvioParaRadiologia").disabled = true;

       desenhaForm({ servicoOpcoes: record[0].servicoOpcoes });

        const responseUsuario = await fetch("https://www.zaping.io:8448/usuario-lista/" + _tipoUsuario + "/" + _idUsuario);

        if (!responseUsuario.ok) {
          const message = `ERRO: ${responseUsuario.statusText}`;
          window.alert(message);
          return;
        }

        const recordsUsuario = await responseUsuario.json();

        let indexDentista = 0;
        let optionsDentista = [];
        const selectDentista = document.getElementById('dentista');
        let optDentista = document.createElement('option');
        optDentista.value = 0;
        optDentista.textContent = '';
        selectDentista.appendChild(optDentista);
        let d = 0;

        let p = 0;
        for (let u = 1; u < recordsUsuario.length+1; u++) {

            if(recordsUsuario[u-1].tipoUsuario === "dentista"){
              optDentista = document.createElement('option');
              optDentista.value = d + 1;
              optDentista.textContent = recordsUsuario[u-1].nome;
              selectDentista.appendChild(optDentista);
              if(record[0].idUsuarioDentista === recordsUsuario[u-1]._id){
                indexDentista = d + 1;
                document.getElementById('dentistaTelefone').value = recordsUsuario[u-1].telefoneCelular;
                document.getElementById('dentistaEmail').value = recordsUsuario[u-1].login;
              }

              aIdDentista[d] = recordsUsuario[u-1]._id;
              aDentistaTelefone[d] = recordsUsuario[u-1].telefoneCelular;
              aDentistaEmail[d] = recordsUsuario[u-1].login;
              d++;
            }
        }
        document.getElementById('dentista').selectedIndex = indexDentista;

        document.getElementById('envioParaRadiologia').checked = record[0].envioParaRadiologia;
        document.getElementById('envioParaPlanejador').checked = record[0].envioParaPlanejador;
        document.getElementById('recebimentoPlanejador').checked = record[0].recebimentoPlanejador;
        document.getElementById('concluidoPeloPlanejador').checked = record[0].concluidoPeloPlanejador;
        document.getElementById('revisaoPeloDentista').checked = record[0].revisaoPeloDentista;
        document.getElementById('aprovadoPeloDentista').checked = record[0].aprovadoPeloDentista;
        document.getElementById('recebidoPelaProducao').checked = record[0].recebidoPelaProducao;
        document.getElementById('despachado').checked = record[0].despachado;
     }

     fetchData();

     return;
   }, [params.id, navigate]);

     return (

      <div className="App">
        <Pdf targetRef={ref} filename={nomeArquivo}>
          {({ toPdf }) => <button onClick={toPdf}>Gerar PDF</button>}
        </Pdf>
        <br />

        <div id="pdf-element" ref={ref} className="area" style={{
            maxWidth: "210mm",
            width: "100%",
            height: "100%",
            position: "relative",
            margin: "0",
            padding: "0"
          }}>

          <div className="row">
            <div className="col-2">
              <img style={{maxWidth: "30mm"}}alt="Cirurgia Guiada" src={logoCG} />
            </div>
            <div className="col-6">
              <p className="pad-bottom0 pad-top0"><strong>Surguide Odontologia Virtual Ltda</strong></p>
              <p className="pad-bottom0 pad-top0">CNPJ: 31.459.061/0001-45</p>
              <p className="pad-bottom0 pad-top0">Rua Eduardo Sprada, 8397 - Curitiba - PR</p>
              <p className="pad-bottom0 pad-top0">exames@cirurgiaguiada.net/41-98850-7446</p>
            </div>
            <div className="col-4">
              <div className="form-group"><strong>
                <label htmlFor="idOS">OS</label>
                <input
                  type="text"
                  className="form-control"
                  id="idOS"
                  style={{height: "30px", width: "98%"}}
                  value={form.idOS}
                />
                <input
                  type="text"
                  className="form-control pad-top0"
                  style={{height: "30px", width: "98%"}}
                  id="servico"
                  disabled
                />
              </strong></div>
            </div>
          </div>
          <br />

          <div id="area-dentista">
            <div className="row">
              <div className="col-4">
                <label htmlFor="dentista" className="form-check-label">Cliente</label>
                <select id="dentista"
                        style={{height: "30px", paddingTop: "5px"}}
                        className="form-select form-select-lg mb-3 pad-bottom0" aria-label=".form-select-lg example">
                </select>
              </div>
              <div className="col-3">
                <label htmlFor="dentistaTelefone">Telefone</label>
                <input
                  type="text"
                  disabled
                  className="form-control dataEnvio pad-bottom0"
                  id="dentistaTelefone"/>
              </div>

              <div className="col-5">
                <label htmlFor="dentistaEmail">Email</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  style={{height: "30px", width: "98%"}}
                  id="dentistaEmail"/>
              </div>
            </div>

            <div className="row pad-top0">
               <div className="col-7 pad-top0">
                  <div className="form-group pad-bottom0 pad-top0">
                    <label htmlFor="nomePaciente">Paciente</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nomePaciente"
                      value={form.nomePaciente}
                      disabled
                    />
                  </div>
               </div>

               <div className="col-2">
               </div>

               <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="dataOS">Criada em</label>
                    <input
                      type="text"
                      className="form-control dataEnvio"
                      id="dataOS"
                      disabled
                      value={form.dataOS}
                    />
                  </div>
               </div>

            </div>

            <div className="form-group">
              <label htmlFor="planoTratamento"><strong>Plano de Tratamento:</strong></label>
              <br />
              <div className="col-11">
                {form.planoTratamento}
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-3">
                <div className="form-check form-check-inline pad-top0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="envioParaRadiologia"
                    disabled
                  />
                  <label htmlFor="envioParaRadiologia" className="form-check-label">Envio p/ Radiologia</label>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control dataEnvio"
                    id="dataEnvioParaRadiologia"
                    disabled
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-check form-check-inline pad-top0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="envioParaPlanejador"
                    disabled
                  />
                  <label htmlFor="envioParaPlanejador" className="form-check-label">Recebimento e Envio p/Planner</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control dataEnvio"
                    id="dataEnvioParaPlanejador"
                    disabled
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-check form-check-inline pad-top0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="recebimentoPlanejador"
                    disabled
                  />
                  <label htmlFor="recebimentoPlanejador" className="form-check-label">Recebido p/ Planner</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control dataEnvio"
                    id="dataRecebimentoPlanejador"
                    disabled
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-check form-check-inline pad-top0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="concluidoPeloPlanejador"
                    disabled
                  />
                  <label htmlFor="concluidoPeloPlanejador" className="form-check-label">Concluído pelo Planner</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control dataEnvio"
                    id="dataConcluidoPeloPlanejador"
                    disabled
                  />
                </div>
              </div>

            </div>

          </div>

          <div id="area-restante">

            <hr className="linha"/>
            <div id="area-dentista2">
              <div className="row">
                <div className="col-3">
                  <div className="form-check form-check-inline pad-top0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="revisaoPeloDentista"
                      disabled
                    />
                    <label htmlFor="revisaoPeloDentista" className="form-check-label">Revisão pelo Cliente</label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control dataEnvio"
                      id="dataRevisaoPeloDentista"
                      disabled
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-check form-check-inline pad-top0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="aprovadoPeloDentista"
                      disabled
                    />
                    <label htmlFor="aprovadoPeloDentista" className="form-check-label">Aprovação Cliente</label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control dataEnvio"
                      id="dataAprovadoPeloDentista"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-check form-check-inline pad-top0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="recebidoPelaProducao"
                      disabled
                    />
                    <label htmlFor="recebidoPelaProducao" className="form-check-label">Recebido pela Produção</label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control dataEnvio"
                      id="dataRecebidoPelaProducao"
                      disabled
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-check form-check-inline pad-top0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="despachado"
                      disabled
                    />
                    <label htmlFor="despachado" className="form-check-label">Despachado para o Cliente</label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control dataEnvio"
                      id="dataDespachado"
                      disabled
                    />
                  </div>
                </div>

              </div>

              <div className="col-12">
                <br />
                <label htmlFor="motivo">Observação Cliente:</label>
                <br />
                {form.observacaoDentista}
              </div>

            </div>

          </div>
          <br />
          <div className="row pad-bottom10">
            <div className="col-7">
              <p>Item</p>
            </div>
            <div className="col-2 keep-right">
              <p>Quantidade</p>
            </div>
            <div className="col-2 keep-center">
              <p>Valor</p>
            </div>
            <div className="col-1">
            </div>
          </div>

          <hr className="linha pad-bottom0 pad-top0"/>
          <div className="areaIC pad-bottom0 pad-top0">
            {itensConfirmados()}
          </div>
          <hr className="linha pad-bottom0 pad-top0"/>
          <div className="row pad-bottom0 pad-top0">
            <div className="col-6">
            </div>
            <div className="col-2 keep-right pad-top7">
              <p>Total:</p>
            </div>
            <div className="col-3 keep-right pad-top5">
              <input
                type="text"
                className="form-control keep-right"
                id="somaTotal"
                disabled
                value={form.somaTotal}
              />
            </div>
            <div className="col-1">
            </div>
          </div>
        </div>
      </div>

   );

}
