import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { toast } from 'react-toastify';

import './style/kit-inclui.css';

import Sidebar from './sidebar';

let _number;

export default function Create() {
  const [records, setRecords] = useState([]);

  const [form, setForm] = useState({
    idUsuarioMaster: "1",
    tipoTabela: "1",
    descricao: "",
    preco: ""
  });
  const navigate = useNavigate();

 // These methods will update the state properties.
 function updateForm(value) {
   if(value.preco){
     const parse = (input, locale = "pt-BR", currency = "BRL") => {
       let fmt = String(input)
         .replace(/(?<=\d)[.,](?!\d+$)/g, "")
         .replace(",", ".");
       const pts = fmt.split(".");
       if (pts.length > 1) {
         if (+pts[0] === 0) fmt = pts.join(".");
         else if (pts[1].length === 3) fmt = pts.join("");
       }
       _number = Number(fmt);
       const isValid = isFinite(_number);
       const string = _number.toFixed(2);
       const intlNFOpts = new Intl.NumberFormat(locale, {
         style: "currency",
         currency: currency,
       }).resolvedOptions();
       const output = _number.toLocaleString(locale, {
         ...intlNFOpts,
         style: "decimal",
       });

       document.getElementById("preco").value = input;

       return {
         input,
         isValid,
         string,
         _number,
         currency,
         output,
       };
     };

     console.log(parse(value.preco));

   }

   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 // This function will handle the submission.
 async function onSubmit(e) {
   e.preventDefault();

   const novoPreco = { ...form };
   novoPreco.preco = _number;

   await fetch("https://www.zaping.io:8448/tabela-inclui", {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify(novoPreco),
   })
   .then(()=>{
     toast.success('Preço Cadastrado com Sucesso!');
   })
   .catch(error => {
     toast.error('Erro ao Gravar Preço!');
     console.log(error);
     return;
   });

   setForm({
     descricao: "",
     preco: ""
   });
   navigate("/tabela-lista");
 }

 // This following section will display the form that takes the input from the user.
 return (

   <div className="kit-inclui">
     <Sidebar />

     <div className="iConteudo">
       <div className="iContainer">
         <form onSubmit={onSubmit}>
           <br />
           <div className="row">
             <div className="col-10">
               <label htmlFor="descricao">Descrição</label>
               <input
                 type="text"
                 className="form-control input-field"
                 autoFocus="autoFocus"
                 id="descricao"
                 value={form.descricao}
                 onChange={(e) => updateForm({ descricao: e.target.value })}
               />
             </div>

             <div className="col-2">
               <label htmlFor="preco">Preço (R$)</label>
               <input
                 type="text"
                 className="form-control"
                 id="preco"
                 value={form.preco}
                 onChange={(e) => updateForm({ preco: e.target.value })}
               />
             </div>
           </div>

           <div className="form-group butSalvar">
             <br />
             <input
               type="submit"
               value="Salvar"
               className="btn btn-primary shadow1"
             />
           </div>

         </form>
       </div>
     </div>

   </div>

 );
}
