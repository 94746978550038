import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { toast } from 'react-toastify';

import './style/kit-inclui.css';

import Sidebar from './sidebar';

export default function Create() {
 const [records, setRecords] = useState([]);

 const [form, setForm] = useState({
   descricao: "",
 });
 const navigate = useNavigate();

 useEffect(() => {

 }, [records.length]);

 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 // This function will handle the submission.
 async function onSubmit(e) {
   e.preventDefault();

   const newPerson = { ...form };

   await fetch("https://www.zaping.io:8448/kit-inclui", {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify(newPerson),
   })
   .then(()=>{
     toast.success('Kit Cadastrado com Sucesso!');
   })
   .catch(error => {
     toast.error('Erro ao Gravar Kit!');
     console.log(error);
     return;
   });

   setForm({
     descricao: "",
   });
   navigate("/kit-lista");
 }

 // This following section will display the form that takes the input from the user.
 return (

   <div className="kit-inclui">
     <Sidebar />

     <div className="iConteudo">
       <div className="iContainer">
         <form onSubmit={onSubmit}>
           <br />
           <div className="form-group">
             <label htmlFor="descricao">Descrição</label>
             <input
               type="text"
               className="form-control"
               autoFocus="autoFocus"
               id="descricao"
               value={form.descricao}
               onChange={(e) => updateForm({ descricao: e.target.value })}
             />
           </div>

           <div className="form-group butSalvar">
             <br />
             <input
               type="submit"
               value="Salvar"
               className="btn btn-primary shadow1"
             />
           </div>

         </form>
       </div>
     </div>

   </div>

 );
}
