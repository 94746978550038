import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import { toast } from 'react-toastify';

import './style/radiologia-inclui.css';

import Sidebar from './sidebar';

export default function Create() {
 const [records, setRecords] = useState([]);

 const [form, setForm] = useState({
   idUsuarioDentista: "",
   descricao: "",
   contato: "",
   telefone: "",
   ruaNumero: "",
   complemento: "",
   bairro: "",
   cidade: "",
   uf: "",
   cep: ""
 });
 const navigate = useNavigate();

 function getStorage(prm) {
   let _prm = window.localStorage.getItem(prm);
   if(_prm) {
     return _prm;
   }else{
     toast.error('Favor Logar Novamente!');
     navigate("/login");
   }
 }

 let _idUsuario = getStorage("_idUsuario");

 useEffect(() => {

 }, [records.length]);

 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 // This function will handle the submission.
 async function onSubmit(e) {
   e.preventDefault();

   form.idUsuarioDentista = _idUsuario;

   const newPerson = { ...form };

   await fetch("https://www.zaping.io:8448/radiologia-inclui", {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify(newPerson),
   })
   .then(()=>{
     toast.success('Radiologia Cadastrada com Sucesso!');
   })
   .catch(error => {
     toast.error('Erro ao Gravar Radiologia!');
     console.log(error);
     return;
   });

   setForm({
     idUsuarioDentista: "",
     descricao: "",
     contato: "",
     telefone: "",
     ruaNumero: "",
     complemento: "",
     bairro: "",
     cidade: "",
     uf: "",
     cep: ""
   });
   navigate("/radiologia-lista");
 }

 // This following section will display the form that takes the input from the user.
 return (

   <div className="radiologia-inclui">
     <Sidebar />

     <div className="iConteudo">
       <div className="iContainer">
         <form onSubmit={onSubmit}>
           <br />
           <div className="form-group">
             <label htmlFor="descricao">Descrição</label>
             <input
               type="text"
               className="form-control"
               autoFocus="autoFocus"
               id="descricao"
               value={form.descricao}
               onChange={(e) => updateForm({ descricao: e.target.value })}
             />
           </div>

            <div className="row">
               <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="contato">Contato</label>
                    <input
                      type="text"
                      className="form-control"
                      id="contato"
                      value={form.contato}
                      onChange={(e) => updateForm({ contato: e.target.value })}
                    />
                  </div>
               </div>

               <div className="col-6">
                 <div className="form-group">
                   <label htmlFor="telefone">Telefone</label>
                   <input
                     type="text"
                     className="form-control"
                     id="telefone"
                     value={form.telefone}
                     onChange={(e) => updateForm({ telefone: e.target.value })}
                   />
                 </div>
               </div>
            </div>

            <div className="row">
               <div className="col-8">
                  <div className="form-group">
                    <label htmlFor="ruaNumero">Rua e No.</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ruaNumero"
                      value={form.ruaNumero}
                      onChange={(e) => updateForm({ ruaNumero: e.target.value })}
                    />
                  </div>
               </div>

               <div className="col-4">
                 <div className="form-group">
                   <label htmlFor="complemento">Compl.</label>
                   <input
                     type="text"
                     className="form-control"
                     id="complemento"
                     value={form.complemento}
                     onChange={(e) => updateForm({complemento: e.target.value })}
                   />
                 </div>
               </div>
            </div>

            <div className="row">
               <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="bairro">Bairro</label>
                    <input
                      type="text"
                      className="form-control"
                      id="bairro"
                      value={form.bairro}
                      onChange={(e) => updateForm({bairro: e.target.value })}
                    />
                  </div>
               </div>

               <div className="col-6">
                 <div className="form-group">
                   <label htmlFor="cidade">Cidade</label>
                   <input
                     type="text"
                     className="form-control"
                     id="cidade"
                     value={form.cidade}
                     onChange={(e) => updateForm({ cidade: e.target.value })}
                   />
                 </div>
               </div>
            </div>

            <div className="row">
               <div className="col-1">
                  <div className="form-group">
                    <label htmlFor="estado">UF</label>
                    <input
                      type="text"
                      className="form-control"
                      id="uf"
                      value={form.uf}
                      onChange={(e) => updateForm({ uf: e.target.value })}
                    />
                  </div>
               </div>

               <div className="col-5">
               </div>

               <div className="col-2">
                 <div className="form-group">
                   <label htmlFor="cep">CEP</label>
                   <input
                     type="text"
                     className="form-control"
                     id="cep"
                     value={form.cep}
                     onChange={(e) => updateForm({cep: e.target.value })}
                   />
                 </div>
               </div>

               <div className="col-4">
               </div>

            </div>

           <div className="form-group butSalvar">
             <br />
             <input
               type="submit"
               value="Salvar"
               className="btn btn-primary shadow1"
             />
           </div>

         </form>
       </div>
     </div>

   </div>

 );
}
