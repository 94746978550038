import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BsCloudUpload } from "react-icons/bs";

import './style/os-altera.css';
import Sidebar from './sidebar';

let arquivos;
let _idOS;
let _planejador = "";
let aDentistaTelefone = [];
let aDentistaEmail = [];
let aPlanejadorTelefone = [];
let aPlanejadorEmail = [];
let aIdPlanejador = [];
let aIdDentista = [];
let _idUsuarioDentista = "";
let _idUsuarioPlanejador = "";
let _sistemaModelo = [];
let aRadiologias = [];
let _radiologiaId = '';

export default function Edit() {
 let _idUsuario = getStorage("_idUsuario");

 let evento = "";

 let optionsK = [];
 let optionsS = [];
 let optionsM = [];
 let optionsLab = [];
 let recordsKS = [];

 let [arquivosPrevia, setArquivosPrevia] = useState({arquivosPrevia: ""});
 let [arquivosDocumento, setArquivosDocumento] = useState({arquivosDocumento: ""});
 let [arquivoProtocolo, setArquivoProtocolo] = useState({arquivoProtocolo: ""});

 let [form, setForm] = useState({
   servicoOpcoes: "",
   nomePaciente: "",
   dentistaTelefone: "",
   idOS: "",
   dataOS: "",
   planoTratamento: "",
   somenteProjeto: "",
   urgente: "",
   kit: "",
   sistema: "",
   modelo: "",
   abrangenciaOpcoes: "",
   tipoMaterial: "",
   envioParaRadiologia: "",
   radiologia: {nome: ""},
   observacaoRadiologia: "",
   idUsuario: ""
 });

 const params = useParams();
 const navigate = useNavigate();

 const trataRadiologia = e => {
   if(e.target.value){
     _radiologiaId = aRadiologias[e.target.value - 1]._id;
   }
 };

 function alteraArquivo(a) {
   setArquivosPrevia(a.target.files);

   arquivos = a.target.files;
   var filesArr = Array.prototype.slice.call(arquivos);
   let aFile = [];
   let lSpecial = false;
   let format = /[! ç@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
   filesArr.map((record) => {
     let str = record.name.toLowerCase();
     if(format.test(str)){
       lSpecial = true;
     }
     if(lSpecial){
       toast.error('Nome não pode conter acentos, espaços e caracteres especiais.')
       aFile = [];
     }else{
       aFile.push(record.name);
     }
   })

   let eAP = document.getElementById('dNomeArquivosPrevia');
   let arquivosPreviaExistentes = [];
   for (let i = 0; i < eAP.children.length; i = i + 2) {
     arquivosPreviaExistentes.push(eAP.children[i].innerText);
   }
   let _aAP, x;
   for (x = 0; x < aFile.length; x++) {
       if (arquivosPreviaExistentes.includes(aFile[x])){
       }else{
          _aAP = document.createElement('a');
          _aAP.innerHTML = aFile[x];
          _aAP.href = "https://zaping.io/arquivos/" + _idOS + "_" + aFile[x];
          _aAP.target = "_blank";
          eAP.appendChild(_aAP);
          _aAP = document.createElement('br');
          eAP.appendChild(_aAP);
       }
   }

   var oReq1 = new XMLHttpRequest();
   var xurl = 'https://www.zaping.io:8448/anexar';
   oReq1.open("POST", xurl, true);
   oReq1.onreadystatechange = function receiveResponse() {
       if(this.readyState === 4) {
           if (this.status === 200){
               oReq1.onload = function(e) {
                   if(oReq1.response.length===0){
                   }
               };
           }
       }
   };

   let formDt = new FormData(document.getElementById('formData'));
   formDt.append('idOS', document.getElementById("idOS").value);
   formDt.append('campo', 'anexosPrevia');
   formDt.append('arquivosPreviaExistentes', arquivosPreviaExistentes);
   let f;
   for (f = 0; f < a.target.files.length; f++) {
     formDt.append("files", a.target.files[f]);
   }

   oReq1.send(formDt);
 }

 function alteraArquivoDoc(a) {
   setArquivosDocumento(a.target.files);

   arquivos = a.target.files;
   var filesArr = Array.prototype.slice.call(arquivos);
   let aFileD = [];
   let lSpecial = false;
   let format = /[! ç@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
   filesArr.map((record) => {
     let str = record.name.toLowerCase();
     if(format.test(str)){
       lSpecial = true;
     }
     if(lSpecial){
       toast.error('Nome não pode conter acentos, espaços e caracteres especiais.')
       aFileD = [];
     }else{
       aFileD.push(record.name);
     }
   })

   let eAD = document.getElementById('dNomeArquivosDocumento');
   let arquivosDocumentoExistentes = [];
   for (let i = 0; i < eAD.children.length; i = i + 2) {
     arquivosDocumentoExistentes.push(eAD.children[i].innerText);
   }
   let _aAD, d;
   for (d = 0; d < aFileD.length; d++) {
       if (arquivosDocumentoExistentes.includes(aFileD[d])){
       }else{
          _aAD = document.createElement('a');
          _aAD.innerHTML = aFileD[d];
          _aAD.href = "https://zaping.io/arquivos/" + _idOS + "_" + aFileD[d];
          _aAD.target = "_blank";
          eAD.appendChild(_aAD);
          _aAD = document.createElement('br');
          eAD.appendChild(_aAD);
       }
   }

   var oReq1 = new XMLHttpRequest();
   var xurl = 'https://www.zaping.io:8448/anexarDocumento';
   oReq1.open("POST", xurl, true);
   oReq1.onreadystatechange = function receiveResponse() {
       if(this.readyState === 4) {
           if (this.status === 200){
               oReq1.onload = function(e) {
                   if(oReq1.response.length===0){
                   }
               };
           }
       }
   };

   let formDt = new FormData(document.getElementById('formDataD'));
   formDt.append('idOS', document.getElementById("idOS").value);
   formDt.append('campo', 'anexosDocumento');
   formDt.append('arquivosDocumentoExistentes', arquivosDocumentoExistentes);
   let f;
   for (f = 0; f < a.target.files.length; f++) {
     formDt.append("filesD", a.target.files[f]);
   }

   oReq1.send(formDt);
 }

 function alteraArquivoProtocolo(a) {
   evento = "arquivoProtocolo";
   let eAP = document.getElementById('dNomeArquivoProtocolo');
   while (eAP.hasChildNodes()) {
     eAP.removeChild(eAP.firstChild);
   }

   setArquivoProtocolo(a.target.files);

   arquivos = a.target.files;
   var filesArr = Array.prototype.slice.call(arquivos);
   let aFileP = [];
   let lSpecial = false;
   let format = /[! ç@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
   filesArr.map((record) => {
     let str = record.name.toLowerCase();
     if(format.test(str)){
       lSpecial = true;
     }
     if(lSpecial){
       toast.error('Nome não pode conter acentos, espaços e caracteres especiais.')
       aFileP = [];
     }else{
       aFileP.push(record.name);
     }
   })

   let arquivoProtocoloExistentes = [];
   for (let p = 0; p < eAP.children.length; p = p + 2) {
     arquivoProtocoloExistentes[0] = eAP.children[p].innerText;
   }
   let _aAP, dP;
   for (dP = 0; dP < aFileP.length; dP++) {
       if (arquivoProtocoloExistentes.includes(aFileP[dP])){
       }else{
          _aAP = document.createElement('a');
          _aAP.innerHTML = aFileP[dP];
          _aAP.href = "https://zaping.io/arquivos/" + _idOS + "_" + aFileP[dP];
          _aAP.target = "_blank";
          eAP.appendChild(_aAP);
          _aAP = document.createElement('br');
          eAP.appendChild(_aAP);
       }
   }

   let reqP = new XMLHttpRequest();
   var xurl = 'https://www.zaping.io:8448/anexarProtocolo';
   reqP.open("POST", xurl, true);
   reqP.onreadystatechange = function receiveResponse() {
       if(this.readyState === 4) {
           if (this.status === 200){
               reqP.onload = function(e) {

                 onClickSubmit(false);

               };
           }
       }
   };

   let formDtP = new FormData(document.getElementById('formDataP'));
   formDtP.append('idOS', document.getElementById("idOS").value);
   formDtP.append('campo', 'anexoProtocolo');
   formDtP.append('arquivoProtocoloExistentes', arquivoProtocoloExistentes);
   formDtP.append("filesP", a.target.files[0]);

   reqP.send(formDtP);
 }

 function getStorage(prm) {
   let _prm = window.localStorage.getItem(prm);
   if(_prm === "null"){
     navigate("/login");
   }else{
     if(_prm) {
       return _prm;
     }else{
       navigate("/login");
     }
   }
 }

 function desenhaForm(value){
   let elemCG = document.getElementById('div-cg');
   let elemPO = document.getElementById('div-po');

   let prm;
   if( value.servicoOpcoes ) {
     prm = value.servicoOpcoes;
   }else{
     prm = value.param;
   }
   if( prm === "servicoCirurgiaGuiada" ){
     elemCG.style.visibility = "visible";
     elemCG.style.display = "block";
     elemPO.style.visibility = "hidden";
     elemPO.style.display = "none";
   }else{
     if( prm === "servicoPrototipagemOssea" ){
       elemCG.style.visibility = "hidden";
       elemCG.style.display = "none";
       elemPO.style.visibility = "visible";
       elemPO.style.display = "block";
     }else{
       elemCG.style.visibility = "hidden";
       elemCG.style.display = "none";
       elemPO.style.visibility = "hidden";
       elemPO.style.display = "none";
     }
   }
   let elemStatus = document.getElementById('status');
   let statusCor;
   if(elemStatus.selectedIndex === 1){
     statusCor = "orange";
   }
   if(elemStatus.selectedIndex === 2){
     statusCor = "red";
   }
   if(elemStatus.selectedIndex === 3){
     statusCor = "green";
   }
   document.getElementById('status').style.color = statusCor;
   document.getElementById("dataOS").disabled = true;

 }

 useEffect(() => {

   async function fetchData() {
     let oArquivosPrevia = [];
     let oArquivosDocumento = [];
     let oArquivoProtocolo = [];

     let n = 0;

     _idOS = params.id.toString();
     let _tipoUsuario = getStorage("_tipoUsuario");
     let _idUsuario = getStorage("_idUsuario");

     const response = await fetch(`https://www.zaping.io:8448/os/` + _tipoUsuario + `/` + _idUsuario + `/` + _idOS);

     if (!response.ok) {
       toast.error('ERRO!');
       return;
     }

     let record = await response.json();
console.log(record)

     let _dentista = record[0].idUsuarioDentista;
     document.getElementById("dentista").disabled = true;
     let _dentistaTelefone = record[0].dentistaTelefone;

     if(_tipoUsuario === "admin"){
       document.getElementById("dentista").disabled = false;
     }

     let nodes;
     if(_tipoUsuario === "dentista"){
       document.getElementById("observacaoParaProducao").style.visibility = "hidden";
       document.getElementById("observacaoParaProducao").style.display = "none";
       document.getElementById("lblObservacaoParaProducao").style.visibility = "hidden";
       document.getElementById("lblObservacaoParaProducao").style.display = "none";

       nodes = document.getElementById("area-dentista").getElementsByTagName('*');
       for(n = 0; n < nodes.length; n++){
         nodes[n].disabled = false;
       }
       nodes = document.getElementById("area-restante").getElementsByTagName('*');
       for(n = 0; n < nodes.length; n++){
         nodes[n].disabled = true;
         nodes[n].style.color = "black";
       }

       nodes = document.getElementById("area-dentista2").getElementsByTagName('*');
       for(n = 0; n < nodes.length; n++){
         nodes[n].disabled = false;
       }
     }else{
//       nodes = document.getElementById("area-dentista").getElementsByTagName('*');
//       for(n = 0; n < nodes.length; n++){
//         nodes[n].disabled = false;
//         nodes[n].style.color = "black";
//       }
//       nodes = document.getElementById("area-restante").getElementsByTagName('*');
//       for(n = 0; n < nodes.length; n++){
//         nodes[n].disabled = false;
//       }
     }

     let _planejadorTelefone = "";
     let _planejadorEmail = "";
     let _dentistaEmail = "";
     if(record[0].planejador){
       _idUsuarioPlanejador = record[0].planejador;
       _planejador = record[0].planejador;
       _planejadorTelefone = record[0].planejadorTelefone;
       _planejadorEmail = record[0].planejadorEmail;
     }

     if(record[0].dentista){
       _idUsuarioDentista = record[0].idUsuarioDentista;
       _dentista = record[0].idUsuarioDentista;
       _dentistaTelefone = record[0].dentistaTelefone;
       _dentistaEmail = record[0].dentistaEmail;
     }

     record = record[0].resGetOS;
     if (!record) {
       toast.error('OS não Encontrada!');
       navigate("/os-lista");
       return;
     }

     setForm(record[0]);
     document.getElementById("dataOS").value = new Date(record[0].dataOS).toLocaleDateString('pt-BR');

     document.getElementById("idOS").disabled = true;
     document.getElementById("dataEnvioParaRadiologia").disabled = true;

     if(record[0].observacaoRadiologia){
      atualizaLink("observacaoRadiologia", record[0].observacaoRadiologia, "linkContainer")
     }

     if(record[0].dataEnvioParaRadiologia){
       if(record[0].dataEnvioParaRadiologia.length > 0){
       document.getElementById("dataEnvioParaRadiologia").value = new Date(record[0].dataEnvioParaRadiologia).toLocaleDateString('pt-BR');
       }
     }
     if(record[0].dataEnvioParaPlanejador){
       if(record[0].dataEnvioParaPlanejador.length > 0){
         document.getElementById("dataEnvioParaPlanejador").value = new Date(record[0].dataEnvioParaPlanejador).toLocaleDateString('pt-BR');
       }
     }
     if(record[0].dataRecebimentoPlanejador){
       if(record[0].dataRecebimentoPlanejador.length > 0){
         document.getElementById("dataRecebimentoPlanejador").value = new Date(record[0].dataRecebimentoPlanejador).toLocaleDateString('pt-BR');
       }
     }
     if(record[0].dataConcluidoPeloPlanejador){
       if(record[0].dataConcluidoPeloPlanejador.length > 0){
         document.getElementById("dataConcluidoPeloPlanejador").value = new Date(record[0].dataConcluidoPeloPlanejador).toLocaleDateString('pt-BR');
       }
     }
     if(record[0].dataRevisaoPeloDentista){
       if(record[0].dataRevisaoPeloDentista.length > 0){
         document.getElementById("dataRevisaoPeloDentista").value = new Date(record[0].dataRevisaoPeloDentista).toLocaleDateString('pt-BR');
       }
     }
     if(record[0].dataAprovadoPeloDentista){
       if(record[0].dataAprovadoPeloDentista.length > 0){
         document.getElementById("dataAprovadoPeloDentista").value = new Date(record[0].dataAprovadoPeloDentista).toLocaleDateString('pt-BR');
       }
     }
     if(record[0].dataRecebidoPelaProducao){
       if(record[0].dataRecebidoPelaProducao.length > 0){
         document.getElementById("dataRecebidoPelaProducao").value = new Date(record[0].dataRecebidoPelaProducao).toLocaleDateString('pt-BR');
       }
     }
     if(record[0].dataFinalizado){
      if(record[0].dataFinalizado.length > 0){
        document.getElementById("dataFinalizado").value = new Date(record[0].dataFinalizado).toLocaleDateString('pt-BR');
      }
    }     
     if(record[0].dataDespachado){
       if(record[0].dataDespachado.length > 0){
         document.getElementById("dataDespachado").value = new Date(record[0].dataDespachado).toLocaleDateString('pt-BR');
       }
     }

     var eleAP = document.getElementById('dNomeArquivosPrevia');
     let aAP, a;
     if(record[0].anexosPrevia){
       oArquivosPrevia = record[0].anexosPrevia;
       for (a = 0; a < record[0].anexosPrevia.length; a++) {
          aAP = document.createElement('a');
          aAP.innerHTML = record[0].anexosPrevia[a];
          aAP.href = "https://zaping.io/arquivos/" + _idOS + "_" + record[0].anexosPrevia[a];
          aAP.target = "_blank";
          eleAP.appendChild(aAP);
          aAP = document.createElement('br');
          eleAP.appendChild(aAP);
       }
     }else{
       oArquivosPrevia = [];
     }

     var eleAD = document.getElementById('dNomeArquivosDocumento');
     let aAD, y;
     if(record[0].anexosDocumento){
        oArquivosDocumento = record[0].anexosDocumento;
        for (y = 0; y < record[0].anexosDocumento.length; y++) {
           aAD = document.createElement('a');
           aAD.innerHTML = record[0].anexosDocumento[y];
           aAD.href = "https://zaping.io/arquivos/" + _idOS + "_" + record[0].anexosDocumento[y];
           aAD.target = "_blank";
           eleAD.appendChild(aAD);
           aAD = document.createElement('br');
           eleAD.appendChild(aAD);
        }
      }else{
        oArquivosDocumento = [];
      }

     var eleAPC = document.getElementById('dNomeArquivoProtocolo');
     let aAPC, yPC;
     if(record[0].anexoProtocolo){
        oArquivoProtocolo = record[0].anexoProtocolo;
        for (yPC = 0; yPC < record[0].anexoProtocolo.length; yPC++) {
           aAPC = document.createElement('a');
           aAPC.innerHTML = record[0].anexoProtocolo[yPC];
           aAPC.href = "https://zaping.io/arquivos/" + _idOS + "_" + record[0].anexoProtocolo[yPC];
           aAPC.target = "_blank";
           eleAPC.appendChild(aAPC);
           aAPC = document.createElement('br');
           eleAPC.appendChild(aAPC);
        }
      }else{
        oArquivoProtocolo = [];
      }

     desenhaForm({ servicoOpcoes: record[0].servicoOpcoes });

     let indexLab;

     if(record[0].servicoOpcoes === "servicoCirurgiaGuiada"){
       const responseKS = await fetch("https://www.zaping.io:8448/kit-sistema_modelo-lista");

       if (!responseKS.ok) {
         const message = `ERRO: ${responseKS.statusText}`;
         window.alert(message);
         return;
       }
       recordsKS = await responseKS.json();

       const selectKit = document.getElementById('kit');
       let optKit = document.createElement('option');
       optKit.value = 0;
       optKit.textContent = '';
       selectKit.appendChild(optKit);
       let i = 1;
       let indexKit, indexSistema, indexModelo, indexLab;

       for (i = 1; i < recordsKS[0].kit.length+1; i++) {
           optKit = document.createElement('option');
           optKit.value = i;
           optKit.textContent = recordsKS[0].kit[i-1].descricao;
           selectKit.appendChild(optKit);
           if(record[0].kit === recordsKS[0].kit[i-1].descricao){
             indexKit = i;
           }
       }
       document.querySelectorAll('#kit > option').forEach((option) => {
           if (optionsK.includes(option.value)) option.remove()
           else optionsK.push(option.value)
       })
       document.getElementById('kit').selectedIndex = indexKit;
       _sistemaModelo = recordsKS[0].sistema;
       optionsS = [];
       const selectSistema = document.getElementById('sistema');
       let optSistema = document.createElement('option');
       optSistema.value = 0;
       optSistema.textContent = '';
       selectSistema.appendChild(optSistema);
       for (let is = 1; is < recordsKS[0].sistema.length+1; is++) {
           optSistema = document.createElement('option');
           optSistema.value = is;
           optSistema.textContent = recordsKS[0].sistema[is-1].sistema;
           selectSistema.appendChild(optSistema);
           if(record[0].sistema === recordsKS[0].sistema[is-1].sistema){
             indexSistema = is;
           }
       }
       document.querySelectorAll('#sistema > option').forEach((optionS) => {
           if (optionsS.includes(optionS.value)) optionS.remove()
           else optionsS.push(optionS.value)
       })
       document.getElementById('sistema').selectedIndex = indexSistema;

       optionsM = [];
       const selectModelo = document.getElementById('modelo');
       let optModelo = "";
       for (let m = 0; m < recordsKS[0].sistema[indexSistema - 1].modelo.length; m++) {
           optModelo = document.createElement('option');
           optModelo.value = m;
           optModelo.textContent = recordsKS[0].sistema[indexSistema - 1].modelo[m];
           selectModelo.appendChild(optModelo);
           if(record[0].modelo === recordsKS[0].sistema[indexSistema - 1].modelo[m]){
             indexModelo = m;
           }
       }
       document.querySelectorAll('#modelo > option').forEach((optionM) => {
           if (optionsM.includes(optionM.value)) optionM.remove()
           else optionsM.push(optionM.value)
       })
       document.getElementById('modelo').selectedIndex = indexModelo;
     }

      const responseLab = await fetch("https://www.zaping.io:8448/radiologia-lista/planejador/" + record[0].idUsuarioDentista);

      if (!responseLab.ok) {
        const message = `ERRO: ${responseLab.statusText}`;
        window.alert(message);
        return;
      }

      const recordsLab = await responseLab.json();
      aRadiologias = recordsLab;

      optionsLab = [];
      const selectLab = document.getElementById('radiologia.nome');
      let optLab = document.createElement('option');
      optLab.value = 0;
      optLab.textContent = '';
      selectLab.appendChild(optLab);
      let l = 1;
      for (l = 1; l < recordsLab.length+1; l++) {
          optLab = document.createElement('option');
          optLab.value = l;
          optLab.textContent = recordsLab[l-1].uf + " " + recordsLab[l-1].cidade + " " + recordsLab[l-1].descricao;
          selectLab.appendChild(optLab);
          if(record[0].radiologia){
            if(record[0].radiologia.id === recordsLab[l-1]._id){
              indexLab = l;
              _radiologiaId = record[0].radiologia.id;
            }
          }
      }
      document.querySelectorAll('#radiologia.nome > option').forEach((option) => {
          if (optionsLab.includes(option.value)) option.remove()
          else optionsLab.push(option.value)
      })
      document.getElementById('radiologia.nome').selectedIndex = indexLab;
      optionsLab = [];

      const responseUsuario = await fetch("https://www.zaping.io:8448/usuario-lista/" + _tipoUsuario + "/" + _idUsuario);

      if (!responseUsuario.ok) {
        const message = `ERRO: ${responseUsuario.statusText}`;
        window.alert(message);
        return;
      }

      const recordsUsuario = await responseUsuario.json();

      let indexDentista = 0;
      let optionsDentista = [];
      const selectDentista = document.getElementById('dentista');
      let optDentista = document.createElement('option');
      optDentista.value = 0;
      optDentista.textContent = '';
      selectDentista.appendChild(optDentista);
      let d = 0;

      let indexPlanejador = 0;
      let optionsPlanejador = [];
      const selectPlanejador = document.getElementById('planejador');
      let optPlanejador = document.createElement('option');
      optPlanejador.value = 0;
      optPlanejador.textContent = '';
      selectPlanejador.appendChild(optPlanejador);
      let p = 0;
      for (let u = 1; u < recordsUsuario.length+1; u++) {
          if(recordsUsuario[u-1].tipoUsuario === "planejador"){
            optPlanejador = document.createElement('option');
            optPlanejador.value = p + 1;
            optPlanejador.textContent = recordsUsuario[u-1].nome;
            selectPlanejador.appendChild(optPlanejador);
            if(record[0].planejador){
              if(record[0].planejador === recordsUsuario[u-1]._id){
                indexPlanejador = p + 1;
                document.getElementById('planejadorTelefone').value = recordsUsuario[u-1].telefoneCelular;
                document.getElementById('planejadorEmail').value = recordsUsuario[u-1].login;
              }
            }
            aIdPlanejador[p] = recordsUsuario[u-1]._id;
            aPlanejadorTelefone[p] = recordsUsuario[u-1].telefoneCelular;
            aPlanejadorEmail[p] = recordsUsuario[u-1].login;
            p++;
          }

          if(recordsUsuario[u-1].tipoUsuario === "admin" || recordsUsuario[u-1].tipoUsuario === "dentista"){
            optDentista = document.createElement('option');
            optDentista.value = d + 1;
            optDentista.textContent = recordsUsuario[u-1].nome;
            selectDentista.appendChild(optDentista);
            if(record[0].idUsuarioDentista === recordsUsuario[u-1]._id){
              indexDentista = d + 1;
              document.getElementById('dentistaTelefone').value = recordsUsuario[u-1].telefoneCelular;
              document.getElementById('dentistaEmail').value = recordsUsuario[u-1].login;
            }

            aIdDentista[d] = recordsUsuario[u-1]._id;
            aDentistaTelefone[d] = recordsUsuario[u-1].telefoneCelular;
            aDentistaEmail[d] = recordsUsuario[u-1].login;
            d++;
          }
      }
      document.getElementById('dentista').selectedIndex = indexDentista;
      optionsDentista = [];

      document.querySelectorAll('#planejador > option').forEach((option) => {
          if (optionsPlanejador.includes(option.value)) option.remove()
          else optionsPlanejador.push(option.value)
      })
      document.getElementById('planejador').selectedIndex = indexPlanejador;
      optionsPlanejador = [];

      let statusIndex, statusCor;
      if(record[0].status === "atencao"){
        statusIndex = 1;
        statusCor = "orange";
      }
      if(record[0].status === "contraindicacao"){
        statusIndex = 2;
        statusCor = "red";
      }
      if(record[0].status === "ok"){
        statusIndex = 3;
        statusCor = "green";
      }
      document.getElementById('status').selectedIndex = statusIndex;
      document.getElementById('status').style.color = statusCor;

      document.getElementById('envioParaRadiologia').checked = record[0].envioParaRadiologia;
      document.getElementById('possuiExame').checked = record[0].possuiExame;
      document.getElementById('envioParaPlanejador').checked = record[0].envioParaPlanejador;
      document.getElementById('recebimentoPlanejador').checked = record[0].recebimentoPlanejador;
      document.getElementById('concluidoPeloPlanejador').checked = record[0].concluidoPeloPlanejador;
      document.getElementById('revisaoPeloDentista').checked = record[0].revisaoPeloDentista;
      document.getElementById('aprovadoPeloDentista').checked = record[0].aprovadoPeloDentista;
      document.getElementById('recebidoPelaProducao').checked = record[0].recebidoPelaProducao;
      document.getElementById('finalizado').checked = record[0].finalizado;
      document.getElementById('despachado').checked = record[0].despachado;

//      document.getElementById('dataRevisaoPeloDentista').value = record[0].dataRevisaoPeloDentista;

      document.getElementById('dataEnvioParaPlanejador').disabled = true;
      document.getElementById('planejadorTelefone').disabled = true;
      document.getElementById('planejadorEmail').disabled = true;
      document.getElementById('dentistaTelefone').disabled = true;
      if(_tipoUsuario === "admin"){
        document.getElementById('envioParaPlanejador').disabled = false;
        document.getElementById('planejador').disabled = false;
      }else{
        document.getElementById('envioParaPlanejador').disabled = true;
        document.getElementById('planejador').disabled = true;
      }
   }

   fetchData();

   return;
 }, [params.id, navigate]);

 function updEnvioRadiologia(prm) {
   if(prm === true) {
    document.getElementById('possuiExame').check = false;
    evento = "envioParaRadiologia";
     document.getElementById('dataEnvioParaRadiologia').value = new Date().toLocaleDateString('pt-BR');
     document.getElementById('radiologia.nome').disabled = false;
     document.getElementById('radiologia.nome').focus();

     onClickSubmit(false);
   }else{
     evento = "";
     document.getElementById('radiologia.nome').disabled = true;
   }
 }

  function possuiExame(prm) {
    evento = "";
    if(prm === true) {
      document.getElementById('observacaoRadiologia').focus();
      document.getElementById('envioParaRadiologia').checked = false;
    }
  }

  function atualizaLink(campo, conteudoLink, linkContainer) {
    if (conteudoLink) {
      const linkElement = document.createElement('a');
      conteudoLink = conteudoLink.replace("localhost:3001/os-altera/", "");
      conteudoLink = conteudoLink.replace("https://www.cirurgia-guiada.net/os-altera/", "");

      linkElement.href = conteudoLink;
      linkElement.target = "_blank";
      linkElement.textContent = conteudoLink;
      document.getElementById(linkContainer).appendChild(linkElement);
      document.getElementById(campo).style.display = 'none';

    }
  }

 function updEnvioPlanejador(prm) {
   if(prm === true) {
     if(document.getElementById('envioParaRadiologia').checked === true || document.getElementById('possuiExame').checked === true){
       evento = "envioParaPlanejador";
       document.getElementById("dataEnvioParaPlanejador").value = new Date().toLocaleDateString('pt-BR');
       document.getElementById('planejador').focus();

       onClickSubmit(false);
     }else{
       evento = "";
       document.getElementById('envioParaPlanejador').checked = false;
       toast.error('OS ainda não Enviada para Radiologia ou não possui Exames de Imagem!')
     }
   }else{
     evento = "";
   }
 }

 function updRecebidoPlanejador(prm) {
   if(prm === true) {
     if(document.getElementById('envioParaPlanejador').checked === true){
       evento = "recebimentoPlanejador";
       document.getElementById('dataRecebimentoPlanejador').value = new Date().toLocaleDateString('pt-BR');

       onClickSubmit(false);
     }else{
       evento = "";
       document.getElementById('recebimentoPlanejador').checked = false;
       toast.error('OS ainda não Enviada para o Planner!')
     }
   }else{
     evento = "";
   }
 }

 function updConcluidoPlanejador(prm) {
   if(prm === true) {
     if(document.getElementById('recebimentoPlanejador').checked === true){
       evento = "concluidoPeloPlanejador";
       document.getElementById('dataConcluidoPeloPlanejador').value = new Date().toLocaleDateString('pt-BR');

       onClickSubmit(false);
     }else{
       evento = "";
       document.getElementById('concluidoPeloPlanejador').checked = false;
       toast.error('OS ainda não Recebida pelo Planner!')
     }
   }else{
     evento = "";
   }
 }

 function updRevisaoDentista(prm) {
   if(prm === true) {
     if(document.getElementById('concluidoPeloPlanejador').checked === true){
       evento = "revisaoPeloDentista";
       document.getElementById('dataRevisaoPeloDentista').value = new Date().toLocaleDateString('pt-BR');

       onClickSubmit(false);
     }else{
       evento = "";
       document.getElementById('revisaoPeloDentista').checked = false;
       toast.error('OS ainda não Concluída pelo Planner!')
     }
   }else{
     evento = "";
   }
 }

 function updAprovadoDentista(prm) {
   if(prm === true) {
     if(document.getElementById('concluidoPeloPlanejador').checked === true){
       evento = "aprovadoPeloDentista";
       document.getElementById('dataAprovadoPeloDentista').value = new Date().toLocaleDateString('pt-BR');

       onClickSubmit(false);
     }else{
       evento = "";
       document.getElementById('aprovadoPeloDentista').checked = false;
       toast.error('OS ainda não Concluída pelo Planner!')
     }
   }else{
     evento = "";
   }
 }

 function updRecebidoProducao(prm) {
   if(prm === true) {
     if(document.getElementById('aprovadoPeloDentista').checked === true){
       evento = "recebidoPelaProducao";
       document.getElementById('dataRecebidoPelaProducao').value = new Date().toLocaleDateString('pt-BR');

       onClickSubmit(false);
     }else{
       evento = "";
       document.getElementById('recebidoPelaProducao').checked = false;
       toast.error('OS ainda não Aprovada pelo Dentista!')
     }
   }else{
     evento = "";
   }
 }

 function updFinalizado(prm) {
  if(prm === true) {
    if(document.getElementById('recebidoPelaProducao').checked === true){
      evento = "finalizado";
      document.getElementById('dataFinalizado').value = new Date().toLocaleDateString('pt-BR');

      onClickSubmit(false);
    }else{
      evento = "";
      document.getElementById('finalizado').checked = false;
      toast.error('Não Recebido pela Produção!')
    }
  }else{
    evento = "";
  }
 } 

 function updDespachado(prm) {
  if(prm === true) {
    if(document.getElementById('finalizado').checked === true){
      evento = "despachado";
      document.getElementById('dataDespachado').value = new Date().toLocaleDateString('pt-BR');
      document.getElementById('transportado').focus();

      onClickSubmit(false);
    }else{
      evento = "";
      document.getElementById('despachado').checked = false;
      toast.error('Produção Não Concluída!')
    }
  }else{
    evento = "";
  }
 }

 function geraRequisicao(url) {
   window.localStorage.setItem('_idOS', _idOS);
   window.localStorage.setItem('_nomePaciente', document.getElementById('nomePaciente').value);
   window.localStorage.setItem('_dentista', document.getElementById("dentista").options[document.getElementById("dentista").selectedIndex].text);
   window.localStorage.setItem('_dentistaTelefone', document.getElementById('dentistaTelefone').value);
   window.localStorage.setItem('_servicoOpcoes', form.servicoOpcoes);
   if( form.servicoOpcoes === "servicoCirurgiaGuiada" ){
     let arquivo = "";
     if( form.tipoOpcoes === "arcoTotal" ){
       arquivo = "total";
     }else{
       if( form.tipoOpcoes === "implantesUnitarios" ){
         arquivo = "parcial";
       }
     }
     window.localStorage.setItem('_arquivo', arquivo);
     window.localStorage.setItem('_nomeServico', "Cirurgia Guiada");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/cirurgia-guiada-" + arquivo + ".pdf");
   }
   if( form.servicoOpcoes === "servicoPrototipagemOssea" ){
     window.localStorage.setItem('_nomeServico', "Prototipagem Óssea");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/prototipagem-ossea.pdf");
   }
   if( form.servicoOpcoes === "servicoCirurgiaOrtognatica" ){
     window.localStorage.setItem('_nomeServico', "Cirurgia Ortognática");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/cirurgia-ortognatica.pdf");
   }
   if( form.servicoOpcoes === "servicoEnceramentoDigital" ){
     window.localStorage.setItem('_nomeServico', "Enceramento Digital");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/enceramento-digital.pdf");
   }
   if( form.servicoOpcoes === "servicoGuiaPeriodontal" ){
     window.localStorage.setItem('_nomeServico', "Guia Periodontal");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/guia-periodontal.pdf");
   }
   if( form.servicoOpcoes === "servicoGuiaEndodontico" ){
     window.localStorage.setItem('_nomeServico', "Guia Endodôntico");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/guia-endodontico.pdf");
   }
   if( form.servicoOpcoes === "servicoPlacaMiorrelaxante" ){
     window.localStorage.setItem('_nomeServico', "Placa Miorrelaxante");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/placa-miorrelaxante.pdf");
   }
   if( form.servicoOpcoes === "servicoModeloImpresso" ){
     window.localStorage.setItem('_nomeServico', "Modelo Impresso");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/modelo-impresso.pdf");
   }
   if( form.servicoOpcoes === "servicoOutro" ){
     window.localStorage.setItem('_nomeServico', "Outros Serviços");
     window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/outros.pdf");
   }

   let _radiologiaDescricao = "";
   let _radiologiaTelefone = "";
   let _radiologiaRuaNumero = "";
   let _radiologiaComplemento = "";
   let _radiologiaBairro = "";

   for (let r = 0; r < aRadiologias.length; r++) {
     if(aRadiologias[r]._id === _radiologiaId){
       if(aRadiologias[r].descricao){
         _radiologiaDescricao = aRadiologias[r].descricao;
       }
       if(aRadiologias[r].telefone){
         _radiologiaTelefone = aRadiologias[r].telefone;
       }
       if(aRadiologias[r].ruaNumero){
         _radiologiaRuaNumero = aRadiologias[r].ruaNumero;
       }
       if(aRadiologias[r].complemento){
         _radiologiaComplemento = aRadiologias[r].complemento;
       }
       if(aRadiologias[r].bairro){
         _radiologiaBairro = aRadiologias[r].bairro;
       }
     }
   }
   window.localStorage.setItem('_radiologiaDescricao', _radiologiaDescricao);
   window.localStorage.setItem('_radiologiaTelefone', _radiologiaTelefone);
   window.localStorage.setItem('_radiologiaRuaNumero', _radiologiaRuaNumero);
   window.localStorage.setItem('_radiologiaComplemento', _radiologiaComplemento);
   window.localStorage.setItem('_radiologiaBairro', _radiologiaBairro);

   const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
   if (newWindow) newWindow.opener = null
 }

 function updateForm(value) {

   desenhaForm(value);
   if( value.planejador ){
     let lPlanejador = document.getElementById("planejador");
     document.getElementById("planejadorTelefone").value = aPlanejadorTelefone[lPlanejador.options.selectedIndex - 1];
     document.getElementById("planejadorEmail").value = aPlanejadorEmail[lPlanejador.options.selectedIndex - 1];
     _idUsuarioPlanejador = aIdPlanejador[lPlanejador.options.selectedIndex - 1];
     document.getElementById("dataEnvioParaPlanejador").value = new Date().toLocaleDateString('pt-BR');

     return;
   }
   if( value.dentista ){
     let lDentista = document.getElementById("dentista");
     document.getElementById("dentistaTelefone").value = aDentistaTelefone[lDentista.options.selectedIndex - 1];
     document.getElementById("dentistaEmail").value = aDentistaEmail[lDentista.options.selectedIndex - 1];
     _idUsuarioDentista = aIdDentista[lDentista.options.selectedIndex - 1];
     return;
   }

    if( value.sistema ){
      document.querySelectorAll('#modelo > option').forEach((optionM) => {
        optionM.remove()
      })

       optionsM = [];
       const selectModelo = document.getElementById('modelo');
       let optModelo = document.createElement('option');
       optModelo.value = 0;
       optModelo.textContent = "";
       selectModelo.appendChild(optModelo);
       for (let m = 0; m < _sistemaModelo[value.sistema - 1].modelo.length; m++) {
           optModelo = document.createElement('option');
           optModelo.value = m + 1;
           optModelo.textContent = _sistemaModelo[value.sistema - 1].modelo[m];
           selectModelo.appendChild(optModelo);
       }
       document.getElementById('modelo').focus();
    }

    if( value.transportado || value.rastreio){
      form.dataDespachado = new Date().toLocaleDateString('pt-BR');
//      return;
    }

   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 async function onClickServico() {
   navigate("/fatura-os/" + _idOS);
 }

 async function onClickSubmit(full) {
   if(form.servicoOpcoes === "servicoCirurgiaGuiada"){
     var lKit = document.getElementById("kit");
     form.kit = lKit.options[lKit.options.selectedIndex].innerText;
     var lSistema = document.getElementById("sistema");
     form.sistema = lSistema.options[lSistema.options.selectedIndex].innerText;
     var lModelo = document.getElementById("modelo");
     form.modelo = lModelo.options[lModelo.options.selectedIndex].innerText;
   }else{
     form.kit = "";
     form.sistema = "";
     form.modelo = "";
   }

   var lStatus = document.getElementById("status");
   let _planejador = null;

   let parts;
   let [day, month, year] = document.getElementById("dataEnvioParaRadiologia").value.split('/');
   let _dataEnvioParaRadiologia = new Date(year, month - 1, day);

   [day, month, year] = document.getElementById("dataEnvioParaPlanejador").value.split('/');
   let _dataEnvioParaPlanejador = new Date(year, month - 1, day);

   [day, month, year] = document.getElementById("dataRecebimentoPlanejador").value.split('/');
   let _dataRecebimentoPlanejador = new Date(year, month - 1, day);

   [day, month, year] = document.getElementById("dataConcluidoPeloPlanejador").value.split('/');
   let _dataConcluidoPeloPlanejador = new Date(year, month - 1, day);

   [day, month, year] = document.getElementById("dataRevisaoPeloDentista").value.split('/');
   let _dataRevisaoPeloDentista = new Date(year, month - 1, day);

   [day, month, year] = document.getElementById("dataAprovadoPeloDentista").value.split('/');
   let _dataAprovadoPeloDentista = new Date(year, month - 1, day);

   [day, month, year] = document.getElementById("dataRecebidoPelaProducao").value.split('/');
   let _dataRecebidoPelaProducao = new Date(year, month - 1, day);

   [day, month, year] = document.getElementById("dataFinalizado").value.split('/');
   let _dataFinalizado = new Date(year, month - 1, day);

   [day, month, year] = document.getElementById("dataDespachado").value.split('/');
   let _dataDespachado = new Date(year, month - 1, day);

   const alteradaOS = {
       servicoOpcoes: form.servicoOpcoes,
       nomePaciente: form.nomePaciente,
       idOS: form.idOS,
       dataOS: new Date(form.dataOS),
       planoTratamento: form.planoTratamento,
       somenteProjeto: form.somenteProjeto,
       urgente: form.urgente,
       kit: form.kit,
       sistema: form.sistema,
       modelo: form.modelo,
       abrangenciaOpcoes: form.abrangenciaOpcoes,
       tipoMaterial: form.tipoMaterial,
       evento: evento,
       envioParaRadiologia: document.getElementById("envioParaRadiologia").checked,
       dataEnvioParaRadiologia: _dataEnvioParaRadiologia,
       radiologia: {id: _radiologiaId},
       observacaoRadiologia: form.observacaoRadiologia,
       envioParaPlanejador: document.getElementById("envioParaPlanejador").checked,
       dataEnvioParaPlanejador: _dataEnvioParaPlanejador,
       recebimentoPlanejador: document.getElementById("recebimentoPlanejador").checked,
       dataRecebimentoPlanejador: _dataRecebimentoPlanejador,
       observacaoPlanejador: document.getElementById("observacaoPlanejador").value,
       linkPreviaPlanejamento: document.getElementById("linkPreviaPlanejamento").value,
       status: lStatus.options[lStatus.options.selectedIndex].value,
       motivo: document.getElementById("motivo").value,
       concluidoPeloPlanejador: document.getElementById("concluidoPeloPlanejador").checked,
       dataConcluidoPeloPlanejador: _dataConcluidoPeloPlanejador,
       observacaoDentista: document.getElementById("observacaoDentista").value,
       revisaoPeloDentista: document.getElementById("revisaoPeloDentista").checked,
       dataRevisaoPeloDentista: _dataRevisaoPeloDentista,
       aprovadoPeloDentista: document.getElementById("aprovadoPeloDentista").checked,
       dataAprovadoPeloDentista: _dataAprovadoPeloDentista,
       observacaoParaProducao: document.getElementById("observacaoParaProducao").value,
       recebidoPelaProducao: document.getElementById("recebidoPelaProducao").checked,
       dataRecebidoPelaProducao: _dataRecebidoPelaProducao,
       finalizado: document.getElementById("finalizado").checked,
       dataFinalizado: _dataFinalizado,
       despachado: document.getElementById("despachado").checked,
       dataDespachado: _dataDespachado,
       transportado: document.getElementById("transportado").value,
       rastreio: document.getElementById("rastreio").value,
       idUsuario: _idUsuario,
       idUsuarioDentista: _idUsuarioDentista,
       dentistaNome: document.getElementById("dentista").options[document.getElementById("dentista").selectedIndex].text,
       dentistaTelefone: document.getElementById("dentistaTelefone").value,
       dentistaEmail: document.getElementById("dentistaEmail").value,
       planejador: _idUsuarioPlanejador,
       planejadorNome: document.getElementById("planejador").options[document.getElementById("planejador").selectedIndex].text,
       planejadorTelefone: document.getElementById("planejadorTelefone").value,
       planejadorEmail: document.getElementById("planejadorEmail").value,
       source: "4199353943"
     };
   evento = "";

   // This will send a post request to update the data in the database.
   await fetch(`https://www.zaping.io:8448/os-altera/` + form.idOS, {
     method: "POST",
     body: JSON.stringify(alteradaOS),
     headers: {
       'Content-Type': 'application/json'
     },
   });

   if(full === true){
     toast.success('OS Alterada com Sucesso!');
     navigate("/os-lista");
   }

 }

   // This following section will display the form that takes input from the user to update the data.
   return (

        <div>
          <Sidebar />

          <div className="iConteudo">

            <div className="iContainer">
              <div className="form-group butSalvar top-fixed">
                <input
                  type="submit"
                  value="Salvar"
                  className="btn btn-primary shadow1 pad-top10"
                  onClick={(e) => onClickSubmit(true)}
                />
              </div>

              <br />
              <div id="area-dentista">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoCirurgiaGuiada"
                      value="servicoCirurgiaGuiada"
                      checked={form.servicoOpcoes === "servicoCirurgiaGuiada"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoCirurgiaGuiada" className="form-check-label">Cirurgia Guiada</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoPrototipagemOssea"
                      value="servicoPrototipagemOssea"
                      checked={form.servicoOpcoes === "servicoPrototipagemOssea"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoPrototipagemOssea" className="form-check-label">Prototipagem Óssea</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoCirurgiaOrtognatica"
                      value="servicoCirurgiaOrtognatica"
                      checked={form.servicoOpcoes === "servicoCirurgiaOrtognatica"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoCirurgiaOrtognatica" className="form-check-label">Cirurgia Ortognática</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoEnceramentoDigital"
                      value="servicoEnceramentoDigital"
                      checked={form.servicoOpcoes === "servicoEnceramentoDigital"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoCirurgiaOrtognatica" className="form-check-label">Enceramento Digital</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoGuiaPeriodontal"
                      value="servicoGuiaPeriodontal"
                      checked={form.servicoOpcoes === "servicoGuiaPeriodontal"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoGuiaPeriodontal" className="form-check-label">Guia Periodontal</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoGuiaEndodontico"
                      value="servicoGuiaEndodontico"
                      checked={form.servicoOpcoes === "servicoGuiaEndodontico"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoGuiaEndodontico" className="form-check-label">Guia Endodôntico</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoPlacaMiorrelaxante"
                      value="servicoPlacaMiorrelaxante"
                      checked={form.servicoOpcoes === "servicoPlacaMiorrelaxante"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoPlacaMiorrelaxante" className="form-check-label">Placa Miorrelaxante</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoModeloImpresso"
                      value="servicoModeloImpresso"
                      checked={form.servicoOpcoes === "servicoModeloImpresso"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoModeloImpresso" className="form-check-label">Modelo Impresso</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="servicoOpcoes"
                      id="servicoOutro"
                      value="servicoOutro"
                      checked={form.servicoOpcoes === "servicoOutro"}
                      onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label htmlFor="servicoOutro" className="form-check-label">Outro</label>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-8">
                    <label htmlFor="dentista" className="form-check-label">Cliente</label>
                    <select id="dentista" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                      onChange={(e) => updateForm({ dentista: e.target.value, param: form.servicoOpcoes })}>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="dentistaTelefone">Telefone</label>
                    <input
                      type="text"
                      className="form-control dataEnvio"
                      id="dentistaTelefone"
                      disabled
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="dentistaEmail">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      id="dentistaEmail"
                    />
                  </div>

                </div>

                <br />
                <div className="row">
                   <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="nomePaciente">Paciente</label>
                        <input
                          type="text"
                          className="form-control"
                          id="nomePaciente"
                          value={form.nomePaciente}
                          onChange={(e) => updateForm({ nomePaciente: e.target.value, param: form.servicoOpcoes })}
                        />
                      </div>
                   </div>

                   <div className="col-4">
                      <div className="form-group idOS">
                        <label htmlFor="idOS">OS</label>
                        <input
                          type="text"
                          className="form-control idOS"
                          id="idOS"
                          value={form.idOS}
                          disabled
                        />
                      </div>
                   </div>

                   <div className="col-2">
                      <div className="form-group">
                        <label htmlFor="dataOS">Criada em</label>
                        <input
                          type="text"
                          className="form-control"
                          id="dataOS"
                          disabled
                        />
                      </div>
                   </div>

                </div>

                <br />
                <div className="form-group">
                  <label htmlFor="planoTratamento">Plano de Tratamento</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="planoTratamento"
                    value={form.planoTratamento}
                    onChange={(e) => updateForm({ planoTratamento: e.target.value, param: form.servicoOpcoes })}
                  />
                </div>

                <br />
                <div className="row">
                  <div className="col-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="somenteProjeto"
                        checked={form.somenteProjeto}
                        onChange={(e) => updateForm({ somenteProjeto: e.target.checked, param: form.servicoOpcoes })}
                      />
                      <label htmlFor="somenteProjeto" className="form-check-label">Somente projeto (sem impressão 3D)</label>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="urgente"
                        checked={form.urgente}
                        onChange={(e) => updateForm({ urgente: e.target.checked, param: form.servicoOpcoes })}
                      />
                      <label htmlFor="urgente" className="form-check-label">Urgente (prazo preferencial, acréscimo de 20%)</label>
                    </div>
                  </div>
                </div>

                <div id="div-po">
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="abrangenciaOpcoes"
                      id="maxilaSomenteRebordo"
                      value="maxilaSomenteRebordo"
                      checked={form.abrangenciaOpcoes === "maxilaSomenteRebordo"}
                      onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label id="lblMaxilaSomenteRebordo" htmlFor="maxilaSomenteRebordo" className="form-check-label">Maxila Somente Rebordo</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="abrangenciaOpcoes"
                      id="maxilaEstendidaZigomatico"
                      value="maxilaEstendidaZigomatico"
                      checked={form.abrangenciaOpcoes === "maxilaEstendidaZigomatico"}
                      onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label id="lblMaxilaEstendidaZigomatico" htmlFor="maxilaEstendidaZigomatico" className="form-check-label">Maxila Estendida p/Zigomático</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="abrangenciaOpcoes"
                      id="mandibulaSomenteRebordo"
                      value="mandibulaSomenteRebordo"
                      checked={form.abrangenciaOpcoes === "mandibulaSomenteRebordo"}
                      onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label id="lblMandibulaSomenteRebordo" htmlFor="mandibulaSomenteRebordo" className="form-check-label">Mandíbula somente rebordo</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="abrangenciaOpcoes"
                      id="mandibulaEstendidaRamoCondilo"
                      value="mandibulaEstendidaRamoCondilo"
                      checked={form.abrangenciaOpcoes === "mandibulaEstendidaRamoCondilo"}
                      onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label id="lblMandibulaEstendidaRamoCondilo" htmlFor="mandibulaEstendidaRamoCondilo" className="form-check-label">Mandíbula estendida p/ramo e côndilo</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="abrangenciaOpcoes"
                      id="outras"
                      value="outras"
                      checked={form.abrangenciaOpcoes === "outras"}
                      onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label id="lblOutras" htmlFor="outras" className="form-check-label">Outras</label>
                  </div>

                   <br />
                   <br />
                   <div className="form-check form-check-inline">
                     <input
                       className="form-check-input"
                       type="radio"
                       name="tipoMaterial"
                       id="resina"
                       value="resina"
                       checked={form.tipoMaterial === "resina"}
                       onChange={(e) => updateForm({ tipoMaterial: e.target.value, param: form.servicoOpcoes })}
                     />
                     <label id="lblResina" htmlFor="resina" className="form-check-label">Resina</label>
                   </div>
                   <div className="form-check form-check-inline">
                     <input
                       className="form-check-input"
                       type="radio"
                       name="tipoMaterial"
                       id="filamento"
                       value="filamento"
                       checked={form.tipoMaterial === "filamento"}
                       onChange={(e) => updateForm({ tipoMaterial: e.target.value, param: form.servicoOpcoes })}
                     />
                     <label id="lblFilamento" htmlFor="filamento" className="form-check-label">Filamento</label>
                   </div>
                   <br />
                   <br />

                </div>

                <div id="div-cg">
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tipoOpcoes"
                      id="arcoTotal"
                      value="arcoTotal"
                      checked={form.tipoOpcoes === "arcoTotal"}
                      onChange={(e) => updateForm({ tipoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label id="lblArcoTotal" htmlFor="arcoTotal" className="form-check-label">Arco Total</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tipoOpcoes"
                      id="implantesUnitarios"
                      value="implantesUnitarios"
                      checked={form.tipoOpcoes === "implantesUnitarios"}
                      onChange={(e) => updateForm({ tipoOpcoes: e.target.value, param: form.servicoOpcoes })}
                    />
                    <label id="lblImplantesUnitarios" htmlFor="implantesUnitarios" className="form-check-label">Implantes Unitários</label>
                  </div>

                  <br />
                  <br />
                  <label id="lblKit" htmlFor="kit" className="form-check-label">Kit Cirurgico Guiado</label>
                  <select id="kit" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                    onChange={(e) => updateForm({ kit: e.target.value, param: form.servicoOpcoes })}>
                  </select>

                  <div className="row">
                    <div className="col-6">
                      <label id="lblSistema" htmlFor="sistema" className="form-check-label">Sistema</label>
                      <select id="sistema" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                        onChange={(e) => updateForm({ sistema: e.target.value, param: form.servicoOpcoes })}>
                      </select>
                    </div>
                    <div className="col-6">
                      <label id="lblModelo" htmlFor="modelo" className="form-check-label">Modelo</label>
                      <select id="modelo" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                        onChange={(e) => updateForm({ modelo: e.target.value, param: form.servicoOpcoes })}>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="envioParaRadiologia"
                        onChange={(e) => updEnvioRadiologia(e.target.checked)}
                      />
                      <label htmlFor="envioParaRadiologia" className="form-check-label">Envio p/ Radiologia</label>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control dataEnvio"
                        id="dataEnvioParaRadiologia"
                        onChange={(e) => updateForm({ dataEnvioParaRadiologia: e.target.value, param: form.servicoOpcoes })}
                      />
                    </div>
                  </div>

                  <div className="col-6 pad-bot-0">
                    <label htmlFor="radiologia.nome" className="form-check-label">Radiologia</label>
                    <select id="radiologia.nome" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                      onChange={trataRadiologia} >
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="possuiExame"
                        onChange={(e) => possuiExame(e.target.checked)}
                      />
                      <label htmlFor="possuiExame" className="form-check-label">Possui Exame</label>
                    </div>
                  </div>

                  <div className="col-9">
                  </div>

                </div>

                <div className="row">
                  <div className="col-12">
                      <br />
                      <input
                        type="submit"
                        value="Gerar Requisição"
                        className="btn btn-primary shadow1"
                        onClick={(e) => geraRequisicao('/requisicao')}
                      />
                  </div>
                </div>
                <br />
              </div>

              <div id="area-restante">
                <div className="row">
                  <hr className="linha"/>
                  <div className="col-12 pad-top">
                    <label htmlFor="observacaoRadiologia">Link Exames de Imagem</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="observacaoRadiologia"
                      value={form.observacaoRadiologia}
                      onBlur={(e) => atualizaLink("observacaoRadiologia", e.target.value, "linkContainer")}
                      onChange={(e) => updateForm({ observacaoRadiologia: e.target.value, param: form.servicoOpcoes })}
                    />
                    <div id="linkContainer"></div>
                  </div>

                  <div className="col-4">
                    <div className="form-check form-check-inline pad-top10">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="envioParaPlanejador"
                        onChange={(e) => updEnvioPlanejador(e.target.checked)}
                      />
                      <label htmlFor="envioParaPlanejador" className="form-check-label">Recebimento e Envio p/ Planner</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control dataEnvio"
                        id="dataEnvioParaPlanejador"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-2">
                  </div>

                  <div className="col-6 pad-top10">
                    <label htmlFor="planejador" className="form-check-label">Planner</label>
                    <select id="planejador" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                      onChange={(e) => updateForm({ planejador: e.target.value, param: form.servicoOpcoes })}>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <label htmlFor="planejadorTelefone">Telefone</label>
                    <input
                      type="text"
                      className="form-control dataEnvio"
                      disabled
                      id="planejadorTelefone"/>
                  </div>
                  <div className="col-6">
                    <label htmlFor="planejadorEmail">email</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      id="planejadorEmail"/>
                  </div>
                </div>

                <br />
                <div className="row">
                  <hr className="linha"/>
                  <div className="col-4">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="recebimentoPlanejador"
                        onChange={(e) => updRecebidoPlanejador(e.target.checked)}
                      />
                      <label htmlFor="recebimentoPlanejador" className="form-check-label">Recebido p/ Planner</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control dataEnvio"
                        id="dataRecebimentoPlanejador"
                        disabled
                        onChange={(e) => updateForm({ dataRecebimentoPlanejador: e.target.value, param: form.servicoOpcoes })}
                      />
                    </div>
                  </div>

                  <div className="col-12 pad-top10">
                    <label htmlFor="observacaoPlanejador">Observação do Planner (se necessário)</label>
                    <textarea
                      type="text"
                      className="form-control observacao-planejador"
                      id="observacaoPlanejador"
                      value={form.observacaoPlanejador}
                      onChange={(e) => updateForm({ observacaoPlanejador: e.target.value, param: form.servicoOpcoes })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 pad-top10">
                    <label htmlFor="linkPreviaPlanejamento">Links</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="linkPreviaPlanejamento"
                      value={form.linkPreviaPlanejamento}
                      onChange={(e) => updateForm({ linkPreviaPlanejamento: e.target.value, param: form.servicoOpcoes })}
                    />
                  </div>
                  <div className="col-3">
                    <div className="arquivo pad-top23">
                      <label className="custom-file-upload shadow1"><BsCloudUpload />
                        <form id="formData" name="formData" method="post" encType="multipart/form-data">
                          <input type="file" id="anexosPrevia" name="files" multiple onChange={(a) => alteraArquivo(a)}/>Anexar
                        </form>
                      </label>
                    </div>
                  </div>
                  <div className="col-9 pad-top20">
                    <div className="cNomeArquivosPrevia" id="dNomeArquivosPrevia">
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 pad-top20">
                    <label id="lblStatus" htmlFor="kit" className="form-check-label">Status</label>
                    <select id="status" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                      onChange={(e) => updateForm({ status: e.target.value, param: form.servicoOpcoes })}>
                      <option value=""></option>
                      <option value="atencao">ATENÇÃO</option>
                      <option value="contraindicacao">CONTRAINDICAÇÃO</option>
                      <option value="ok">OK</option>
                    </select>
                  </div>

                  <div className="col-8">
                    <label htmlFor="motivo">Motivo</label>
                    <textarea
                      type="text"
                      className="form-control motivo"
                      id="motivo"
                      value={form.motivo}
                      onChange={(e) => updateForm({ motivo: e.target.value, param: form.servicoOpcoes })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-check form-check-inline pad-top10">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="concluidoPeloPlanejador"
                        onChange={(e) => updConcluidoPlanejador(e.target.checked)}
                      />
                      <label htmlFor="concluidoPeloPlanejador" className="form-check-label">Concluído pelo Planner</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control dataEnvio"
                        id="dataConcluidoPeloPlanejador"
                        disabled
                        onChange={(e) => updateForm({ dataConcluidoPeloPlanejador: e.target.value, param: form.servicoOpcoes })}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                  </div>
                </div>

                <hr className="linha"/>
                <div id="area-dentista2">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check form-check-inline pad-top0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="revisaoPeloDentista"
                          onChange={(e) => updRevisaoDentista(e.target.checked)}
                        />
                        <label htmlFor="revisaoPeloDentista" className="form-check-label">Revisão pelo Cliente</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control dataEnvio"
                          id="dataRevisaoPeloDentista"
                          disabled
                          onChange={(e) => updateForm({ dataRevisaoPeloDentista: e.target.value, param: form.servicoOpcoes })}
                        />
                      </div>
                    </div>
                    <br />
                  </div>

                  <div className="col-12">
                    <br />
                    <label htmlFor="motivo">Alterações Necessárias</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="observacaoDentista"
                      value={form.observacaoDentista}
                      onChange={(e) => updateForm({ observacaoDentista: e.target.value, param: form.servicoOpcoes })}
                    />
                  </div>

                  <div className="col-6">
                    <div className="form-check form-check-inline pad-top10">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="aprovadoPeloDentista"
                        onChange={(e) => updAprovadoDentista(e.target.checked)}
                      />
                      <label htmlFor="aprovadoPeloDentista" className="form-check-label">Aprovação Cliente</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control dataEnvio"
                        id="dataAprovadoPeloDentista"
                        disabled
                        onChange={(e) => updateForm({ dataAprovadoPeloDentista: e.target.value, param: form.servicoOpcoes })}
                      />
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <hr className="linha"/>
                  <p><b>Produção</b></p>
                  <div className="col-3">
                    <div className="arquivo pad-top0">
                      <label className="custom-file-upload shadow1"><BsCloudUpload />
                        <form id="formDataD" name="formDataD" method="post" encType="multipart/form-data">
                          <input type="file" id="anexosDocumento" name="filesD" multiple onChange={(a) => alteraArquivoDoc(a)}/>Docs
                        </form>
                      </label>
                    </div>
                  </div>
                  <div className="col-9 pad-top20">
                    <div className="cNomeArquivosDocumento" id="dNomeArquivosDocumento">
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div className="col-3">
                    <div className="arquivo pad-top0">
                      <label className="custom-file-upload shadow1"><BsCloudUpload />
                        <form id="formDataP" name="formDataP" method="post" encType="multipart/form-data">
                          <input type="file" id="anexoProtocolo" name="filesP" onChange={(a) => alteraArquivoProtocolo(a)}/>Protocolo Cirúrgico
                        </form>
                      </label>
                    </div>
                  </div>
                  <div className="col-9 pad-top20">
                    <div className="cNomeArquivoProtocolo" id="dNomeArquivoProtocolo">
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <br />
                  <label id="lblObservacaoParaProducao" htmlFor="motivo">Observação p/ Produção</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="observacaoParaProducao"
                    value={form.observacaoParaProducao}
                    onChange={(e) => updateForm({ observacaoParaProducao: e.target.value, param: form.servicoOpcoes })}
                  />
                </div>

                <br />
                <div className="row">
                  <div className="col-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="recebidoPelaProducao"
                        onChange={(e) => updRecebidoProducao(e.target.checked)}
                      />
                      <label htmlFor="recebidoPelaProducao" className="form-check-label">Recebido pela Produção</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control dataEnvio"
                        id="dataRecebidoPelaProducao"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="finalizado"
                        onChange={(e) => updFinalizado(e.target.checked)}
                      />
                      <label htmlFor="finalizado" className="form-check-label">Finalizado</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control dataEnvio"
                        id="dataFinalizado"
                        disabled
                      />
                    </div>
                  </div>

                </div>

                <br />
                <div className="row">

                  <div className="col-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="despachado"
                        onChange={(e) => updDespachado(e.target.checked)}
                      />
                      <label htmlFor="despachado" className="form-check-label">Enviado</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control dataEnvio"
                        id="dataDespachado"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 pad-top10">
                    <label htmlFor="transportado">Transporte</label>
                    <input
                      type="text"
                      className="form-control"
                      id="transportado"
                      value={form.transportado}
                      onChange={(e) => updateForm({ transportado: e.target.value, param: form.servicoOpcoes })}
                    />
                  </div>
                  <div className="col-6 pad-top10">
                    <label htmlFor="rastreio">Rastreio</label>
                    <input
                      type="text"
                      className="form-control"
                      id="rastreio"
                      value={form.rastreio}
                      onChange={(e) => updateForm({ rastreio: e.target.value, param: form.servicoOpcoes })}
                    />
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-2 pad-top10">
                  <div className="form-group butSalvar">
                    <input
                      type="submit"
                      value="Salvar"
                      className="btn btn-primary shadow1"
                      onClick={(e) => onClickSubmit(true)}
                    />
                  </div>
                </div>

                <div className="col-2 pad-top10">
                  <div className="form-group butSalvar">
                    <input
                      type="submit"
                      value="Serviço"
                      className="btn btn-primary shadow1"
                      onClick={(e) => onClickServico()}
                    />
                  </div>
                </div>

              </div>
              <br />
          </div>
        </div>

      </div>
 );

}
