import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import CurrencyInput, { formatValue } from 'react-currency-input-field';

import { FaCheck } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";

import { IconContext } from "react-icons";

import useForceUpdate from 'use-force-update';

import { onConfirm } from 'react-confirm-pro';

import './style/fatura-os.css';
import Sidebar from './sidebar';

let _idOS;
let aDentistaTelefone = [];
let aDentistaEmail = [];
let aIdDentista = [];
let _idUsuarioDentista = "";
let emFiltro = false;
let recordsTabela;
let valorUnitarioItem;
let faturamento;
let aItensConfirmados = [];
let novoValor;
let _tipoUsuario;

export default function FaturaEdit() {

  let RecordItens = (props) => (
        <div className="row pad-bottom0">
          <div className="col-7 pad-bottom0">
            <p id="itDescricao" className="pad-bottom0 pad-top0">{props.recordItem.descricao}</p>
          </div>

          <div className="col-2 pad-bottom0 keep-right">
            <p id="itQuantidade" className="pad-bottom0 pad-top0">{props.recordItem.quantidade}</p>
          </div>

          <div className="col-2 pad-bottom0 keep-right">
            <p id="itValor" className="pad-bottom0 pad-top0">{props.recordItem.itemPrecoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
          </div>

          <div className="col-1 pad-bottom0">
            <button id="botApagaItem" className="bot-add pad-bottom0" onClick={(e) => clicouApagaItem(props.recordItem)}>
                <MdDelete />
            </button>
          </div>
        </div>
  );

  const Record = (props) => (
      <div className="container pad-bottom0 pad-top0">
        <div className="row pad-bottom0 pad-top0">
          <div className="col-8 pad-bottom0 pad-top0">
            <p id="tabelaDescricao" className="pad-bottom0 pad-top0">{props.record.descricao}</p>
          </div>
          <div className="col-2 pad-bottom0 pad-top0 keep-right">
            <p id="tabelaPreco" className="pad-bottom0 pad-top0">{props.record.preco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
          </div>
          <div className="col-2 pad-bottom0 pad-top0">
            <button id="botNovoItem" className="bot-add pad-bottom0 pad-top0" onClick={(e) => clicouNovoItem(props.record)}>
                <AiOutlinePlus />
            </button>
          </div>
        </div>
      </div>
  );

  const forceUpdate = useForceUpdate();
  let [records, setRecords] = useState([]);
  let [recordItens, setRecordItens] = useState([]);
  let [chave, setChave] = useState([]);
  let [recordsFull, setRecordsFull] = useState([]);
  let [form, setForm] = useState({
   servico: "",
   nomePaciente: "",
   dentistaTelefone: "",
   idOS: "",
   dataOS: "",
   planoTratamento: "",
   idUsuario: ""
  });
  const params = useParams();
  const navigate = useNavigate();

  let _idUsuario = getStorage("_idUsuario");

  async function clicouApagaItem(param) {
    let row = 0;
    return recordItens.map((recordItem) => {
      if(recordItem.descricao === param.descricao){
        recordItens.splice(row, 1);
        setRecordItens(recordItens);
        forceUpdate();
        if(recordItens.length === 0){
          document.getElementById("somaTotal").value = "";
        }
      }
      row++;
    })
  }

  async function clicouNovoItem(param) {
    document.getElementById('itemDescricao').value = param.descricao;
    valorUnitarioItem = param.preco;

    let valorFormatado = formatValue({
      value: param.preco.toString(),
      intlConfig: { locale: 'pt-BR', currency: 'BRL' },
    });
    document.getElementById('itemPrecoTotal').value = valorFormatado;
    document.getElementById('itemQuantidade').value = 1;
    document.getElementById('itemQuantidade').focus();
  }

  async function confirmaItem() {
    if(document.getElementById('itemDescricao').value.length === 0){
      toast.error('Informe a Descrição!');
      document.getElementById('itemDescricao').focus();
      return;
    }
    if(document.getElementById('itemQuantidade').value.length === 0){
      toast.error('Informe a Quantidade!');
      document.getElementById('itemQuantidade').focus();
      return;
    }
    let validaValor = document.getElementById('itemPrecoTotal').value;
    if(validaValor.length === 0){
      toast.error('Informe o Valor!');
      document.getElementById('itemPrecoTotal').value = "";
      document.getElementById('itemPrecoTotal').focus();
      return;
    }else{
      if(validaValor.includes("NaN")){
        toast.error('Valor Inválido!');
        document.getElementById('itemPrecoTotal').value = "";
        document.getElementById('itemPrecoTotal').focus();
        return;
      }
    }
    novoValor = document.getElementById('itemPrecoTotal').value.replace("R$", "");
    novoValor = novoValor.replace(".", "");
    novoValor = novoValor.replace(".", "");
    novoValor = novoValor.replace(",", ".");
    novoValor = Number(novoValor)

    let novoItem = {idItem: aItensConfirmados.length + 1,
                    descricao: document.getElementById('itemDescricao').value,
                    quantidade: document.getElementById('itemQuantidade').value,
                    itemPrecoTotal: novoValor
                   };
    aItensConfirmados.unshift(novoItem);
    setRecordItens(aItensConfirmados);
    forceUpdate();
    document.getElementById('itemDescricao').value = "";
    document.getElementById('itemQuantidade').value = "";
    document.getElementById('itemPrecoTotal').value = "";
  }

  function itensConfirmados() {
    let somaTotal = 0;
    return recordItens.map((recordItem) => {
      somaTotal = somaTotal + recordItem.itemPrecoTotal;
      document.getElementById("botFaturar").disabled = false;
      document.getElementById("somaTotal").value = somaTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      return (
        <RecordItens
          recordItem={recordItem}
          key={recordItem.idItem}
        />
      );
    });
  }

  function getStorage(prm) {
     let _prm = window.localStorage.getItem(prm);
     if(_prm === "null"){
       navigate("/login");
     }else{
       if(_prm) {
         return _prm;
       }else{
         navigate("/login");
       }
     }
  }

  function desenhaForm(value){
     let prm;
     if( value.servicoOpcoes ) {
       prm = value.servicoOpcoes;
     }else{
       prm = value.param;
     }
     let _servico = "";
     if( prm === "servicoCirurgiaGuiada" ){
       _servico = "Cirurguia Guiada";
     }
     if( prm === "servicoPrototipagemOssea" ){
       _servico = "Prototipagem Ossea";
     }
     if( prm === "servicoCirurgiaOrtognatica" ){
       _servico = "Cirurgia Ortognática";
     }
     if( prm === "servicoEnceramentoDigital" ){
       _servico = "Enceramento Digital";
     }
     if( prm === "servicoGuiaPeriodontal" ){
       _servico = "Guia Periodontal";
     }
     if( prm === "servicoGuiaEndodontico" ){
       _servico = "Guia Endodôntico";
     }
     if( prm === "servicoPlacaMiorrelaxante" ){
       _servico = "Placa Miorrelaxante";
     }
     if( prm === "servicoModeloImpresso" ){
       _servico = "Modelo Impresso";
     }
     if( prm === "servicoOutro" ){
       _servico = "Outros Serviços";
     }
     document.getElementById("servico").value = _servico;
  }

  useEffect(() => {

     async function fetchOS() {
       let n = 0;
       _idOS = params.id.toString();
       _tipoUsuario = getStorage("_tipoUsuario");
console.log(_tipoUsuario)
       _idUsuario = getStorage("_idUsuario");

       const response = await fetch(`https://www.zaping.io:8448/os/` + _tipoUsuario + `/` + _idUsuario + `/` + _idOS);

       if (!response.ok) {
         toast.error('ERRO!');
         return;
       }

       let record = await response.json();

       let _dentista = record[0].idUsuarioDentista;
       let _dentistaTelefone = record[0].dentistaTelefone;
       let _emailFinanceiro = "";
       if(record[0].dentista){
         _idUsuarioDentista = record[0].idUsuarioDentista;
         _dentista = record[0].idUsuarioDentista;
         _dentistaTelefone = record[0].dentistaTelefone;
         _emailFinanceiro = record[0].emailFinanceiro;
       }
       document.getElementById("pagamentoForma").value = record[0].pagamentoPadrao.forma;
       document.getElementById("pagamentoCondicao").value = record[0].pagamentoPadrao.condicao;

       record = record[0].resGetOS;
       if(record[0].faturamento){
         if(record[0].faturamento.itens){
           aItensConfirmados = record[0].faturamento.itens;
           setRecordItens(aItensConfirmados);
         }
       }else{
         document.getElementById("botFaturar").disabled = true;
         aItensConfirmados = [];
         setRecordItens(aItensConfirmados);
       }
       if(_tipoUsuario === "dentista"){
         document.getElementById("area-adm1").style.visibility = "hidden";
         document.getElementById("area-adm1").style.display = "none";
         document.getElementById("area-adm2").style.visibility = "hidden";
         document.getElementById("area-adm2").style.display = "none";
       }

       if (!record) {
         toast.error('OS não Encontrada!');
         navigate("/os-lista");
         return;
       }

       setForm(record[0]);
       document.getElementById("dataOS").value = new Date(record[0].dataOS).toLocaleDateString('pt-BR');

       desenhaForm({ servicoOpcoes: record[0].servicoOpcoes });

       let indexLab;

        const responseUsuario = await fetch("https://www.zaping.io:8448/usuario-lista/" + _tipoUsuario + "/" + _idUsuario);

        if (!responseUsuario.ok) {
          const message = `ERRO: ${responseUsuario.statusText}`;
          window.alert(message);
          return;
        }

        const recordsUsuario = await responseUsuario.json();

        let indexDentista = 0;
        let optionsDentista = [];
        const selectDentista = document.getElementById('dentista');
        let optDentista = document.createElement('option');
        optDentista.value = 0;
        optDentista.textContent = '';
        selectDentista.appendChild(optDentista);
        let d = 0;

        for (let u = 1; u < recordsUsuario.length+1; u++) {
          if(recordsUsuario[u-1].tipoUsuario === "dentista"){
            optDentista = document.createElement('option');
            optDentista.value = d + 1;
            optDentista.textContent = recordsUsuario[u-1].nome;
            selectDentista.appendChild(optDentista);
            if(record[0].idUsuarioDentista === recordsUsuario[u-1]._id){
              indexDentista = d + 1;
              document.getElementById('dentistaTelefone').value = recordsUsuario[u-1].telefoneCelular;
              document.getElementById('emailFinanceiro').value = recordsUsuario[u-1].emailFinanceiro;
            }

            aIdDentista[d] = recordsUsuario[u-1]._id;
            aDentistaTelefone[d] = recordsUsuario[u-1].telefoneCelular;
            aDentistaEmail[d] = recordsUsuario[u-1].login;
            d++;
          }
        }
        document.getElementById('dentista').selectedIndex = indexDentista;
        optionsDentista = [];

        document.getElementById('dentistaTelefone').disabled = true;
     }

     async function fetchProdutoServico() {
        const responseTabela = await fetch("https://www.zaping.io:8448/tabela/1");

        if (!responseTabela) {
          const message = `ERRO: ${responseTabela.statusText}`;
          window.alert(message);
          return;
        }

        recordsTabela = await responseTabela.json();
        setRecords(recordsTabela);
        setRecordsFull(recordsTabela);
     }

     fetchOS();

     fetchProdutoServico();

     return;
  }, [params.id, navigate]);

  function listaTabela() {
    let oRecord;

    return records.map((record) => {
      return (
        <Record
          record={record}
          key={record._id}
        />
      );
    });
  }

  function updateForm(value) {
     return setForm((prev) => {
       return { ...prev, ...value };
     });
  }

  async function onClickSalvar() {
     let valorTotal = document.getElementById("somaTotal").value.replace("R$", "");
     valorTotal = valorTotal.replace(".", "");
     valorTotal = valorTotal.replace(".", "");
     valorTotal = valorTotal.replace(",", ".");
     valorTotal = Number(valorTotal);
     let pagamentocondicao = document.getElementById("pagamentoCondicao").value;

     let dentista = document.getElementById("dentista");
     dentista=dentista.options[dentista.selectedIndex].text;// get selected option value
     const salva = {
                    data: new Date(),
                    nomePaciente: document.getElementById("nomePaciente").value,
                    dentistaNome: dentista,
                    dentistaTelefone: document.getElementById("dentistaTelefone").value,
                    emailFinanceiro: document.getElementById("emailFinanceiro").value,
                    pagamento: {forma: document.getElementById("pagamentoForma").value,
                                condicao: pagamentocondicao},
                    itens: recordItens,
                    valorTotal: valorTotal,
                    valorAcrecimo: 0,
                    valorDesconto: 0,
                    valorFaturado: valorTotal
                   };

     // This will send a post request to update the data in the database.
     await fetch(`https://www.zaping.io:8448/grava-os/` + form.idOS, {
       method: "POST",
       body: JSON.stringify(salva),
       headers: {
         'Content-Type': 'application/json'
       },
     });

     toast.success('OS Gravada com Sucesso!');
     navigate("/faturamento-lista");
  }

  async function onClickFaturar() {
     let valorTotal = document.getElementById("somaTotal").value.replace("R$", "");
     valorTotal = valorTotal.replace(".", "");
     valorTotal = valorTotal.replace(".", "");
     valorTotal = valorTotal.replace(",", ".");
     valorTotal = Number(valorTotal);
     if(valorTotal === 0){
       toast.error('Sem Valor a Faturar!');
       return;
     }

     let pagamentocondicao = document.getElementById("pagamentoCondicao").value;

     let dentista = document.getElementById("dentista");
     dentista=dentista.options[dentista.selectedIndex].text;// get selected option value

     let idFatura = form.idOS + "1";

     let dataVencimento = new Date();
      
      const currentDay = dataVencimento.getDate();
      let currentYear = dataVencimento.getFullYear();
      let nextMonth = dataVencimento.getMonth() + 1; // Note: month starts from 0
      let nextDay; 
      if(currentDay >= 6 && currentDay <=20){
        nextDay = 20;
      }else{
        nextDay = 5;
      }
      if (nextMonth > 11) {
        nextMonth = 0;
        currentYear++;
      }
      
      dataVencimento = new Date(currentYear, nextMonth, nextDay);
      
     const salva = {
                    data: new Date(),
                    nomePaciente: document.getElementById("nomePaciente").value,
                    dentistaNome: dentista,
                    dentistaTelefone: document.getElementById("dentistaTelefone").value,
                    emailFinanceiro: document.getElementById("emailFinanceiro").value,
                    valorFaturado: valorTotal,
                    valorFaturadoStr: document.getElementById("somaTotal").value,
                    fatura: {
                              idFatura: idFatura,
                              dataVencimento: dataVencimento,
                              valor: valorTotal,
                              status: "aberto"
                            }
                   };

     await fetch(`https://www.zaping.io:8448/fatura-os/` + form.idOS, {
       method: "POST",
       body: JSON.stringify(salva),
       headers: {
         'Content-Type': 'application/json'
       },
     });

     toast.success('OS Faturada com Sucesso!');
     navigate("/faturamento-lista");
  }

  async function onClickBaixarFatura() {

    const onClickLight = () => {
      onConfirm({
        title: (
          <h3>
            Confirma Baixa?
          </h3>
        ),
        btnCancel: "Não",
        btnSubmit: "Sim",
        description: (
          <p></p>
        ),
        onCancel: () => {
          return;
        },
        onSubmit: async () => {
          const salva = {
                          status: "pago"
                        };

          const responseBaixa = await fetch(`https://www.zaping.io:8448/baixa-fatura/` + form.idOS, {
            method: "POST",
            body: JSON.stringify(salva),
            headers: {
              'Content-Type': 'application/json'
            },
          });

          if (!responseBaixa.ok) {
            const message = `ERRO: ${responseBaixa.statusText}`;
            window.alert(message);
            return;
          }else{
            toast.success('Fatura Baixada com Sucesso!');
          }

          navigate("/faturamento-lista");
        },
      })
    };

    onClickLight();

  }

  async function onClickImprimirOS() {
     let url = "/os-imprime/" + form.idOS;
     window.localStorage.setItem('_idOS', _idOS);
     window.localStorage.setItem('_nomePaciente', document.getElementById('nomePaciente').value);
     window.localStorage.setItem('_dentista', document.getElementById('dentista').value);
     window.localStorage.setItem('_dentistaTelefone', document.getElementById('dentistaTelefone').value);
     window.localStorage.setItem('_servicoOpcoes', form.servicoOpcoes);
     if( form.servicoOpcoes === "servicoCirurgiaGuiada" ){
       let arquivo = "";
       if( form.tipoOpcoes === "arcoTotal" ){
         arquivo = "total";
       }else{
         if( form.tipoOpcoes === "implantesUnitarios" ){
           arquivo = "parcial";
         }
       }
       window.localStorage.setItem('_nomeServico', "Cirurgia Guiada");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/cirurgia-guiada-" + arquivo + ".pdf");
     }
     if( form.servicoOpcoes === "servicoPrototipagemOssea" ){
       window.localStorage.setItem('_nomeServico', "Prototipagem Óssea");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/prototipagem-ossea.pdf");
     }
     if( form.servicoOpcoes === "servicoCirurgiaOrtognatica" ){
       window.localStorage.setItem('_nomeServico', "Cirurgia Ortognática");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/cirurgia-ortognatica.pdf");
     }
     if( form.servicoOpcoes === "servicoEnceramentoDigital" ){
       window.localStorage.setItem('_nomeServico', "Enceramento Digital");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/enceramento-digital.pdf");
     }
     if( form.servicoOpcoes === "servicoGuiaPeriodontal" ){
       window.localStorage.setItem('_nomeServico', "Guia Periodontal");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/guia-periodontal.pdf");
     }
     if( form.servicoOpcoes === "servicoGuiaEndodontico" ){
       window.localStorage.setItem('_nomeServico', "Guia Endodôntico");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/guia-endodontico.pdf");
     }
     if( form.servicoOpcoes === "servicoPlacaMiorrelaxante" ){
       window.localStorage.setItem('_nomeServico', "Placa Miorrelaxante");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/placa-miorrelaxante.pdf");
     }
     if( form.servicoOpcoes === "servicoModeloImpresso" ){
       window.localStorage.setItem('_nomeServico', "Modelo Impresso");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/modelo-impresso.pdf");
     }
     if( form.servicoOpcoes === "servicoOutro" ){
       window.localStorage.setItem('_nomeServico', "Outros Serviços");
       window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/outros.pdf");
     }

     const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
     if (newWindow) newWindow.opener = null
  }

  const trataQuantidade = (param) => {
    novoValor = valorUnitarioItem * param;
    let valorFormatadoAlterado = formatValue({
      value: novoValor.toString(),
      intlConfig: { locale: 'pt-BR', currency: 'BRL' },
    });
    if(valorFormatadoAlterado.includes("NaN")){
      document.getElementById("itemPrecoTotal").value = "";
    }else{
      document.getElementById("itemPrecoTotal").value = valorFormatadoAlterado;
    }
  }

  const buscaProdutoServico = (param) => {
    setChave(param);

    if(param.length > 0){
      param = param.toLowerCase();
      let result = records.filter(rec => rec.descricao.toLowerCase().includes(param))
      emFiltro = true;
      setRecords(result);
    }else{
      emFiltro = false;
      setRecords(recordsFull);
    }
  }

  function BuscaOS() {
     return (
       <div className="conteudo pad-bottom0">
             <input className="textoFiltro pad-bottom0"
               type="text"
               id="chave"
               placeholder="Digite o Serviço/Produto"
               autoFocus="autoFocus"
               value={chave}
               onChange={(e) => buscaProdutoServico(e.target.value)} />
       </div>
     )
  }

   return (
        <div className="div1">
          <Sidebar />

          <div className="iConteudo">
            <div className="iContainer">

              <div className="row pad-bottom0">
                <div className="col-6 pad-bottom0">
                  <label htmlFor="servico">Serviço</label>
                  <input
                    type="text"
                    className="form-control"
                    id="servico"
                    disabled
                    value={form.servico}
                  />
                </div>
                <div className="col-6 pad-bottom0">
                  <label htmlFor="dentista" className="form-check-label">Dentista</label>
                  <select id="dentista" className="form-select form-select-lg mb-3 pad-bottom0" aria-label=".form-select-lg example"
                    disabled>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label htmlFor="dentistaTelefone">Telefone</label>
                  <input
                    type="text"
                    className="form-control dataEnvio"
                    disabled
                    id="dentistaTelefone"/>
                </div>

                <div className="col-6">
                  <label htmlFor="emailFinanceiro">Email Financeiro</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    id="emailFinanceiro"/>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="nomePaciente">Paciente</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nomePaciente"
                      disabled
                      value={form.nomePaciente}
                    />
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group idOS">
                    <label htmlFor="idOS">OS</label>
                    <input
                       type="text"
                       className="form-control idOS"
                       id="idOS"
                       disabled
                       value={form.idOS}
                    />
                  </div>
                </div>

                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="dataOS">Criada em</label>
                    <input
                       type="text"
                       className="form-control"
                       id="dataOS"
                       disabled
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="planoTratamento">Plano de Tratamento</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="planoTratamento"
                  disabled
                  value={form.planoTratamento}
                />
              </div>

              <div className="row">
                <div className="col-6">
                  <label htmlFor="pagamentoForma">Forma de Pagamento</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pagamentoForma"
                    value={form.pagamentoForma}
                    disabled
                  />
                </div>

                <div className="col-6">
                  <label htmlFor="pagamentoCondicao">Condição</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pagamentoCondicao"
                    value={form.pagamentoCondicao}
                    disabled
                  />
                </div>
              </div>

              <div className="row pad-bottom10">
                <div className="col-7">
                  <p>Item</p>
                </div>
                <div className="col-2 keep-right">
                  <p>Quantidade</p>
                </div>
                <div className="col-2 keep-center">
                  <p>Valor</p>
                </div>
                <div className="col-1">
                </div>
              </div>
              <hr className="linha pad-bottom0 pad-top0"/>

              <div className="areaItensConfirmados pad-bottom0 pad-top0">
                {itensConfirmados()}
              </div>

              <div className="row pad-bottom0 pad-top0">
                <div className="col-7">
                </div>
                <div className="col-2 keep-right pad-top9">
                  <p>Total:</p>
                </div>
                <div className="col-2 keep-right">
                  <input
                    type="text"
                    className="form-control keep-right"
                    id="somaTotal"
                    disabled
                    value={form.somaTotal}
                  />
                </div>
                <div className="col-1">
                </div>
              </div>

              <div id="area-adm1 pad-bottom0">
                <div className="row pad-bottom0">
                  <div className="col-7">
                    <input
                      type="text"
                      className="form-control"
                      id="itemDescricao"
                      value={form.itemDescricao}
                    />
                  </div>

                  <div className="col-2">
                    <input
                      type="text"
                      className="form-control keep-right"
                      id="itemQuantidade"
                      value={form.itemQuantidade}
                      onChange={(e) => trataQuantidade( e.target.value )}
                    />
                  </div>

                  <div className="col-2">
                    <CurrencyInput
                      id="itemPrecoTotal"
                      name="itemPrecoTotal"
                      decimalsLimit={2}
                      intlConfig={{ locale: 'pt-Br', currency: 'BRL' }}
                      className="form-control keep-right"
                    />
                  </div>

                  <div className="col-1">
                    <button id="confirmaItem" onClick={(e) => confirmaItem()} className="btn btn-primary shadow2">
                        <FaCheck />
                    </button>
                  </div>
                </div>

              </div>

              <div className="admpad-bottom0" id="area-adm2">
                <BuscaOS />

                <div className="tabelaServico pad-top0 pad-bottom0">
                  {listaTabela()}
                </div>

                <div className="row pad-top5">
                  <div className="col-3">
                    <div className="form-group butSalvar">
                      <input
                        type="submit"
                        value="Salvar"
                        className="btn btn-primary shadow2"
                        onClick={(e) => onClickSalvar()}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group butSalvar">
                      <input
                        id="botFaturar"
                        type="submit"
                        value="Faturar"
                        className="btn btn-primary shadow2"
                        onClick={(e) => onClickFaturar()}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group butSalvar">
                      <input
                        id="botImprimir"
                        type="submit"
                        value="Imprimir OS"
                        className="btn btn-primary shadow2"
                        onClick={(e) => onClickImprimirOS()}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group butSalvar">
                      <input
                        id="botBaixarFatura"
                        type="submit"
                        value="Baixar Fatura"
                        className="btn btn-primary shadow2"
                        onClick={(e) => onClickBaixarFatura()}
                      />
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
   );

}
