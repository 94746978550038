import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router";

import './style/radiologia-lista.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiOutlinePlus } from "react-icons/ai";
import { IconContext } from "react-icons";

import { toast } from 'react-toastify';

import Sidebar from './sidebar';

let emFiltro = false;

const Record = (props) => (
  <div className="conteudo">

    <div className="container">
      <div className="row">
        <div className="col-10">
          <Link className="action" to={`/radiologia-altera/${props.oRecord._id}`}>
            <p id="descricao">{props.oRecord.descricao}</p>
          </Link>
        </div>

      </div>

    </div>
  </div>
);

export default function RadiologiaList() {
 let [records, setRecords] = useState([]);
 let [recordsFull, setRecordsFull] = useState([]);
 let [chave, setChave] = useState([]);

 const navigate = useNavigate();

 function getStorage(prm) {
   let _prm = window.localStorage.getItem(prm).toLowerCase();
   if(_prm) {
     return _prm;
   }else{
     toast.error('Favor Logar Novamente!');
     navigate("/login");
   }
 }

 let _tipoUsuario = getStorage("_tipoUsuario");
 let _idUsuario = getStorage("_idUsuario");

 // This method fetches the records from the database.
 useEffect(() => {

   async function getRecords() {
     const response = await fetch(`https://www.zaping.io:8448/radiologia-lista/` + _tipoUsuario + `/` + _idUsuario);

     if (!response.ok) {
       const message = `ERRO: ${response.statusText}`;
       window.alert(message);
       return;
     }

     let records = await response.json();
     for (let r = 0; r < records.length; r++) {
       records[r].desc = records[r].uf + " " + records[r].cidade + " " + records[r].descricao;
     }

     setRecords(records);
     setRecordsFull(records);
   }

   if(emFiltro === false){
     getRecords();
   }

 }, [records.length]);

 // This method will delete a record
 async function deleteRecord(id) {
   await fetch(`http://localhost:5000/${id}`, {
     method: "DELETE"
   });

   const newRecords = records.filter((el) => el._id !== id);
   setRecords(newRecords);
 }

 function recordList() {
   let oRecord;

   return records.map((record) => {
     oRecord = {_id: record._id,
                descricao: record.desc
               }
     return (
       <Record
         record={record}
         oRecord={oRecord}
         deleteRecord={() => deleteRecord(record._id)}
         key={record._id}
       />
     );
   });
 }

 const handleSearch = (param) => {
   setChave(param);

   if(param.length > 0){
     param = param.toLowerCase();
     let result = records.filter(rec => rec.desc.toLowerCase().includes(param))
     emFiltro = true;
     setRecords(result);
   }else{
     emFiltro = false;
     setRecords(recordsFull);
   }

 }

 function FiltraRadiologia() {

   return (
     <div className="conteudo">
       <div className="row">
         <div className="col-8">
           <input className="textoFiltro"
             type="text"
             id="chave"
             placeholder="Buscar..."
             autoFocus="autoFocus"
             value={chave}
             onChange={(e) => handleSearch(e.target.value)} />
         </div>

         <div className="col-2">
         </div>

         <div className="col-2 butInclui">
           <IconContext.Provider value={{ size: "1.5em"}}>
             <Link id="mais" to="/radiologia-inclui"><AiOutlinePlus /></Link>
           </IconContext.Provider>
         </div>
       </div>
     </div>
   )
 }

 // This following section will display the table with the records of individuals.
 return (

   <div>

    <Sidebar />

    <FiltraRadiologia />

    {recordList()}
   </div>

 );
}
