import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import './style/kit-altera.css';

import Sidebar from './sidebar';

export default function Edit() {

   let [form, setForm] = useState({
     sistema: "",
     modelo: []
   });

   const params = useParams();
   const navigate = useNavigate();
   let _id = params.id.toString();

   function getStorage(prm) {
     let _prm = window.localStorage.getItem(prm);
     if(_prm) {
       return _prm;
     }else{
       toast.error('Favor Logar Novamente!');
       navigate("/login");
     }
   }

   useEffect(() => {

     async function fetchData() {
       console.log(_id)

       const response = await fetch(`https://www.zaping.io:8448/sistema_modelo/` + _id);

       if (!response.ok) {
         toast.error('ERRO!');
         return;
       }

       let record = await response.json();

       if (!record) {
         toast.error('Sistema/Modelo não Encontrado!');
         navigate("/sistema_modelo-lista");
         return;
       }
       setForm(record[0]);

     }

     fetchData();

     return;
   }, [params.id, navigate]);

   function updateForm(value) {

     return setForm((prev) => {
       return { ...prev, ...value };
     });
   }

   async function onClickSubmit() {
     let aModelo = form.modelo.split(",")
     const alteraSistemaModelo = {
       sistema: form.sistema,
       modelo: aModelo
     }
//        "CM Cortical,CM EX,CM Alvim,CM Drive,GM Helix,GM Drive,Outrosss"
console.log(alteraSistemaModelo)
       // This will send a post request to update the data in the database.
     await fetch(`https://www.zaping.io:8448/sistema_modelo-altera/` + _id, {
       method: "POST",
       body: JSON.stringify(alteraSistemaModelo),
       headers: {
         'Content-Type': 'application/json'
       },
     });
     toast.success('Sistema/Modelo Alterado com Sucesso!');

     navigate("/sistema_modelo-lista");
   }

   // This following section will display the form that takes input from the user to update the data.
   return (

      <div className="usuario-inclui">
        <Sidebar />

        <div className="iConteudo">
          <div className="iContainer">
              <br />
              <div className="form-group">
                <label htmlFor="sistema">Sistema</label>
                <input
                  type="text"
                  className="form-control"
                  id="sistema"
                  value={form.sistema}
                  onChange={(e) => updateForm({ sistema: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="descricao">Modelo (separado por vírgula)</label>
                <input
                  type="text"
                  className="form-control"
                  id="modelo"
                  value={form.modelo}
                  onChange={(e) => updateForm({ modelo: e.target.value })}
                />
              </div>

               <div className="form-group butSalvar">
                 <br />
                 <input
                   type="submit"
                   value="Salvar"
                   className="btn btn-primary shadow1"
                   onClick={(e) => onClickSubmit()}
                 />
               </div>

          </div>
        </div>

      </div>

  );

}
