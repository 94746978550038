import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "../misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import Service1Image from "../../images/service1.jpg";
import Service2Image from "../../images/service2.jpeg";
import Service3Image from "../../images/service3.jpg";
import Service4Image from "../../images/service4.jpg";
import Service5Image from "../../images/service5.jpg";

import './style/style.css';

const Container = tw.div`relative`;

let contStyle = {
  fontFamily: 'Mark Pro'
}

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-gray-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-gray-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-gray-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-gray-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: Service1Image,
      title: "PLANEJAMENTO",
      description: "OFERECEMOS TECNOLOGIA DE PONTA PARA FORNECER INFORMAÇÕES PRECISAS AOS CIRURGIÕES-DENTISTAS E SEUS PACIENTES, ALÉM DA CIRURGIA GUIADA, PARA APRIMORAR A ODONTOLOGIA DIGITAL."
    },
    { imageSrc: Service2Image,
      title: "PROTOTIPAGEM E IMPRESSÃO 3D",
      description: "OFERECEMOS UMA VARIEDADE DE SOLUÇÕES ODONTOLÓGICAS, INCLUINDO GUIAS CIRÚRGICOS PARA VÁRIOS TIPOS DE CIRURGIAS, MODELOS ÓSSEOS, PROTOTIPAGEM MÉDICA E ODONTOLÓGICA, ENTRE OUTROS."
    },

    { imageSrc: Service3Image,
      title: "CONSULTORIA",
      description: "SUPORTE, CONSULTORIA E PROTOCOLOS DE AQUISIÇÃO DE IMAGENS PARA QUE AS CLÍNICAS RADIOLÓGICAS POSSAM OFERECER EXAMES DE IMAGEM ADEQUADOS."
    },
    { imageSrc: Service4Image,
      title: "TERCEIRIZAÇÃO",
      description: "PLANEJAMENTO VIRTUAL REMOTO E FORNECIMENTO DOS ARQUIVOS DIGITAIS PRONTOS PARA IMPRESSÃO PARA LABORATÓRIOS DE PRÓTESE E RADIOLOGIAS."
     },
    { imageSrc: Service5Image,
      title: "CURSOS E TREINAMENTOS",
      description: "NÓS VALORIZAMOS A EDUCAÇÃO E O COMPARTILHAMENTO DO CONHECIMENTO POR MEIO DE CURSOS ONLINE, PRESENCIAIS E MENTORIA VIP. REALIZAMOS OS CURSOS EM CURITIBA - PR OU EM OUTROS LUGARES SOB CONVITE, CAPACITANDO PROFISSIONAIS E DISSEMINANDO MELHORES PRÁTICAS."
    }
  ];

  return (
    <Container style={contStyle}>
      <ThreeColumnContainer>
        <Heading><span tw="text-[#101010]">NOSSOS SERVIÇOS</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <img className="imageContainer" src={card.imageSrc || defaultCardImage} alt="" />
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description txtService">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
