import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import './style/kit-altera.css';

import Sidebar from './sidebar';

export default function Edit() {

   let [form, setForm] = useState({
     descricao: ""
   });

   const params = useParams();
   const navigate = useNavigate();
   let _id = params.id.toString();

   useEffect(() => {

     async function fetchData() {
       console.log(_id)

       const response = await fetch(`https://www.zaping.io:8448/kit/` + _id);

       if (!response.ok) {
         toast.error('ERRO!');
         return;
       }

       let record = await response.json();

       if (!record) {
         toast.error('Kit não Encontrado!');
         navigate("/kit-lista");
         return;
       }
       setForm(record[0]);

     }

     fetchData();

     return;
   }, [params.id, navigate]);

   function updateForm(value) {

     return setForm((prev) => {
       return { ...prev, ...value };
     });
   }

   async function onClickSubmit() {

     const alteradakit = {
       descricao: form.descricao
     }

       // This will send a post request to update the data in the database.
     await fetch(`https://www.zaping.io:8448/kit-altera/` + _id, {
       method: "POST",
       body: JSON.stringify(alteradakit),
       headers: {
         'Content-Type': 'application/json'
       },
     });
     toast.success('Kit Alterado com Sucesso!');

     navigate("/kit-lista");
   }

   // This following section will display the form that takes input from the user to update the data.
   return (

      <div className="usuario-inclui">
        <Sidebar />

        <div className="iConteudo">
          <div className="iContainer">
              <br />
              <div className="form-group">
                <label htmlFor="descricao">Descrição</label>
                <input
                  type="text"
                  className="form-control"
                  id="descricao"
                  value={form.descricao}
                  onChange={(e) => updateForm({ descricao: e.target.value })}
                />
              </div>

               <div className="form-group butSalvar">
                 <br />
                 <input
                   type="submit"
                   value="Salvar"
                   className="btn btn-primary shadow1"
                   onClick={(e) => onClickSubmit()}
                 />
               </div>

          </div>
        </div>

      </div>

  );

}
