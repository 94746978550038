import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import './style/usuario-altera.css';

import Sidebar from './sidebar';

let _tipoUsuarioAlterado;

export default function Edit() {
   let _tipoUsuario = getStorage("_tipoUsuario");
   let _idUsuario = getStorage("_idUsuario");

   let [form, setForm] = useState({
     nome: "",
     idUsuarioCadastrador: "",
     tipoUsuario: "",
     login: "",
     documento: "",
     telefoneFixo: "",
     telefoneCelular: "",
     ruaNumero: "",
     complemento: "",
     bairro: "",
     cidade: "",
     uf: "",
     cep: "",
     emailFinanceiro: "",
     pagamentoPadrao: {forma: "", condicao: "", sNF: ""}
   });

   const params = useParams();
   const navigate = useNavigate();
   let _id = params.id.toString();

   function getStorage(prm) {
     let _prm = window.localStorage.getItem(prm);
     if(_prm) {
       return _prm;
     }else{
       toast.error('Favor Logar Novamente!');
       navigate("/login");
     }
   }

   function desenhaForm(){
     let elemDentista = document.getElementById('area-dentista');
     document.getElementById("tipoUsuario").disabled = true;
     if( _tipoUsuarioAlterado !== "dentista"){
       elemDentista.style.visibility = "hidden";
       elemDentista.style.display = "none";
     }
   }

   useEffect(() => {

     async function fetchData() {

       const response = await fetch(`https://www.zaping.io:8448/usuario/` + _id);

       if (!response.ok) {
         toast.error('ERRO!');
         return;
       }

       let record = await response.json();

       if (!record) {
         toast.error('Usuário não Encontrado!');
         navigate("/usuario-lista");
         return;
       }
       setForm(record[0]);

       _tipoUsuarioAlterado = record[0].tipoUsuario;

       document.getElementById("login").value = record[0].login;
       document.getElementById("emailFinanceiro").value = record[0].emailFinanceiro;

       desenhaForm();

       const selectTipo = document.getElementById("tipoUsuario");
       let optTipo = document.createElement('option');
       let t = 0;
       let indexTipo;
       let aTipoUsuario = ["Dentista", "Planejador", "Validador", "Master", "Admin", "Financeiro"];
       for (t = 0; t < aTipoUsuario.length; t++) {
           optTipo = document.createElement('option');
           optTipo.value = t;
           optTipo.textContent = aTipoUsuario[t];
           selectTipo.appendChild(optTipo);
           if(record[0].tipoUsuario === aTipoUsuario[t].toLowerCase()){
             indexTipo = t;
           }
       }
       document.getElementById('tipoUsuario').selectedIndex = indexTipo;

       if(record[0].pagamentoPadrao){
         const selectForma = document.getElementById("formaPagamento");
         let optForma = document.createElement('option');
         let f = 1;
         let indexForma;
         let aForma = ["PIX", "Transferência", "Boleto", "Crédito"];
         for (f = 1; f < aForma.length+1; f++) {
             optForma = document.createElement('option');
             optForma.value = f;
             optForma.textContent = aForma[f-1];
             selectForma.appendChild(optForma);
             if(record[0].pagamentoPadrao.forma === aForma[f-1]){
               indexForma = f-1;
             }
         }
         document.getElementById('formaPagamento').selectedIndex = indexForma;

          const selectCondicao = document.getElementById("condicaoPagamento");
          let optCondicao = document.createElement('option');
          let c = 1;
          let indexCondicao;
          let aCondicao = [5, 20];
          for (c = 1; c < aCondicao.length+1; c++) {
              optCondicao = document.createElement('option');
              optCondicao.value = c;
              optCondicao.textContent = aCondicao[c-1];
              selectCondicao.appendChild(optCondicao);
              if(record[0].pagamentoPadrao.condicao === aCondicao[c-1]){
                indexCondicao = c-1;
              }
          }
          document.getElementById('condicaoPagamento').selectedIndex = indexCondicao;

          document.getElementById('sNF').checked = record[0].pagamentoPadrao.sNF;
        }
     }

     fetchData();

     return;
   }, [params.id, navigate]);

   function updateForm(value) {

     return setForm((prev) => {
       return { ...prev, ...value };
     });
   }

   async function onClickSubmit() {
     var lTipoUsuario = document.getElementById("tipoUsuario");
     var lForma = document.getElementById("formaPagamento");
     var lCondicao = document.getElementById("condicaoPagamento");

     const alteradoUsuario = {
       nome: form.nome,
       idUsuarioCadastrador: _idUsuario,
       tipoUsuario: lTipoUsuario.options[lTipoUsuario.options.selectedIndex].innerText.toLowerCase(),
       login: form.login,
       documento: form.documento,
       telefoneFixo: form.telefoneFixo,
       telefoneCelular: form.telefoneCelular
     }
     if(_tipoUsuarioAlterado === "dentista"){
       alteradoUsuario.ruaNumero = form.ruaNumero
       alteradoUsuario.complemento = form.complemento
       alteradoUsuario.bairro = form.bairro
       alteradoUsuario.cidade = form.cidade
       alteradoUsuario.uf = form.uf
       alteradoUsuario.cep = form.cep
       alteradoUsuario.emailFinanceiro = form.emailFinanceiro
       alteradoUsuario.pagamentoPadrao = {forma: lForma.options[lForma.options.selectedIndex].innerText,
                                         condicao: lCondicao.options[lCondicao.options.selectedIndex].innerText,
                                         sNF: document.getElementById('sNF').checked
                                       }
     }
     await fetch(`https://www.zaping.io:8448/usuario-altera/` + _id, {
       method: "POST",
       body: JSON.stringify(alteradoUsuario),
       headers: {
         'Content-Type': 'application/json'
       },
     });
     toast.success('Usuário Alterado com Sucesso!');

     navigate("/usuario-lista");
   }

   // This following section will display the form that takes input from the user to update the data.
   return (

      <div className="usuario-inclui">
        <Sidebar />

        <div className="iConteudo">
          <div className="iContainer">
              <br />
              <div className="form-group">
                <label htmlFor="nome">Nome</label>
                <input
                  type="text"
                  className="form-control"
                  id="nome"
                  value={form.nome}
                  onChange={(e) => updateForm({ nome: e.target.value })}
                />
              </div>
              <div className="row">
                 <div className="col-6">
                    <label id="lbltipoUsuario" htmlFor="tipoUsuario" className="form-check-label">Tipo</label>
                    <select id="tipoUsuario" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                      onChange={(e) => updateForm({ tipoUsuario: e.target.value })}>
                    </select>
                 </div>

                 <div className="col-6">
                   <div className="form-group">
                     <label htmlFor="documento">Documento</label>
                     <input
                       type="text"
                       className="form-control"
                       id="documento"
                       value={form.documento}
                       onChange={(e) => updateForm({ documento: e.target.value })}
                     />
                   </div>
                 </div>
              </div>

               <div className="row">
                  <div className="col-6">
                     <div className="form-group">
                       <label htmlFor="telefoneFixo">Telefone Fixo</label>
                       <input
                         type="text"
                         className="form-control"
                         id="telefoneFixo"
                         value={form.telefoneFixo}
                         onChange={(e) => updateForm({ telefoneFixo: e.target.value })}
                       />
                     </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="telefoneCelular">Celular</label>
                      <input
                        type="text"
                        className="form-control"
                        id="telefoneCelular"
                        value={form.telefoneCelular}
                        onChange={(e) => updateForm({ telefoneCelular: e.target.value })}
                      />
                    </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="login">email/login</label>
                      <input type="text" id="login" className="form-control" disabled />
                    </div>
                   </div>
               </div>

               <div id="area-dentista">
                 <div className="row">
                    <div className="col-8">
                       <div className="form-group">
                         <label htmlFor="ruaNumero">Rua e No.</label>
                         <input
                           type="text"
                           className="form-control"
                           id="ruaNumero"
                           value={form.ruaNumero}
                           onChange={(e) => updateForm({ ruaNumero: e.target.value })}
                         />
                       </div>
                    </div>

                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="complemento">Compl.</label>
                        <input
                          type="text"
                          className="form-control"
                          id="complemento"
                          value={form.complemento}
                          onChange={(e) => updateForm({complemento: e.target.value })}
                        />
                      </div>
                    </div>
                 </div>

                 <div className="row">
                    <div className="col-6">
                       <div className="form-group">
                         <label htmlFor="bairro">Bairro</label>
                         <input
                           type="text"
                           className="form-control"
                           id="bairro"
                           value={form.bairro}
                           onChange={(e) => updateForm({bairro: e.target.value })}
                         />
                       </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="cidade">Cidade</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cidade"
                          value={form.cidade}
                          onChange={(e) => updateForm({ cidade: e.target.value })}
                        />
                      </div>
                    </div>
                 </div>

                 <div className="row">
                    <div className="col-2">
                       <div className="form-group">
                         <label htmlFor="uf">UF</label>
                         <input
                           type="text"
                           className="form-control"
                           id="uf"
                           value={form.uf}
                           onChange={(e) => updateForm({ uf: e.target.value })}
                         />
                       </div>
                    </div>
                    <div className="col-4">
                    </div>

                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="cep">cep</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cep"
                          value={form.cep}
                          onChange={(e) => updateForm({cep: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                    </div>
                 </div>

                 <br />
                 <p>Pagamento Padrão</p>
                 <div className="row">
                   <div className="col-6">
                     <label id="lblFormaPagamento" htmlFor="formaPagamento" className="form-check-label">Forma</label>
                     <select id="formaPagamento" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                       onChange={(e) => updateForm({ formaPagamento: e.target.value })}>
                    </select>
                   </div>

                   <div className="col-6">
                     <label id="lblCondicaoPagamento" htmlFor="condicaoPagamento" className="form-check-label">Condição</label>
                     <select id="condicaoPagamento" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                        onChange={(e) => updateForm({ condicaoPagamento: e.target.value })}>
                     </select>
                   </div>
                 </div>

                 <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="emailFinanceiro">email Financeiro</label>
                        <input type="text"
                               id="emailFinanceiro"
                               className="form-control"
                               onChange={(e) => updateForm({ emailFinanceiro: e.target.value })}
                        />
                      </div>
                    </div>

                    <div className="col-1">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sNF"
                        />
                        <label htmlFor="sNF" className="form-check-label">SN</label>
                      </div>
                    </div>

                 </div>
               </div>

               <div className="form-group butSalvar">
                 <br />
                 <input
                   type="submit"
                   value="Salvar"
                   className="btn btn-primary shadow1"
                   onClick={(e) => onClickSubmit()}
                 />
               </div>

          </div>
        </div>

      </div>

  );

}
