import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import './style/kit-altera.css';

import Sidebar from './sidebar';
let _number;

export default function Edit() {

   let [form, setForm] = useState({
     descricao: "",
     preco: ""
   });

   const params = useParams();
   const navigate = useNavigate();
   let _id = params.id.toString();

   useEffect(() => {

     async function fetchData() {
       const response = await fetch(`https://www.zaping.io:8448/tabela/` + _id);

       if (!response.ok) {
         toast.error('ERRO!');
         return;
       }

       let record = await response.json();
       if (!record) {
         toast.error('Tabela não Encontrada!');
         navigate("/tabela-lista");
         return;
       }
       setForm(record[0]);

     }

     fetchData();

     return;
   }, [params.id, navigate]);

   function updateForm(value) {
     if(value.preco){
       const parse = (input, locale = "pt-BR", currency = "BRL") => {
         let fmt = String(input)
           .replace(/(?<=\d)[.,](?!\d+$)/g, "")
           .replace(",", ".");
         const pts = fmt.split(".");
         if (pts.length > 1) {
           if (+pts[0] === 0) fmt = pts.join(".");
           else if (pts[1].length === 3) fmt = pts.join("");
         }
         _number = Number(fmt);
         const isValid = isFinite(_number);
         const string = _number.toFixed(2);
         const intlNFOpts = new Intl.NumberFormat(locale, {
           style: "currency",
           currency: currency,
         }).resolvedOptions();
         const output = _number.toLocaleString(locale, {
           ...intlNFOpts,
           style: "decimal",
         });

         document.getElementById("preco").value = input;
         return {
           input,
           isValid,
           string,
           _number,
           currency,
           output,
         };
       };

       console.log(parse(value.preco));
     }

     return setForm((prev) => {
       return { ...prev, ...value };
     });
   }

   async function onClickSubmit() {

     const alteradaTabela = {
       descricao: form.descricao,
       preco: _number
     }

       // This will send a post request to update the data in the database.
     await fetch(`https://www.zaping.io:8448/tabela-altera/` + _id, {
       method: "POST",
       body: JSON.stringify(alteradaTabela),
       headers: {
         'Content-Type': 'application/json'
       },
     });
     toast.success('Preço Alterado com Sucesso!');

     navigate("/tabela-lista");
   }

   // This following section will display the form that takes input from the user to update the data.
   return (

      <div className="usuario-inclui">
        <Sidebar />

        <div className="iConteudo">
          <div className="iContainer">
              <br />
              <div className="row">
                <div className="col-10">
                  <label htmlFor="descricao">Descrição</label>
                  <input
                    type="text"
                    className="form-control"
                    id="descricao"
                    value={form.descricao}
                    onChange={(e) => updateForm({ descricao: e.target.value })}
                  />
                </div>
                <div className="col-2">
                  <label htmlFor="descricao">Preço</label>
                  <input
                    type="text"
                    className="form-control"
                    id="preco"
                    value={form.preco}
                    onChange={(e) => updateForm({ preco: e.target.value })}
                  />
                </div>

              </div>

               <div className="form-group butSalvar">
                 <br />
                 <input
                   type="submit"
                   value="Salvar"
                   className="btn btn-primary shadow1"
                   onClick={(e) => onClickSubmit()}
                 />
               </div>

          </div>
        </div>

      </div>

  );

}
