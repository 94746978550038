import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import './style/radiologia-altera.css';

import Sidebar from './sidebar';

export default function Edit() {
   let _idUsuario = getStorage("_idUsuario");

   let [form, setForm] = useState({
     idUsuarioDentista: "",
     descricao: "",
     contato: "",
     telefone: "",
     ruaNumero: "",
     complemento: "",
     bairro: "",
     cidade: "",
     uf: "",
     cep: ""
   });

   const params = useParams();
   const navigate = useNavigate();
   let _id = params.id.toString();

   function getStorage(prm) {
     let _prm = window.localStorage.getItem(prm);
     if(_prm) {
       return _prm;
     }else{
       toast.error('Favor Logar Novamente!');
       navigate("/login");
     }
   }

   useEffect(() => {

     async function fetchData() {

       const response = await fetch(`https://www.zaping.io:8448/radiologia/` + _id);

       if (!response.ok) {
         toast.error('ERRO!');
         return;
       }

       let record = await response.json();

       if (!record) {
         toast.error('Radiologia não Encontrada!');
         navigate("/radiologia-lista");
         return;
       }

       setForm(record[0]);
     }

     fetchData();

     return;
   }, [params.id, navigate]);

   function updateForm(value) {

     return setForm((prev) => {
       return { ...prev, ...value };
     });
   }

   async function onClickSubmit() {

     const alteradaRadiologia = {
       idUsuarioDentista: _idUsuario,
       descricao: form.descricao,
       contato: form.contato,
       telefone: form.telefone,
       ruaNumero: form.ruaNumero,
       complemento: form.complemento,
       bairro: form.bairro,
       cidade: form.cidade,
       uf: form.uf,
       cep: form.cep
     }

       // This will send a post request to update the data in the database.
     await fetch(`https://www.zaping.io:8448/radiologia-altera/` + _id, {
       method: "POST",
       body: JSON.stringify(alteradaRadiologia),
       headers: {
         'Content-Type': 'application/json'
       },
     });
     toast.success('Radiologia Alterada com Sucesso!');

     navigate("/radiologia-lista");
   }

   // This following section will display the form that takes input from the user to update the data.
   return (

      <div className="usuario-inclui">
        <Sidebar />

        <div className="iConteudo">
          <div className="iContainer">
              <br />
              <div className="form-group">
                <label htmlFor="descricao">Descrição</label>
                <input
                  type="text"
                  className="form-control"
                  id="descricao"
                  value={form.descricao}
                  onChange={(e) => updateForm({ descricao: e.target.value })}
                />
              </div>

              <div className="row">
                 <div className="col-6">
                     <div className="form-group">
                       <label htmlFor="contato">Contato</label>
                       <input
                         type="text"
                         className="form-control"
                         id="contato"
                         value={form.contato}
                         onChange={(e) => updateForm({ contato: e.target.value })}
                       />
                     </div>
                 </div>

                 <div className="col-6">
                   <div className="form-group">
                     <label htmlFor="telefone">Telefone</label>
                     <input
                       type="text"
                       className="form-control"
                       id="telefone"
                       value={form.telefone}
                       onChange={(e) => updateForm({ telefone: e.target.value })}
                     />
                   </div>
                 </div>
              </div>

              <div className="row">
                 <div className="col-8">
                    <div className="form-group">
                      <label htmlFor="ruaNumero">Rua e No.</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ruaNumero"
                        value={form.ruaNumero}
                        onChange={(e) => updateForm({ ruaNumero: e.target.value })}
                      />
                    </div>
                 </div>

                 <div className="col-4">
                   <div className="form-group">
                     <label htmlFor="complemento">Compl.</label>
                     <input
                       type="text"
                       className="form-control"
                       id="complemento"
                       value={form.complemento}
                       onChange={(e) => updateForm({complemento: e.target.value })}
                     />
                   </div>
                 </div>
              </div>

              <div className="row">
                 <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="bairro">Bairro</label>
                      <input
                        type="text"
                        className="form-control"
                        id="bairro"
                        value={form.bairro}
                        onChange={(e) => updateForm({bairro: e.target.value })}
                      />
                    </div>
                 </div>

                 <div className="col-6">
                   <div className="form-group">
                     <label htmlFor="cidade">Cidade</label>
                     <input
                       type="text"
                       className="form-control"
                       id="cidade"
                       value={form.cidade}
                       onChange={(e) => updateForm({ cidade: e.target.value })}
                     />
                   </div>
                 </div>
              </div>

              <div className="row">
                 <div className="col-1">
                    <div className="form-group">
                      <label htmlFor="estado">UF</label>
                      <input
                        type="text"
                        className="form-control"
                        id="uf"
                        value={form.uf}
                        onChange={(e) => updateForm({ uf: e.target.value })}
                      />
                    </div>
                 </div>

                 <div className="col-5">
                 </div>

                 <div className="col-2">
                   <div className="form-group">
                     <label htmlFor="cep">CEP</label>
                     <input
                       type="text"
                       className="form-control"
                       id="cep"
                       value={form.cep}
                       onChange={(e) => updateForm({cep: e.target.value })}
                     />
                   </div>
                 </div>

                 <div className="col-4">
                 </div>

              </div>

               <div className="form-group butSalvar">
                 <br />
                 <input
                   type="submit"
                   value="Salvar"
                   className="btn btn-primary shadow1"
                   onClick={(e) => onClickSubmit()}
                 />
               </div>

          </div>
        </div>

      </div>

  );

}
