import React, { useState } from "react";

import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import logo from './assets/logo_cg_t.png';
import './style/login.css';

function LogIn() {
  const [email, setEmail] = useState('');
  const [emailErro, setEmailErro] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErro, setPasswordErro] = useState('');
  const navigate = useNavigate();

  window.localStorage.setItem('_idUsuario', null);
  window.localStorage.setItem('_usuario', null);
  window.localStorage.setItem('_tipoUsuario', null);

  function handleSubmit(e){
    e.preventDefault();
    if(email !== '' && password !== ''){
    	var req = new XMLHttpRequest();

    	req.responseType='json';
    	var xurl = 'https://www.zaping.io:8448/login';
    	var jUserLogin = [{
    					   login: email,
    					   senha: password
    					  }];

    	req.open('POST', xurl, true);
    	req.setRequestHeader('Content-Type', 'application/json');
    	req.onreadystatechange = function receiveResponse() {
    		if(this.readyState === 4) {
    			if (this.status === 200){
    				req.onload = function(e) {
              console.log(req.response)
    					var vSituacao = req.response; // não é responseText
    					if(vSituacao.success === true){
    						window.localStorage.setItem('_idUsuario', vSituacao._id);
                window.localStorage.setItem('_usuario', vSituacao.usuario);
                window.localStorage.setItem('_telefone', vSituacao.telefone);
                window.localStorage.setItem('_tipoUsuario', vSituacao.tipoUsuario);
                navigate("/os-lista");
    					}else{
    						if(vSituacao._id){
    							if(vSituacao.success === false){
                    toast.error('Senha Inválida!');
    								document.getElementById("senha").value = null;
                    document.getElementById("senha").focus();
    								return;
    							}
    						}else{
                  toast.error('Login Inexistente!');
                  document.getElementById("email").value = null;
                  document.getElementById("senha").value = null;
                  document.getElementById("email").focus();
    							return;
    						}
    					}
    				};
    				req.ontimeout = function(e) {
              toast.error('Erro de Conexão!');
    				}
    			}
    		}
    	};
      console.log(jUserLogin)

    	req.send(JSON.stringify(jUserLogin));

    }else{
      toast.error('Informe Login e Senha!');
    }
  }

  const fValidaEmail = (xEmail) => {
  	if(xEmail.length > 0){
  		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  		if(re.test(xEmail)===false){
        setEmailErro('Formato inválido!');
      }else{
        setEmailErro('');
  		}
  	}
    setEmail(xEmail);
  }

  const fValidaSenha = (xSenha) => {
  	if(xSenha.length > 0){
  		if(xSenha.length < 6){
        setPasswordErro('Pelo menos 6 dígitos!');
      }else{
        setPasswordErro('');
  		}
  	}
    setPassword(xSenha);
  }

  return (
    <div className="container-center">
      <div className="login shadow1">
        <div className="login-area">
          <img id="logo-cg" src={logo} alt="Cirurgia Guiada" />
        </div>

        <form onSubmit={handleSubmit}>
          <br />
          <input
                type="text"
                id="email"
                autoFocus
                placeholder="email@email.com"
                value={email}
                onChange={(e) => fValidaEmail(e.target.value)}
          />
          <span className="text-danger">{emailErro}</span>
          <input
                type="password"
                id="senha"
                placeholder="*******"
                value={password}
                onChange={(e) => fValidaSenha(e.target.value) } />
          <span className="text-danger">{passwordErro}</span>
          <br />
          <button type="submit" className="botAcessar shadow1">Acessar</button>
        </form>

        <Link to="/esqueci">Esqueci a senha.</Link>
      </div>
    </div>
  );

}

export default LogIn;
