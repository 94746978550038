import React, { useState } from "react";

import { Link } from 'react-router-dom';

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

//import icons from react icons
import { FaList, FaTooth, FaPhotoVideo } from "react-icons/fa";
import { FiLogOut, FiArrowLeft, FiArrowRight, FiGift, FiCheckSquare } from "react-icons/fi";
import { BiCog, BiMessageDots } from "react-icons/bi";
import { BsFillPersonFill, BsCurrencyDollar, BsTable, BsHandbag } from "react-icons/bs";
import { GiFirstAidKit } from "react-icons/gi";

import { IconContext } from "react-icons";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import './style/sidebar.css';
import logoCG from './assets/logoCG.png';

const Sidebar = () => {

  const isMobile = async () => {
    let responseMobile = false;

    function waitForElement(selector) {
      return new Promise((resolve) => {
        const observer = new MutationObserver((mutations) => {
          const element = document.querySelector(selector);
          if (element) {
            element.style.width = "60px";
            observer.disconnect();
            resolve(element);
          }
        });
        observer.observe(document.documentElement, { childList: true, subtree: true });
      });
    }

    let element;
    if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i)){
      element = await waitForElement("#header");
      responseMobile = true;
    }
    return responseMobile;
  };

  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(isMobile)

    //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  return (
    <>
      <div id="header">
          {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse} className="shadow1 barra">
          <IconContext.Provider value={{ size: "1.3em"}}>
            <SidebarHeader>
              <div className="logotext">
                <img id="logo-cg" alt="Cirurgia Guiada" src={logoCG} />
              </div>

              <div className="closemenu" onClick={menuIconClick}>
                  {/* changing menu collapse icon on click */}
                {menuCollapse ? (
                  <FiArrowRight/>
                ) : (
                  <FiArrowLeft/>
                )}
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="square">
                <MenuItem icon={<FaList />}>OS<Link to="/os-lista" /></MenuItem>
                <MenuItem icon={<BsFillPersonFill />}>Usuário<Link to="/usuario-lista" /></MenuItem>
                <MenuItem icon={<FaPhotoVideo />}>Radiologia<Link to="/radiologia-lista" /></MenuItem>
                <MenuItem icon={<BiCog />}>Parâmetros</MenuItem>
                  <div className="subParametros">
                      <SubMenu>
                        <MenuItem icon={<GiFirstAidKit />}>Kit Cirúrgico<Link to="/kit-lista" /></MenuItem>
                        <MenuItem icon={<FaTooth />}>Sistema/Modelo<Link to="/sistema_modelo-lista" /></MenuItem>
                        <MenuItem icon={<BsTable />}>Tabela de Preços<Link to="/tabela-lista" /></MenuItem>
                        <MenuItem icon={<BiMessageDots />}>Mensagens Padrão<Link to="/mensagem-lista" /></MenuItem>
                        <MenuItem icon={<FiGift />}>Pontos</MenuItem>
                          <SubMenu>
                            <MenuItem icon={<BsHandbag />}>Prêmios</MenuItem>
                            <MenuItem icon={<BsCurrencyDollar />}>Moeda</MenuItem>
                          </SubMenu>
                      </SubMenu>
                  </div>
                <MenuItem icon={<FiCheckSquare />}>Faturamento</MenuItem>
                  <div>
                      <SubMenu>
                        <MenuItem icon={<FaList />}>Lista<Link to="/faturamento-lista" /></MenuItem>
                      </SubMenu>
                  </div>

              </Menu>
            </SidebarContent>
            <SidebarFooter>
              <Menu iconShape="square">
                <MenuItem icon={<FiLogOut />}>Sair<Link to="/" /></MenuItem>
              </Menu>
            </SidebarFooter>
          </IconContext.Provider>

        </ProSidebar>
      </div>
    </>
  );
};

export default Sidebar;
