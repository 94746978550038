import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import { toast } from 'react-toastify';

import './style/usuario-inclui.css';

import Sidebar from './sidebar';

export default function Create() {
 const [login, setLogin] = useState('');
 const [loginErro, setLoginErro] = useState('');
 const [password, setPassword] = useState('');
 const [passwordErro, setPasswordErro] = useState('');

 const [records, setRecords] = useState([]);

 const [form, setForm] = useState({
   nome: "",
   idUsuarioCadastrador: "",
   tipoUsuario: "",
   login: "",
   documento: "",
   telefoneFixo: "",
   telefoneCelular: "",
   ruaNumero: "",
   complemento: "",
   bairro: "",
   cidade: "",
   uf: "",
   cep: "",
   emailFinanceiro: "",
   pagamentoPadrao: {forma: "", condicao: "", sNF: ""}
 });
 const navigate = useNavigate();

 function getStorage(prm) {
   let _prm = window.localStorage.getItem(prm);
   if(_prm === "null"){
//     toast.error('Favor Logar Novamente!');
     navigate("/login");
   }else{
     if(_prm) {
       return _prm;
     }else{
//       toast.error('Favor Logar Novamente!');
       navigate("/login");
     }
   }
 }

 const fValidaSenha = (xSenha) => {
   if(xSenha.length > 0){
     if(xSenha.length < 5){
       setPasswordErro('Pelo menos 5 dígitos!');
     }else{
       setPasswordErro('');
     }
   }
   setPassword(xSenha);
 }

 let _idUsuario = getStorage("_idUsuario");
 let _tipoUsuarioAlterado = "";

 useEffect(() => {
   const selectTipoUsuario = document.getElementById('tipoUsuario');
   let optTipoUsuario = document.createElement('option');
   optTipoUsuario.value = 0;
   optTipoUsuario.textContent = '';
   selectTipoUsuario.appendChild(optTipoUsuario);
   let t = 1;
   let aTipoUsuario = ["Dentista", "Planejador", "Validador", "Master", "Admin", "Financeiro"];
   for (t = 1; t < aTipoUsuario.length+1; t++) {
       optTipoUsuario = document.createElement('option');
       optTipoUsuario.value = t;
       optTipoUsuario.textContent = aTipoUsuario[t-1];
       selectTipoUsuario.appendChild(optTipoUsuario);
   }

   const selectForma = document.getElementById('formaPagamento');
   let optForma = document.createElement('option');
   optForma.value = 0;
   optForma.textContent = '';
   selectForma.appendChild(optForma);
   let f = 1;
   let aForma = ["PIX", "Transferência", "Boleto", "Crédito"];
   for (f = 1; f < aForma.length+1; f++) {
       optForma = document.createElement('option');
       optForma.value = f;
       optForma.textContent = aForma[f-1];
       selectForma.appendChild(optForma);
   }

   const selectCondicao = document.getElementById('condicaoPagamento');
   let optCondicao = document.createElement('option');
   optCondicao.value = 0;
   optCondicao.textContent = '';
   selectCondicao.appendChild(optCondicao);
   let c = 1;
   let aCondicao = [5, 20];
   for (c = 1; c < aCondicao.length+1; c++) {
       optCondicao = document.createElement('option');
       optCondicao.value = c;
       optCondicao.textContent = aCondicao[c-1];
       selectCondicao.appendChild(optCondicao);
   }

 }, [records.length]);

 const fValidaLogin = (xLogin) => {
   if(xLogin.length > 0){
     var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     if(re.test(xLogin)===false){
       setLoginErro('Formato inválido!');
     }else{
       setLoginErro('');
     }
   }
   document.getElementById('emailFinanceiro').value = xLogin;
   setLogin(xLogin);
 }

 function desenhaForm(_tipoUsuarioAlterado){
   let elemDentista = document.getElementById('area-dentista');
   if(_tipoUsuarioAlterado === '1'){
     elemDentista.style.visibility = "visible";
     elemDentista.style.display = "block";
   }else{
     elemDentista.style.visibility = "hidden";
     elemDentista.style.display = "none";
   }
 }

 // These methods will update the state properties.
 function updateForm(value) {
   console.log(value)

   if( value.tipoUsuario ){
     _tipoUsuarioAlterado = value.tipoUsuario;
     desenhaForm(_tipoUsuarioAlterado);
   }

   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 // This function will handle the submission.
 async function onSubmit(e) {
   e.preventDefault();

   var lTipoUsuario = document.getElementById("tipoUsuario");
   form.tipoUsuario = lTipoUsuario.options[lTipoUsuario.options.selectedIndex].innerText.toLowerCase();
   form.idUsuarioCadastrador = _idUsuario;
   form.login = document.getElementById("login").value;
   form.senha = document.getElementById("senha").value;
   var lForma = document.getElementById("formaPagamento");
   form.pagamentoPadrao.forma = lForma.options[lForma.options.selectedIndex].innerText;
   var lCondicao = document.getElementById("condicaoPagamento");
   form.pagamentoPadrao.condicao = lCondicao.options[lCondicao.options.selectedIndex].innerText;
   form.emailFinanceiro = document.getElementById("emailFinanceiro").value;
   form.sNF = document.getElementById("sNF").checked;
   const newPerson = { ...form };

   await fetch("https://www.zaping.io:8448/usuario-inclui", {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify(newPerson),
   })
   .then(()=>{
     toast.success('Usuário Cadastrado com Sucesso!');
   })
   .catch(error => {
     toast.error('Erro ao Gravar Usuário!');
     console.log(error);
     return;
   });
   document.getElementById('tipoUsuario').selectedIndex = 0;

   setForm({
     nome: "",
     idUsuarioCadastrador: "",
     tipoUsuario: "",
     login: "",
     documento: "",
     telefoneFixo: "",
     telefoneCelular: "",
     ruaNumero: "",
     complemento: "",
     bairro: "",
     cidade: "",
     uf: "",
     cep: "",
     emailFinanceiro: "",
     pagamentoPadrao: {forma: "", condicao: "", sNF: ""}
   });
   navigate("/usuario-lista");
 }

 // This following section will display the form that takes the input from the user.
 return (

   <div className="usuario-inclui">
     <Sidebar />

     <div className="iConteudo">
       <div className="iContainer">
         <form onSubmit={onSubmit}>
           <br />
           <div className="form-group">
             <label htmlFor="nome">Nome</label>
             <input
               type="text"
               className="form-control"
               id="nome"
               autoFocus="autoFocus"
               value={form.nome}
               onChange={(e) => updateForm({ nome: e.target.value })}
             />
           </div>
           <div className="row">
              <div className="col-6">
                 <label id="lbltipoUsuario" htmlFor="tipoUsuario" className="form-check-label">Tipo</label>
                 <select id="tipoUsuario" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                   onChange={(e) => updateForm({ tipoUsuario: e.target.value })}>
                 </select>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="documento">Documento</label>
                  <input
                    type="text"
                    className="form-control"
                    id="documento"
                    value={form.documento}
                    onChange={(e) => updateForm({ documento: e.target.value })}
                  />
                </div>
              </div>
           </div>

            <div className="row">
               <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="telefoneFixo">Telefone Fixo</label>
                    <input
                      type="text"
                      className="form-control"
                      id="telefoneFixo"
                      value={form.telefoneFixo}
                      onChange={(e) => updateForm({ telefoneFixo: e.target.value })}
                    />
                  </div>
               </div>

               <div className="col-6">
                 <div className="form-group">
                   <label htmlFor="telefoneCelular">Celular</label>
                   <input
                     type="text"
                     className="form-control"
                     id="telefoneCelular"
                     value={form.telefoneCelular}
                     onChange={(e) => updateForm({ telefoneCelular: e.target.value })}
                   />
                 </div>
               </div>
            </div>

            <div className="row">
               <div className="col-6">
                 <div className="form-group">
                   <label htmlFor="login">email/login</label>
                   <input type="text" id="login" className="form-control" placeholder="email@email.com" value={login} onChange={(e) => fValidaLogin(e.target.value)} />
                   <span className="text-danger">{loginErro}</span>
                 </div>
               </div>
               <div className="col-6">
                 <div className="form-group">
                   <label htmlFor="senha">Senha</label>
                   <input type="password" id="senha" className="form-control" placeholder="*******" value={password} onChange={(e) => fValidaSenha(e.target.value) } />
                 </div>
               </div>
            </div>

            <div className="row">
              <div className="col-6">
              </div>
              <div className="col-6">
                  <span className="text-danger">{passwordErro}</span>
              </div>
            </div>

            <div id="area-dentista">
              <div className="row">
                 <div className="col-8">
                    <div className="form-group">
                      <label htmlFor="ruaNumero">Rua e No.</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ruaNumero"
                        value={form.ruaNumero}
                        onChange={(e) => updateForm({ ruaNumero: e.target.value })}
                      />
                    </div>
                 </div>

                 <div className="col-4">
                   <div className="form-group">
                     <label htmlFor="complemento">Compl.</label>
                     <input
                       type="text"
                       className="form-control"
                       id="complemento"
                       value={form.complemento}
                       onChange={(e) => updateForm({complemento: e.target.value })}
                     />
                   </div>
                 </div>
              </div>

              <div className="row">
                 <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="bairro">Bairro</label>
                      <input
                        type="text"
                        className="form-control"
                        id="bairro"
                        value={form.bairro}
                        onChange={(e) => updateForm({bairro: e.target.value })}
                      />
                    </div>
                 </div>

                 <div className="col-6">
                   <div className="form-group">
                     <label htmlFor="cidade">Cidade</label>
                     <input
                       type="text"
                       className="form-control"
                       id="cidade"
                       value={form.cidade}
                       onChange={(e) => updateForm({ cidade: e.target.value })}
                     />
                   </div>
                 </div>
              </div>

              <div className="row">
                 <div className="col-2">
                    <div className="form-group">
                      <label htmlFor="uf">UF</label>
                      <input
                        type="text"
                        className="form-control"
                        id="uf"
                        value={form.uf}
                        onChange={(e) => updateForm({ uf: e.target.value })}
                      />
                    </div>
                 </div>
                 <div className="col-4">
                 </div>

                 <div className="col-3">
                   <div className="form-group">
                     <label htmlFor="cep">CEP</label>
                     <input
                       type="text"
                       className="form-control"
                       id="cep"
                       value={form.cep}
                       onChange={(e) => updateForm({cep: e.target.value })}
                     />
                   </div>
                 </div>
                 <div className="col-3">
                 </div>
              </div>

              <br />
              <div className="row">
                 <p>Pagamento Padrão</p>
                 <div className="col-6">
                   <label id="lblFormaPagamento" htmlFor="formaPagamento" className="form-check-label">Forma</label>
                   <select id="formaPagamento" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                     onChange={(e) => updateForm({ formaPagamento: e.target.value })}>
                   </select>
                 </div>

                 <div className="col-6">
                   <label id="lblCondicaoPagamento" htmlFor="condicaoPagamento" className="form-check-label">Condição</label>
                   <select id="condicaoPagamento" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                     onChange={(e) => updateForm({ condicaoPagamento: e.target.value })}>
                   </select>
                 </div>
              </div>
              <div className="row">
                 <div className="col-6">
                   <div className="form-group">
                     <label htmlFor="emailFinanceiro">email Financeiro</label>
                     <input type="text"
                            id="emailFinanceiro"
                            className="form-control"
                            onChange={(e) => updateForm({ emailFinanceiro: e.target.value })}
                     />
                   </div>
                 </div>

                 <div className="col-1">
                   <div className="form-check form-check-inline">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="sNF"
                     />
                     <label htmlFor="sNF" className="form-check-label">SN</label>
                   </div>
                 </div>

              </div>
           </div>

           <div className="form-group butSalvar">
             <br />
             <input
               type="submit"
               value="Salvar"
               className="btn btn-primary shadow1"
             />
           </div>

         </form>
       </div>
     </div>

   </div>

 );
}
