import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import { FaInstagram } from 'react-icons/fa';

import './style/contactStyle.css';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-gray-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

let contactStyle = {fontFamily: 'Mark Pro'};
let instaStyle = {fontSize: '40px'}
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`

export default ({
  heading = "ENDEREÇO",
  heading2 = "CONTATOS",
  subheading = "Telefone: +55 41 3598-1880",
  subheading1 = "WhatsApp: +55 41 98832-1888",
  subheading2 = "exames@cirurgiaguiada.net",
  subheading3 = "Rua Padre Anchieta, 2194 - CJ. 803",
  subheading4 = "Edifício Mirábili",
  subheading5 = "Bigorrilho - Curitiba - PR",
  description = "",
  submitButtonText = "",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container style={contactStyle}>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            {subheading3 && <Subheading>{subheading3}</Subheading>}
            {subheading4 && <Subheading>{subheading4}</Subheading>}
            {subheading5 && <Subheading>{subheading5}</Subheading>}
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading2}</Heading>
            {subheading && <Subheading>{subheading}</Subheading>}
            {subheading1 && <Subheading>{subheading1}</Subheading>}
            {subheading2 && <Subheading>{subheading2}</Subheading>}
            <SocialLinksContainer>
              <a href="https://www.instagram.com/cirurgiaguiada_net">
                <FaInstagram style={instaStyle}/>
              </a>
              
            </SocialLinksContainer>
          </TextContent>
        </TextColumn>   
      </TwoColumn>
      <CopyrightText>
        &copy; 2023 (520), cirurgiaguida.net. Todos Direitos Reservados
      </CopyrightText>
    </Container>
  );
};
