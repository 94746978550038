import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { toast } from 'react-toastify';

import './style/kit-inclui.css';

import Sidebar from './sidebar';

export default function Create() {
 const [records, setRecords] = useState([]);

 const [form, setForm] = useState({
   sistema: "",
   modelo: []
 });
 const navigate = useNavigate();

 useEffect(() => {

 }, [records.length]);

 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 // This function will handle the submission.
 async function onSubmit(e) {
   e.preventDefault();

   form.modelo = form.modelo.split(",");
   let novoSistemaModelo = { ...form };

   await fetch("https://www.zaping.io:8448/sistema_modelo-inclui", {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify(novoSistemaModelo),
   })
   .then(()=>{
     toast.success('Sistema/Modelo Cadastrado com Sucesso!');
   })
   .catch(error => {
     toast.error('Erro ao Gravar Sistema/Modelo!');
     console.log(error);
     return;
   });

   setForm({
     sistema: "",
     modelo: []
   });
   navigate("/sistema_modelo-lista");
 }

 // This following section will display the form that takes the input from the user.
 return (

   <div className="kit-inclui">
     <Sidebar />

     <div className="iConteudo">
       <div className="iContainer">
         <form onSubmit={onSubmit}>
           <br />
           <div className="form-group">
             <label htmlFor="descricao">Sistema</label>
             <input
               type="text"
               className="form-control"
               autoFocus="autoFocus"
               id="sistema"
               value={form.sistema}
               onChange={(e) => updateForm({ sistema: e.target.value })}
             />
           </div>
           <div className="form-group">
             <label htmlFor="descricao">Modelo (separado por vírgula)</label>
             <input
               type="text"
               className="form-control"
               id="modelo"
               value={form.modelo}
               onChange={(e) => updateForm({ modelo: e.target.value })}
             />
           </div>

           <div className="form-group butSalvar">
             <br />
             <input
               type="submit"
               value="Salvar"
               className="btn btn-primary shadow1"
             />
           </div>

         </form>
       </div>
     </div>

   </div>

 );
}
