import React from "react";

import ReactDOM from 'react-dom';

import Pdf from "react-to-pdf";
import QRCode from "react-qr-code";

import logoCG from './assets/logo_cg_t.png';
import './style/requisicao.css';

const ref = React.createRef();

function Requisicao() {

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm) {
      return _prm;
    }else{
      return "";
    }
  }

  let _idOS = getStorage("_idOS");
  let _nomePaciente = getStorage("_nomePaciente");
  let _dentista = getStorage("_dentista");
  let _dentistaTelefone = getStorage("_dentistaTelefone");
  let _nomeServico = getStorage("_nomeServico");
  let _arquivo = getStorage("_arquivo");

  let _linkQrCode = getStorage("_linkQrCode");

  let _radiologiaDescricao = "";
  let _radiologiaTelefone = "";
  let _radiologiaRuaNumero = "";
  let _radiologiaComplemento = "";
  let _radiologiaBairro = "";

  _radiologiaDescricao = getStorage("_radiologiaDescricao");
  _radiologiaTelefone = getStorage("_radiologiaTelefone");
  _radiologiaRuaNumero = getStorage("_radiologiaRuaNumero");
  _radiologiaComplemento = getStorage("_radiologiaComplemento");
  _radiologiaBairro = getStorage("_radiologiaBairro");

  let txt11 = "Honrosamente encaminhamos o(a) paciente ";
  let txt12 = " em nome do(a) Dr(a) ";
  let txt13 = ", telefone ";
  let txt14 = ", para a realização dos exames de imagem para ";
  let txt2 = "Por favor, escaneie o QR Code abaixo para obter as instruções para a realização de exames otimizados, evitando repetições."
  let _data = new Date().toLocaleDateString();
  let nomeArquivo = "requisicao_exames_" + _idOS;
  let txt15 = "Em caso de dúvidas, nos envie um whatsapp para (41) 98850 1888.";
  let txt16 = "Após realizar os exames, enviar via https://www.wetransfer.com para exames@cirurgiaguiada.net";
  let txt17;
  let txt18 = '';
  let txt19 = '';
  let txt20 = '';
  let txt21 = '';
  let txt22 = '';

  /*
  - Cirurgia guiada unitários:
  Tomografia em formato DICOM da arcada 
  (   ) superior  (   ) inferior
  Escaneamentos
  
  - Cirurgia guiada totais:
  Tomografia em formato de DICOM da arcada 
  (   ) superior  (   ) inferior
  utilizando a prótese com marcadores
  Tomografia em formato DICOM somente da prótese com marcadores

  - Prototipagem óssea:
  DICOM de face e crânio para prototipagem óssea

  - Cirurgia Ortognatica:
  DICOM de face e crânio
  Escaneamentos 
  Fotografias (frontal repouso, frontal sorrindo, frontal com afastadores e perfil de face)
  Fotografias 3D (Não obrigatório)
  Radiografias panorâmica e teleperfil
  
  - Enceramento digital:
  Escaneamentos;
  Fotografias de face (frontal repouso, frontal sorrindo, frontal com afastadores e perfil de face)
  Fotografia 3D (não obrigatório)
  
  - Guia periodontal;
  Tomografia em formato de DICOM da arcada 
  (   ) superior  (   ) inferior
  Escaneamentos
  Fotografias (frontal repouso, frontal sorrindo, frontal com afastadores e perfil de face)
  Fotografia 3D (não obrigatório)
  
  - Guia endodôntico
  Tomografia em formato de DICOM da arcada 
  (   ) superior  (   ) inferior
  Escaneamentos
  
  - Placa Miorrelaxante
  Escaneamentos
  (     ) com jig
  (    ) sem jig
  
  - Modelo Impresso
  Escaneamentos

  - Outros
      ESPAÇO EM BRANCO PARA PREENCHIMENTO MANUAL
  */

  if(_nomeServico === "Cirurgia Guiada"){
    if(_arquivo === "total"){
      _arquivo = "totais";
      txt18 = ". Tomografia em formato DICOM da arcada utilizando prótese com marcadores"
      txt20 = "Utilizando a prótese com marcadores"
      txt21 = ". Tomografia em formato DICOM somente da prótese"

    }else{
      if(_arquivo === "parcial"){
        _arquivo = "unitários";
        txt18 = ". Tomografia em formato DICOM"
        txt20 = "Escaneamentos"
        txt21 = ". Tomografia em formato DICOM"
      }
    }
    txt17 = ' - Cirurgia guiada ' + _arquivo + ':' 
    txt19 = '(   ) superior  (   ) inferior'
  }
  
  if(_nomeServico === "Prototipagem Óssea"){
    txt17 = '- Prototipagem Óssea'
    txt18 = 'DICOM de face e crânio para prototipagem óssea';
  }
  if(_nomeServico === "Cirurgia Ortognática"){
    txt17 = '- Cirurgia Ortognática'
    txt18 = 'DICOM de face e crânio'
    txt19 = 'Escaneamentos'
    txt20 = 'Fotografias (frontal repouso, frontal sorrindo, frontal com afastadores e perfil de face)'
    txt21 = 'Fotografias 3D (Não obrigatório)'
    txt22 = 'Radiografias panorâmica e teleperfil';
  }
  if(_nomeServico === "Enceramento Digital"){
    txt17 = '- Enceramento Digital'
    txt18 = 'Escaneamentos'
    txt19 = 'Fotografias de face (frontal repouso, frontal sorrindo, frontal com afastadores e perfil de face)'
    txt20 = 'Fotografia 3D (não obrigatório)';
  }
  if(_nomeServico === "Guia Periodontal"){
    txt17 = '- Guia Periodontal'
    txt18 = 'Tomografia em formato de DICOM da arcada'
    txt19 = '(   ) superior  (   ) inferior'
    txt20 = 'Escaneamentos'
    txt21 = 'Fotografias (frontal repouso, frontal sorrindo, frontal com afastadores e perfil de face)'
    txt22 = 'Fotografia 3D (não obrigatório)'
  }
  if(_nomeServico === "Guia Endodôntico"){
    txt17 = '- Guia Endodôntico'
    txt18 = 'Tomografia em formato de DICOM da arcada'
    txt19 = '(   ) superior  (   ) inferior'
    txt20 = 'Escaneamentos'
  }
  if(_nomeServico === "Placa Miorrelaxante"){
    txt17 = '- Placa Miorrelaxante'
    txt18 = 'Escaneamentos'
    txt19 = '(    ) com jig'
    txt20 = '(    ) sem jig';
  }
  if(_nomeServico === "Modelo Impresso"){
    txt17 = '- Modelo Impresso'
    txt18 = 'Escaneamentos';
  }
  if(_nomeServico === "Outros Serviços"){
    txt17 = '- Outros';
  }

  return (
    <div className="App">
      <Pdf targetRef={ref} filename={nomeArquivo}>
        {({ toPdf }) => <button onClick={toPdf}>Gerar PDF</button>}
      </Pdf>
      <br />
      <br />
      <div style={{width: 750}} ref={ref} className="area">
        <img id="logo-cg" className="logoCG" alt="Cirurgia Guiada" src={logoCG} />
        <br />
        <h4>OS: {_idOS}</h4>
        <br />
        <br />
        <h4>Prezado Radiologista:</h4>
        <h4>{_radiologiaDescricao}</h4>
        <h5>{_radiologiaTelefone}</h5>
        <h5>{_radiologiaRuaNumero}</h5>
        <h5>{_radiologiaComplemento}</h5>
        <h5>{_radiologiaBairro}</h5>
        <br />
        <br />
        <h5>{txt11}<b>{[_nomePaciente]}</b>{txt12}<b>{[_dentista]}</b>{txt13}<b>{[_dentistaTelefone]}</b>{txt14}<b>{[_nomeServico]}</b>.</h5>
        <h5>{txt2}</h5>
        <br />
        <h5>{txt17}</h5>
        <h5>{txt18}</h5>
        <h5>{txt19}</h5>
        <h5>{txt20}</h5>
        <h5>{txt21}</h5>
        <h5>{txt22}</h5>
        <br />
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "100%" }}>
          <QRCode
          size={512}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={_linkQrCode}
          viewBox={`0 0 512 512`}
          />
        </div>        
        <br />
        <br />
        <br />
        <br />
        <h5>{txt15}</h5>
        <h5>{txt16}</h5>
        <br />
        <br />
        <div className="assinatura">
          <h5>{_data}</h5>
          <br />
          <br />
          <br />
          <h4>{_dentista}</h4>
          <h5>{_dentistaTelefone}</h5>
        </div>

      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Requisicao />, rootElement);

// window.localStorage.setItem('_nomePaciente', "");
// window.localStorage.setItem('_dentista', "");
// window.localStorage.setItem('_dentistaTelefone', "");
// window.localStorage.setItem('_servicoOpcoes', "");
// window.localStorage.setItem('_nomeServico', "");

export default Requisicao;
