import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router";

import './style/usuario-lista.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiOutlinePlus } from "react-icons/ai";
import { IconContext } from "react-icons";

import { toast } from 'react-toastify';

import Sidebar from './sidebar';

let emFiltro = false;

async function apagaUsuario(param){
  await fetch("https://www.zaping.io:8448/usuario-apaga/" + param, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })

  .then(()=>{
    toast.success('Usuário apagado com sucesso!');
    window.location.reload();
  })
  .catch(error => {
    toast.error('Erro ao apagar usuário!');
    console.log(error);
    return;
  });
}

const Record = (props) => (
  <div className="conteudo">

    <div className="container">
      <div className="row">
        <div className="col-9">
          <Link className="action" to={`/usuario-altera/${props.oRecord._id}`}>
            <p id="nome">{props.oRecord.nome}</p>
          </Link>
        </div>
        
        <div className="col-2">
          <p id="tipoUsuario">{props.oRecord.tipoUsuario}</p>
        </div>
        
        <div className="col-1">
          <div className="form-group">
            <button 
              className="form-group botExcluir"
              onClick={() => apagaUsuario(props.oRecord._id)}>X
            </button>
          </div>          
        </div>

      </div>

    </div>
  </div>
);

export default function UsuarioList() {
 let [records, setRecords] = useState([]);
 let [recordsFull, setRecordsFull] = useState([]);
 let [chave, setChave] = useState([]);

 const navigate = useNavigate();
 let _tipoUsuario = getStorage("_tipoUsuario");
 let _idUsuario = getStorage("_idUsuario");

 function getStorage(prm) {
   let _prm = window.localStorage.getItem(prm).toLowerCase();
   if(_prm) {
     return _prm;
   }else{
     toast.error('Favor Logar Novamente!');
     navigate("/login");
   }
 }

 // This method fetches the records from the database.
 useEffect(() => {
   async function getRecords() {
     const response = await fetch(`https://www.zaping.io:8448/usuario-lista/` + _tipoUsuario + `/` + _idUsuario);

     if (!response.ok) {
       const message = `ERRO: ${response.statusText}`;
       window.alert(message);
       return;
     }

     let records = await response.json();
     setRecords(records);
     setRecordsFull(records);
     if(_tipoUsuario === "dentista"){
      let elemApaga = document.querySelectorAll(".botExcluir");
      elemApaga.forEach(element => {
        element.style.display = "none";
      });

      if(document.getElementById('chave')){
         let elemChave = document.getElementById('chave');
         elemChave.style.visibility = "hidden";
         elemChave.style.display = "none";
       }
       if(document.getElementById('chave')){
         let elemMais = document.getElementById('mais');
         elemMais.style.visibility = "hidden";
         elemMais.style.display = "none";
       }
       document.getElementById('nome').click();
     }
   }

   if(emFiltro === false){
     getRecords();
   }

 }, [records.length]);

 // This method will delete a record
 async function deleteRecord(id) {
   await fetch(`http://localhost:5000/${id}`, {
     method: "DELETE"
   });

   const newRecords = records.filter((el) => el._id !== id);
   setRecords(newRecords);
 }

 function recordList() {
   let oRecord;

   return records.map((record) => {

     let _tipoUsuario = record.tipoUsuario;
     _tipoUsuario = _tipoUsuario.substr(0, 1).toUpperCase() + _tipoUsuario.substr(1, _tipoUsuario.length-1);
     oRecord = {_id: record._id,
                nome: record.nome,
                tipoUsuario: _tipoUsuario
               }
     return (
       <Record
         record={record}
         oRecord={oRecord}
         deleteRecord={() => deleteRecord(record._id)}
         key={record._id}
       />
     );
   });
 }

 const handleSearch = (param) => {
   setChave(param);

   if(param.length > 0){
     param = param.toLowerCase();
     let result = records.filter(rec => rec.nome.toLowerCase().includes(param))
     emFiltro = true;
     setRecords(result);
   }else{
     emFiltro = false;
     setRecords(recordsFull);
   }

 }

 function FiltraUsuario() {

   return (
     <div className="conteudo">
       <div className="row">
         <div className="col-8">
           <input className="textoFiltro"
             type="text"
             id="chave"
             placeholder="Buscar..."
             autoFocus="autoFocus"
             value={chave}
             onChange={(e) => handleSearch(e.target.value)} />
         </div>

         <div className="col-2">
         </div>

         <div className="col-2 butIncluir">
           <IconContext.Provider value={{ size: "1.5em"}}>
             <Link id="mais" to="/usuario-inclui"><AiOutlinePlus /></Link>
           </IconContext.Provider>
         </div>
       </div>
     </div>
   )
 }

 // This following section will display the table with the records of individuals.
 return (

   <div>

    <Sidebar />

    <FiltraUsuario />

    {recordList()}
   </div>

 );
}
