import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router";

import { toast } from 'react-toastify';

import './style/os-lista.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiOutlinePlus } from "react-icons/ai";
import { IconContext } from "react-icons";

import Sidebar from './sidebar';

let emFiltro = false;
let recordsAll = [];
let setRecords = "";
let setTotalLista = "";
let totalLista = 0
let records = "";

function filtraStatus(param){
  const element = document.getElementById(param);
  const cssObj = window.getComputedStyle(element, null);

  let bgColor = cssObj.getPropertyValue("border-color");
  let aBgColor = bgColor.split(" rgb");

  let statusResult = [];
  if(element.innerText === "Exames"){
    statusResult = recordsAll.filter(rec => rec.envioParaRadiologia)
  }else{
    if(element.innerText === "Planejamento"){
      if(aBgColor[0] === "rgb(3, 79, 132)"){
        statusResult = recordsAll.filter(rec => rec.envioParaPlanejador);
        statusResult = statusResult.filter(rec => !rec.recebimentoPlanejador);
      }else{
        if(aBgColor[0] === "rgb(0, 0, 255)"){
          statusResult = recordsAll.filter(rec => rec.recebimentoPlanejador);
        }else{
          if(aBgColor[0] === "rgb(255, 204, 92)"){
            statusResult = recordsAll.filter(rec => rec.status === 'atencao');
          }else{
            if(aBgColor[0] === "rgb(200, 51, 73)"){
              statusResult = recordsAll.filter(rec => rec.status === 'contraindicacao');
            }else{
              statusResult = recordsAll.filter(rec => rec.status === 'ok');
            }
          }
        }
      }
    }else{
      if(element.innerText === "Aprovação"){
        if(aBgColor[0] === "rgb(255, 165, 0)"){
          statusResult = recordsAll.filter(rec => rec.concluidoPeloPlanejador);
          statusResult = statusResult.filter(rec => !rec.aprovadoPeloDentista);
          statusResult = statusResult.filter(rec => !rec.revisaoPeloDentista);
        }else{
          if(aBgColor[0] === "rgb(0, 128, 0)"){
            statusResult = recordsAll.filter(rec => rec.aprovadoPeloDentista);
          }else{
            if(aBgColor[0] === "rgb(255, 0, 0)"){
              statusResult = recordsAll.filter(rec => rec.revisaoPeloDentista);
            }
          }
        }
      }else{
        if(element.innerText === "Produção"){
          if(aBgColor[0] === "rgb(128, 128, 128)"){
            statusResult = recordsAll.filter(rec => rec.aprovadoPeloDentista);
            statusResult = statusResult.filter(rec => !rec.recebidoPelaProducao);
          }else{
            statusResult = recordsAll.filter(rec => rec.recebidoPelaProducao);
          }
        }else{
          if(element.innerText === "Envio"){
            if(aBgColor[0] === "rgb(0, 0, 0)"){
              statusResult = recordsAll.filter(rec => rec.despachado);
            }
          }else{
            if(element.innerText === "Faturamento"){
              statusResult = recordsAll.filter(rec => rec.faturamento)
              if(aBgColor[0] === "rgb(198, 192, 19)"){
                statusResult = statusResult.filter(rec => !rec.faturamento.fatura);
              }else{
                if(aBgColor[0] === "rgb(255, 165, 0)"){
                  statusResult = statusResult.filter(rec => rec.faturamento.fatura);
                  statusResult = statusResult.filter(rec => rec.faturamento.fatura.status === "aberto");
                  statusResult = statusResult.filter(rec => new Date(rec.faturamento.fatura.dataVencimento) > new Date());
                }else{
                  if(aBgColor[0] === "rgb(255, 0, 0)"){
                    statusResult = statusResult.filter(rec => rec.faturamento.fatura);
                    statusResult = statusResult.filter(rec => rec.faturamento.fatura.status === "aberto");
                    statusResult = statusResult.filter(rec => new Date(rec.faturamento.fatura.dataVencimento) < new Date());
                  }else{
                    if(aBgColor[0] === "rgb(0, 128, 0)"){
                      statusResult = statusResult.filter(rec => rec.faturamento.fatura);
                      statusResult = statusResult.filter(rec => rec.faturamento.fatura.status === "pago");
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  emFiltro = true;
  setRecords(statusResult);
  setTotalLista(totalLista);
  document.getElementById("totalLista").innerText = totalLista.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

async function apagaOS(param){
  await fetch("https://www.zaping.io:8448/os-apaga/" + param, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })

  .then(()=>{
    toast.success('OS apagada com sucesso!');
    window.location.reload();
  })
  .catch(error => {
    toast.error('Erro ao apagar OS!');
    console.log(error);
    return;
  });
}

const Record = (props) => (
  <div className="conteudo">

    <div className="container">
      <div className="row">
        <div className="col-11">
          <Link className="action" to={`/os-altera/${props.oRecord.idOS}`}>
            <p id="desc">{props.oRecord.desc}</p>
          </Link>
        </div>

        <div className="col-1">
          <div className="form-group">
            <button 
              className="form-group botExclui"
              onClick={() => apagaOS(props.oRecord.idOS)}>x
            </button>
          </div>          
        </div>

      </div>

      <div className="row">
        <div className="col-12">
     
          <div>
            {props.oRecord.dentista ? (
              <p id="dentista1" onClick={(a) => filtraStatus(a.target.id)}>{props.oRecord.dentista}</p>
            ) :
            <p></p>
            }
            {props.oRecord.dentista ? (
              <p id="dentista2"></p>
            ) :
            <p></p>
            }
          </div>

          <div>
            {props.oRecord.radiologia1 ? (
              <p id="radiologia1" onClick={(a) => filtraStatus(a.target.id)}>{props.oRecord.radiologia1}</p>
            ) :
            <p></p>
            }
            {props.oRecord.radiologia2 ? (
              <p id="radiologia2">{props.oRecord.radiologia2}</p>
            ) :
            <p></p>
            }
          </div>

          <div>
            {props.oRecord.planejador1 ? (
              <p id={props.oRecord._id + 1} onClick={(a) => filtraStatus(a.target.id)} className="planejador1">{props.oRecord.planejador1}</p>
            ) :
            <p></p>
            }
            {props.oRecord.planejador2 ? (
              <p id={props.oRecord._id + 2} className="planejador2">{props.oRecord.planejador2}</p>
            ) :
            <p></p>
            }
          </div>

          <div>
            {props.oRecord.validador1 ? (
              <p id={'v' + props.oRecord._id + 1} onClick={(a) => filtraStatus(a.target.id)} className="validador1">{props.oRecord.validador1}</p>
            ) :
            <p></p>
            }
            {props.oRecord.validador2 ? (
              <p id={'v' + props.oRecord._id + 2} className="validador2">{props.oRecord.validador2}</p>
            ) :
            <p></p>
            }
          </div>

          <div>
            {props.oRecord.producao1 ? (
              <p id={'p' + props.oRecord._id + 1} onClick={(a) => filtraStatus(a.target.id)} className="producao1">{props.oRecord.producao1}</p>
            ) :
            <p></p>
            }
            {props.oRecord.producao2 ? (
              <p id={'p' + props.oRecord._id + 2} className="producao2">{props.oRecord.producao2}</p>
            ) :
            <p></p>
            }
          </div>

          <div>
            {props.oRecord.envio1 ? (
              <p id={'e' + props.oRecord._id + 1} onClick={(a) => filtraStatus(a.target.id)} className="envio1">{props.oRecord.envio1}</p>
            ) :
            <p></p>
            }
            {props.oRecord.envio2 ? (
              <p id={'e' + props.oRecord._id + 2} className="envio2">{props.oRecord.envio2}</p>
            ) :
            <p></p>
            }
          </div>

          <div>
            {props.oRecord.faturamento1 ? (
              <p id={'f' + props.oRecord._id + 1} onClick={(a) => filtraStatus(a.target.id)} className="faturamento1">{props.oRecord.faturamento1}</p>
            ) :
            <p></p>
            }
            {props.oRecord.faturamento2 ? (
              <p id={'f' + props.oRecord._id + 2} className="faturamento2">{props.oRecord.faturamento2}</p>
            ) :
            <p></p>
            }
          </div>

        </div>
      </div>
      <hr />
    </div>
  </div>
);

export default function OSList() {

  [records, setRecords] = useState([]);
  let [recordsFull, setRecordsFull] = useState([]);
  let [chave, setChave] = useState([]);
  [totalLista, setTotalLista] = useState([]);

  const navigate = useNavigate();

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm === "null"){
      navigate("/login");
    }else{
      if(_prm) {
        return _prm;
      }else{
        navigate("/login");
      }
    }
  }

  let _tipoUsuario = getStorage("_tipoUsuario");
  let _idUsuario = getStorage("_idUsuario");
  // This method fetches the records from the database.
  
  useEffect(() => {

    async function getRecords() {
      const response = await fetch(`https://www.zaping.io:8448/os/` + _tipoUsuario + `/` + _idUsuario + `/null`);
      if (!response.ok) {
        const message = `ERRO: ${response.statusText}`;
        window.alert(message);
        return;
      }
      let records = await response.json();
  console.log(records)
      records = records[0].resGetOS;

      const responseUsuario = await fetch("https://www.zaping.io:8448/usuario-lista/" + _tipoUsuario + "/" + _idUsuario);
      if (!responseUsuario.ok) {
        const message = `ERRO: ${responseUsuario.statusText}`;
        window.alert(message);
        return;
      }
      const recordsUsuario = await responseUsuario.json();

      let aUsuario = [];
      for (let u = 0; u < recordsUsuario.length; u++) {
          aUsuario[recordsUsuario[u]._id] = recordsUsuario[u].nome;
      }

      let _dataVencimento;
      for (let r = 0; r < records.length; r++) {
        _dataVencimento = "";
        if(records[r].faturamento){
          if(records[r].faturamento.fatura){
            _dataVencimento = " Vencimento: " + new Date(records[r].faturamento.fatura.dataVencimento).toLocaleDateString('pt-BR');
          }
        }
        records[r].desc = "OS: " + records[r].idOS + " De: " + new Date(records[r].dataOS).toLocaleDateString('pt-BR') + " " + records[r].nomePaciente + " / Dr(a) " + aUsuario[records[r].idUsuarioDentista] + " " + _dataVencimento;
      }

      recordsAll = records;
      setRecords(records);
      setRecordsFull(records);
      setTotalLista(totalLista);

      if(_tipoUsuario === "dentista"){
        let elemApaga = document.querySelectorAll(".botExcluir");
        elemApaga.forEach(element => {
          element.style.display = "none";
        });
      }else{
      }

      document.getElementById("totalLista").innerText = totalLista.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    if(emFiltro === false){
      getRecords();
    }
  }, [records.length]);

  function recordList() {
    let oRecord, radiologia1, radiologia2, planejador1, planejador2, validador1, validador2, producao1, producao2, envio1, envio2, faturamento1, faturamento2;
    totalLista  = 0;

    return records.map((record) => {
      if(record.faturamento){
        if(record.faturamento.fatura){
          totalLista = totalLista + record.faturamento.fatura.valor;
        }else{
          totalLista = totalLista + 0;
        }
        document.getElementById("totalLista").innerText = totalLista.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }

      if(record.envioParaRadiologia){
        radiologia1 = 'Exames';
        radiologia2 = ' ';
      }else{
        radiologia1 = null;
        radiologia2 = null;
      }

      if(record.envioParaPlanejador){
        planejador1 = 'Planejamento';
        planejador2 = ' ';
        let planejadorCor = '#034f84';
        if(record.recebimentoPlanejador){
          planejadorCor = 'blue';
          if(record.status === 'atencao'){
            planejadorCor = '#ffcc5c';
          }else{
            if(record.status === 'contraindicacao'){
              planejadorCor = '#c83349';
            }else{
              if(record.status === 'ok'){
                planejadorCor = '#25d366';
              }
            }
          }
        }
        let elePlanejador1 = document.getElementById(record._id + 1);
        let elePlanejador2 = document.getElementById(record._id + 2);
        if(elePlanejador1){
          elePlanejador1.style.borderTop = "12px solid " + planejadorCor;
          elePlanejador1.style.borderBottom = "12px solid " + planejadorCor;
          elePlanejador1.style.borderLeft = "12px solid " + planejadorCor;
        }
        if(elePlanejador2){
          elePlanejador2.style.borderTop = "12px solid transparent";
          elePlanejador2.style.borderBottom = "12px solid transparent";
          elePlanejador2.style.borderLeft = "12px solid " + planejadorCor;
        }
      }else{
        planejador1 = null;
        planejador2 = null;
      }

      if(record.concluidoPeloPlanejador){
        validador1 = 'Aprovação';
        validador2 = ' ';
        let validadorCor = 'orange';
        if(record.aprovadoPeloDentista){
          if(record.aprovadoPeloDentista === true){
            validadorCor = 'green';
          }
        }else{
          if(record.revisaoPeloDentista === true){
            validadorCor = 'red';
          }
        }
        let eleValidador1 = document.getElementById('v' + record._id + 1);
        let eleValidador2 = document.getElementById('v' + record._id + 2);
        if(eleValidador1){
          eleValidador1.style.borderTop = "12px solid " + validadorCor;
          eleValidador1.style.borderBottom = "12px solid " + validadorCor;
          eleValidador1.style.borderLeft = "12px solid " + validadorCor;
        }
        if(eleValidador2){
          eleValidador2.style.borderTop = "12px solid transparent";
          eleValidador2.style.borderBottom = "12px solid transparent";
          eleValidador2.style.borderLeft = "12px solid " + validadorCor;
        }
      }else{
        validador1 = null;
        validador2 = null;
      }

      if(record.aprovadoPeloDentista){
        producao1 = 'Produção';
        producao2 = ' ';
        let producaoCor = 'gray';
        if(record.recebidoPelaProducao){
          if(record.recebidoPelaProducao === true){
            producaoCor = 'blue';
          }
        }
        let eleProducao1 = document.getElementById('p' + record._id + 1);
        let eleProducao2 = document.getElementById('p' + record._id + 2);
        if(eleProducao1){
          eleProducao1.style.borderTop = "12px solid " + producaoCor;
          eleProducao1.style.borderBottom = "12px solid " + producaoCor;
          eleProducao1.style.borderLeft = "12px solid " + producaoCor;
        }
        if(eleProducao2){
          eleProducao2.style.borderTop = "12px solid transparent";
          eleProducao2.style.borderBottom = "12px solid transparent";
          eleProducao2.style.borderLeft = "12px solid " + producaoCor;
        }
      }else{
        producao1 = null;
        producao2 = null;
      }

      if(record.despachado){
        envio1 = 'Envio';
        envio2 = ' ';
        let envioCor = 'black';
        if(record.despachado){
          if(record.despachado === true){
            envioCor = 'black';
          }
        }
        let eleEnvio1 = document.getElementById('e' + record._id + 1);
        let eleEnvio2 = document.getElementById('e' + record._id + 2);
        if(eleEnvio1){
          eleEnvio1.style.borderTop = "12px solid " + envioCor;
          eleEnvio1.style.borderBottom = "12px solid " + envioCor;
          eleEnvio1.style.borderLeft = "12px solid " + envioCor;
        }
        if(eleEnvio2){
          eleEnvio2.style.borderTop = "12px solid transparent";
          eleEnvio2.style.borderBottom = "12px solid transparent";
          eleEnvio2.style.borderLeft = "12px solid " + envioCor;
        }
      }else{
        envio1 = null;
        envio2 = null;
      }

      if(record.faturamento){
        faturamento1 = 'Faturamento';
        faturamento2 = ' ';
        let faturamentoCor = '#C6C013';
        if(record.faturamento.fatura){
          if(record.faturamento.fatura.status === "aberto"){
            if(new Date(record.faturamento.fatura.dataVencimento) > new Date()){
              faturamentoCor = 'orange';
            }else{
              faturamentoCor = 'red';
            }
          }else{
            if(record.faturamento.fatura.status === "pago"){
              faturamentoCor = 'green';
            }
          }
        }
        let eleFaturamento1 = document.getElementById('f' + record._id + 1);
        let eleFaturamento2 = document.getElementById('f' + record._id + 2);
        if(eleFaturamento1){
          eleFaturamento1.style.borderTop = "12px solid " + faturamentoCor;
          eleFaturamento1.style.borderBottom = "12px solid " + faturamentoCor;
          eleFaturamento1.style.borderLeft = "12px solid " + faturamentoCor;
        }
        if(eleFaturamento2){
          eleFaturamento2.style.borderTop = "12px solid transparent";
          eleFaturamento2.style.borderBottom = "12px solid transparent";
          eleFaturamento2.style.borderLeft = "12px solid " + faturamentoCor;
        }
      }else{
        faturamento1 = null;
        faturamento2 = null;
      }

      oRecord = {_id: record._id,
                  desc: record.desc,
                  data: new Date(record.dataOS).toLocaleDateString('pt-BR'),
                  idOS: record.idOS,
                  dentista: 'Cadastro',
                  radiologia1: radiologia1,
                  radiologia2: radiologia2,
                  planejador1: planejador1,
                  planejador2: planejador2,
                  validador1: validador1,
                  validador2: validador2,
                  producao1: producao1,
                  producao2: producao2,
                  envio1: envio1,
                  envio2: envio2,
                  faturamento1: faturamento1,
                  faturamento2: faturamento2
                }

      return (
        <Record
          record={record}
          oRecord={oRecord}
          key={record._id}
        />
      );
    });
  }

  const handleSearch = (param) => {
    setChave(param);

    if(param.length > 0){
      param = param.toLowerCase();
      let result = records.filter(rec => rec.desc.toLowerCase().includes(param))
      emFiltro = true;
      setRecords(result);
      setTotalLista(totalLista);
      document.getElementById("totalLista").innerText = totalLista.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }else{
      emFiltro = false;
      setRecords(recordsFull);
      setTotalLista(totalLista);
      document.getElementById("totalLista").innerText = totalLista.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
  }

  function FiltraOS() {
    totalLista = 0;
    return (
      <div className="conteudo">
        <div className="row">
          <div className="col-8">
            <input className="textoFiltro"
              type="text"
              id="chave"
              placeholder="Buscar..."
              autoFocus="autoFocus"
              value={chave}
              onChange={(e) => handleSearch(e.target.value)} />
          </div>

          <div className="col-1 botIncluir">
            <IconContext.Provider value={{ size: "1.5em"}}>
              <Link to="/os-inclui"><AiOutlinePlus /></Link>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    )
  }

 // This following section will display the table with the records of individuals.
  return (
    <div>
      <Sidebar />
      <FiltraOS />
      {recordList()}

      <div className="bottom-fixed">
        <p id="totalLista"></p>
      </div>
    </div>
  );
}
