import React from "react";

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import logo from './assets/logoCG.png';
import './style/cadastro.css';

function LogIn() {
  const [form, setForm] = useState({
    nome: "",
    tipoUsuario: "dentista",
    documento: "",
    login: "",
    senha: "",
    telefoneFixo: "",
    telefoneCelular: "",
    ruaNumero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
    cep: ""
  });

  const [login, setLogin] = useState('');
  const [loginErro, setLoginErro] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaErro, setSenhaErro] = useState('');
  const [senha2, setSenha2] = useState('');
  const [senha2Erro, setSenha2Erro] = useState('');

  const navigate = useNavigate();

  window.localStorage.setItem('_idUsuario', null);
  window.localStorage.setItem('_usuario', null);
  window.localStorage.setItem('_tipoUsuario', null);

  function updateForm(value) {
    console.log(value)

    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  async function handleSubmit(e){
    e.preventDefault();

    form.idUsuarioCadastrador = "home";
    form.login = document.getElementById("login").value;
    form.senha = document.getElementById("senha").value;

    const newPerson = { ...form };

    await fetch("https://www.zaping.io:8448/usuario-inclui", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPerson),
    })
    .then(()=>{
      toast.success('Usuário Cadastrado com Sucesso!');
    })
    .catch(error => {
      toast.error('Erro ao Gravar Usuário!');
      console.log(error);
      return;
    });

    setForm({
      nome: "",
      tipoUsuario: "dentista",
      documento: "",
      login: "",
      senha: "",
      telefoneFixo: "",
      telefoneCelular: "",
      ruaNumero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: ""
    });
    navigate("/login");
  }

  const fValidaLogin = (xLogin) => {
  	if(xLogin.length > 0){
  		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  		if(re.test(xLogin)===false){
        setLoginErro('Formato inválido!');
      }else{
        setLoginErro('');
  		}
  	}
    setLogin(xLogin);
  }

  const fValidaSenha = (xSenha) => {
  	if(xSenha.length > 0){
  		if(xSenha.length < 5){
        setSenhaErro('Pelo menos 5 dígitos!');
      }else{
        setSenhaErro('');
  		}
  	}
    setSenha(xSenha);
  }

  const fValidaSenha2 = (xSenha2) => {
  	if(xSenha2.length > 0){
      if(xSenha2 !== senha){
        setSenha2Erro('Senha não Confere!');
      }else{
        setSenha2Erro('');
  		}
  	}
    setSenha2(xSenha2);
  }

  return (
    <div className="container-center">
      <div className="login shadow1">
        <div className="login-area">
          <img id="logo-cg" src={logo} alt="Cirurgia Guiada" />
        </div>

        <form onSubmit={handleSubmit}>
          <br />
          <input type="text" id="nome" autoFocus placeholder="Nome do Dentista" value={form.nome} onChange={(e) => updateForm({ nome: e.target.value })} />
          <div className="row">
            <div className="col-6">
              <input type="text" id="documento" placeholder="CPF/CNPJ" value={form.documento} onChange={(e) => updateForm({ documento: e.target.value })} />
            </div>
            <div className="col-6">
              <input type="text" id="login" placeholder="email@mail.com" value={login} onChange={(e) => fValidaLogin(e.target.value)} />
              <span className="text-danger">{loginErro}</span>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <input type="password" id="senha" placeholder="Senha" value={senha} onChange={(e) => fValidaSenha(e.target.value) } />
              <span className="text-danger">{senhaErro}</span>
            </div>
            <div className="col-6">
              <input type="password" id="senha2" placeholder="Confirme a Senha" value={senha2} onChange={(e) => fValidaSenha2(e.target.value) } />
              <span className="text-danger">{senha2Erro}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <input type="text" id="telefoneFixo" placeholder="Telefone Fixo" value={form.telefoneFixo} onChange={(e) => updateForm({ telefoneFixo: e.target.value })} />
            </div>
            <div className="col-6">
              <input type="text" id="telefoneCelular" placeholder="Celular" value={form.telefoneCelular} onChange={(e) => updateForm({ telefoneCelular: e.target.value })} />
            </div>
          </div>

          <div className="row">
            <div className="col-9">
              <input type="text" id="ruaNumero" placeholder="Rua e Número" value={form.ruaNumero} onChange={(e) => updateForm({ ruaNumero: e.target.value })} />
            </div>
            <div className="col-3">
              <input type="text" id="complemento" placeholder="Complemento" value={form.complemento} onChange={(e) => updateForm({ complemento: e.target.value })} />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <input type="text" id="bairro" placeholder="Bairro" value={form.bairro} onChange={(e) => updateForm({ bairro: e.target.value })} />
            </div>
            <div className="col-6">
              <input type="text" id="cidade" placeholder="Cidade" value={form.cidade} onChange={(e) => updateForm({ cidade: e.target.value })} />
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <input type="text" id="uf" placeholder="UF" value={form.uf} onChange={(e) => updateForm({ uf: e.target.value })} />
            </div>
            <div className="col-4">
            </div>

            <div className="col-3">
              <input type="text" id="cep" placeholder="CEP" value={form.cep} onChange={(e) => updateForm({ cep: e.target.value })} />
            </div>
            <div className="col-3">
            </div>
          </div>

          <br />
          <button type="submit" className="botAcessar shadow1">Cadastrar</button>
        </form>

        <Link to="/login">Já tenho Cadastro.</Link>
      </div>
    </div>
  );

}

export default LogIn;
