import React from "react";

import { Routes, Route } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Home from "./components/home";

import LogIn from "./components/login";
import Cadastro from "./components/cadastro";
import OsLista from "./components/os-lista";
import OsAltera from "./components/os-altera";
import OsInclui from "./components/os-inclui";
import OsImprime from "./components/os-imprime";
import UsuarioLista from "./components/usuario-lista";
import UsuarioAltera from "./components/usuario-altera";
import UsuarioInclui from "./components/usuario-inclui";
import RadiologiaLista from "./components/radiologia-lista";
import RadiologiaAltera from "./components/radiologia-altera";
import RadiologiaInclui from "./components/radiologia-inclui";
import KitLista from "./components/kit-lista";
import KitAltera from "./components/kit-altera";
import KitInclui from "./components/kit-inclui";
import SistemaModeloLista from "./components/sistema_modelo-lista";
import SistemaModeloAltera from "./components/sistema_modelo-altera";
import SistemaModeloInclui from "./components/sistema_modelo-inclui";
import TabelaLista from "./components/tabela-lista";
import TabelaAltera from "./components/tabela-altera";
import TabelaInclui from "./components/tabela-inclui";
import MensagemLista from "./components/mensagem-lista";
import MensagemAltera from "./components/mensagem-altera";
import MensagemInclui from "./components/mensagem-inclui";
import FaturamentoLista from "./components/faturamento-lista";
import FaturaOs from "./components/fatura-os";
import Requisicao from "./components/requisicao";

import './components/style/home.css';

const App = () => {
  return (
      <div className="app">
        <ToastContainer autoClose={3000} />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<LogIn />} />
          <Route exact path="/cadastro" element={<Cadastro />} />
          <Route exact path="/os-lista" element={<OsLista />} isPrivate />
          <Route exact path="/os-altera/:id" element={<OsAltera />} isPrivate />
          <Route exact path="/os-inclui" element={<OsInclui />} isPrivate />
          <Route exact path="/os-imprime/:id" element={<OsImprime />} isPrivate />
          <Route exact path="/usuario-lista" element={<UsuarioLista />} isPrivate />
          <Route exact path="/usuario-altera/:id" element={<UsuarioAltera />} isPrivate />
          <Route exact path="/usuario-inclui" element={<UsuarioInclui />} isPrivate />
          <Route exact path="/radiologia-lista" element={<RadiologiaLista />} isPrivate />
          <Route exact path="/radiologia-altera/:id" element={<RadiologiaAltera />} isPrivate />
          <Route exact path="/radiologia-inclui" element={<RadiologiaInclui />} isPrivate />
          <Route exact path="/kit-lista" element={<KitLista />} isPrivate />
          <Route exact path="/kit-altera/:id" element={<KitAltera />} isPrivate />
          <Route exact path="/kit-inclui" element={<KitInclui />} isPrivate />
          <Route exact path="/sistema_modelo-lista" element={<SistemaModeloLista />} isPrivate />
          <Route exact path="/sistema_modelo-altera/:id" element={<SistemaModeloAltera />} isPrivate />
          <Route exact path="/sistema_modelo-inclui" element={<SistemaModeloInclui />} isPrivate />
          <Route exact path="/tabela-lista" element={<TabelaLista />} isPrivate />
          <Route exact path="/tabela-altera/:id" element={<TabelaAltera />} isPrivate />
          <Route exact path="/tabela-inclui" element={<TabelaInclui />} isPrivate />
          <Route exact path="/mensagem-lista" element={<MensagemLista />} isPrivate />
          <Route exact path="/mensagem-altera/:id" element={<MensagemAltera />} isPrivate />
          <Route exact path="/mensagem-inclui" element={<MensagemInclui />} isPrivate />
          <Route exact path="/requisicao" element={<Requisicao />} isPrivate />
          <Route exact path="/faturamento-lista" element={<FaturamentoLista />} isPrivate />
          <Route exact path="/fatura-os/:id" element={<FaturaOs />} isPrivate />
        </Routes>
      </div>
  );
};

export default App;
