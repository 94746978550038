import React from "react";

import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import AnimationRevealPage from "../home/helpers/AnimationRevealPage.js";
import Hero from "../home/components/hero/BackgroundAsImage.js";
import Features from "../home/components/features/DashedBorderSixFeatures";
import Intro from "../home/components/features/TwoColSingleFeatureWithStats.js";
import MainFeature from "../home/components/features/TwoColSingleFeatureWithStats2.js";
//import MainFeature2 from "../home/components/features/TwoColWithTwoFeaturesAndButtons.js";
import Testimonial from "../home/components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "../home/components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "../home/components/forms/TwoColContactUsWithIllustration.js";
import Footer from "../home/components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "../home/images/customer-support-illustration.svg";

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

import './style/home.css';

export default function Home() {

  return (
    <AnimationRevealPage>   
      
      <div className="top">
        <WhatsAppWidget
          phoneNumber="+554188321888"
          companyName="Cirurgia Guiada"
          message="Como podemos ajudar?"
          inputPlaceHolder="Digite sua mensagem..."
          replyTimeText= ""
        />  
      </div>

      <Hero className="baixo"/>

      <Intro className="baixo"/>
      <MainFeature className="baixo"/>
      <Features className="baixo" />
        
{/* 
      <div className="testim">
        <Testimonial className="baixo testim"
          subheading="Confira os depoimentos de nossos clientes e estudos de caso com situações reais de sucesso. EXPERIÊNCIAS REAIS QUE FAZEM A DIFERENÇA"
          heading={
            <></>
          }
          description=""
          testimonials={[
            {
              imageSrc:
                "./fg.jpg",
              profileImageSrc:
                "https://cirurgiaguiada.net/fg.jpeg",
              quote:
                "Sempre obtive os resultados esperados, desde a qualidade do material como a atenção que a empresa proporcionou para todos da nossa equipe.",
              customerName: "Fabiano Galina - Cirugião Bucomaxilofacial",
              customerTitle: ""
            },
            {
              imageSrc:
                "./fg.jpg",
              profileImageSrc:
                "https://cirurgiaguiada.net/jmp.jpeg",
              quote:
                "Realizei cirurgias guiadas para instalação de implantes convencionais e zigomáticos, todas feitas com excelência graças aos guias cirúrgicos, e prototipagem óssea para planejamento de cirurgias.",
              customerName: "José Maurício Perussolo - Cirurgião Dentista",
              customerTitle: ""
            }
          ]}
          textOnLeft={true}
        />
      </div>
*/}

      <FAQ className="baixo"
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Alguma Dúvida ?
          </>
        }
      />

      <ContactUsForm className="baixo" />
    </AnimationRevealPage>
  )
}
