import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { toast } from 'react-toastify';

import ReactHTMLDatalist from "react-html-datalist";

import { AiOutlinePlus } from "react-icons/ai";
import { IconContext } from "react-icons";

import './style/os-inclui.css';

import Sidebar from './sidebar';

let _idOS;
let aRadiologias = [];
let _radiologiaId = "";
let _itens = [];

let _radiologiaNome = "";
let _tipoUsuario;

export default function Create() {
  const [detalheRadiologia, setDetalheRadiologia] = useState({ radiologia: {nome: "" }});

  const trataRadiologia = e => {
    setDetalheRadiologia({ ...detalheRadiologia, [e.target.name]: e.target.value });

    if(e.target.value){
      _radiologiaNome = e.target.text;
      _radiologiaId = e.target.value;
    }
  };

  let _idUsuario = getStorage("_idUsuario");

 let optionsK = [];
 let optionsS = [];
 let optionsM = [];
 let eEnvioParaRadiologia;

 const [records, setRecords] = useState([]);

 const [form, setForm] = useState({
   servicoOpcoes: null,
   nomePaciente: "",
   idOS: parseInt(Math.random() * 100000),
   dataOS: new Date(),
   planoTratamento: "",
   somenteProjeto: false,
   urgente: false,
   kit: null,
   sistema: null,
   modelo: null,
   abrangenciaOpcoes: null,
   tipoMaterial: "",
   radiologia: {nome: ""},
   placeholder: ""
 });
 const navigate = useNavigate();

 function novaRadiologia(a) {
   a.preventDefault();

   let eleAreaNovaRadiologia = document.getElementById('area-nova-radiologia');
   let eleTxtRadiologiaNome = document.getElementById('txtRadiologiaNome');
   eleAreaNovaRadiologia.style.visibility = "visible";
   eleAreaNovaRadiologia.style.display = "block";
   eleTxtRadiologiaNome.focus();
 }

  function updEnvioLab(prm) {
    if(prm === true) {
      document.getElementById("possuiExame").checked = false; // Check the checkbox

      eEnvioParaRadiologia = true;
      document.getElementById('dataEnvioParaRadiologia').value = new Date().toLocaleDateString('pt-BR');
      document.getElementsByName('radiologia.nome')[0].focus();
    }else{
      eEnvioParaRadiologia = false;
    }
  }

  function geraRequisicao(url) {
//    e.preventDefault();
    window.localStorage.setItem('_idOS', document.getElementById('idOS').value);
    window.localStorage.setItem('_nomePaciente', document.getElementById('nomePaciente').value);
    window.localStorage.setItem('_dentista', getStorage("_usuario"));
    window.localStorage.setItem('_dentistaTelefone', getStorage("_telefone"));
    window.localStorage.setItem('_servicoOpcoes', form.servicoOpcoes);
    if( form.servicoOpcoes === "servicoCirurgiaGuiada" ){
      let arquivo = "";
      if( form.tipoOpcoes === "arcoTotal" ){
        arquivo = "total";
      }else{
        if( form.tipoOpcoes === "implantesUnitarios" ){
          arquivo = "parcial";
        }
      }
      window.localStorage.setItem('_nomeServico', "Cirurgia Guiada");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/cirurgia-guiada-" + arquivo + ".pdf");
    }
    if( form.servicoOpcoes === "servicoPrototipagemOssea" ){
      window.localStorage.setItem('_nomeServico', "Prototipagem Óssea");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/prototipagem-ossea.pdf");
    }
    if( form.servicoOpcoes === "servicoCirurgiaOrtognatica" ){
      window.localStorage.setItem('_nomeServico', "Cirurgia Ortognática");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/cirurgia-ortognatica.pdf");
    }
    if( form.servicoOpcoes === "servicoEnceramentoDigital" ){
      window.localStorage.setItem('_nomeServico', "Enceramento Digital");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/enceramento-digital.pdf");
    }
    if( form.servicoOpcoes === "servicoGuiaPeriodontal" ){
      window.localStorage.setItem('_nomeServico', "Guia Periodontal");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/guia-periodontal.pdf");
    }
    if( form.servicoOpcoes === "servicoGuiaEndodontico" ){
      window.localStorage.setItem('_nomeServico', "Guia Endodôntico");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/guia-endodontico.pdf");
    }
    if( form.servicoOpcoes === "servicoPlacaMiorrelaxante" ){
      window.localStorage.setItem('_nomeServico', "Placa Miorrelaxante");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/placa-miorrelaxante.pdf");
    }
    if( form.servicoOpcoes === "servicoModeloImpresso" ){
      window.localStorage.setItem('_nomeServico', "Modelo Impresso");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/modelo-impresso.pdf");
    }
    if( form.servicoOpcoes === "servicoOutro" ){
      window.localStorage.setItem('_nomeServico', "Outros Serviços");
      window.localStorage.setItem('_linkQrCode', "https://cirurgiaguiada.net/outros.pdf");
    }

    let _radiologiaChave = document.getElementsByName('radiologia.nome')[0].value;
    let _radiologiaDescricao = "";
    let _radiologiaTelefone = "";
    let _radiologiaRuaNumero = "";
    let _radiologiaComplemento = "";
    let _radiologiaBairro = "";
    if(document.getElementById('txtRadiologiaNome').value.length === 0){
      for (let r = 0; r < aRadiologias.length; r++) {
        if(aRadiologias[r].uf + " " + aRadiologias[r].cidade + " " + aRadiologias[r].descricao === _radiologiaChave){
          if(aRadiologias[r].descricao){
            _radiologiaDescricao = aRadiologias[r].descricao;
          }
          if(aRadiologias[r].telefone){
            _radiologiaTelefone = aRadiologias[r].telefone;
          }
          if(aRadiologias[r].ruaNumero){
            _radiologiaRuaNumero = aRadiologias[r].ruaNumero;
          }
          if(aRadiologias[r].complemento){
            _radiologiaComplemento = aRadiologias[r].complemento;
          }
          if(aRadiologias[r].bairro){
            _radiologiaBairro = aRadiologias[r].bairro;
          }
        }
      }
    }else{
      _radiologiaDescricao = document.getElementById('txtRadiologiaNome').value;
      _radiologiaTelefone = document.getElementById('txtRadiologiaTelefone').value;
      _radiologiaRuaNumero = document.getElementById('txtRadiologiaRuaNumero').value;
      _radiologiaComplemento = document.getElementById('txtRadiologiaComplemento').value;
      _radiologiaBairro = document.getElementById('txtRadiologiaBairro').value;
    }

    window.localStorage.setItem('_radiologiaDescricao', _radiologiaDescricao);
    window.localStorage.setItem('_radiologiaTelefone', _radiologiaTelefone);
    window.localStorage.setItem('_radiologiaRuaNumero', _radiologiaRuaNumero);
    window.localStorage.setItem('_radiologiaComplemento', _radiologiaComplemento);
    window.localStorage.setItem('_radiologiaBairro', _radiologiaBairro);

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

    gravaOS(0, 1);
  }

 function getStorage(prm) {
   let _prm = window.localStorage.getItem(prm);
   if(_prm === "null"){
     navigate("/login");
   }else{
     if(_prm) {
       return _prm;
     }else{
       navigate("/login");
     }
   }
 }

 let aItens = [];

 useEffect(() => {

   async function getRecords() {
      _tipoUsuario = getStorage("_tipoUsuario");
      console.log(_tipoUsuario)
      if(_tipoUsuario !== "dentista"){
        let eleDentista = document.getElementById('dentista-div');
        eleDentista.style.visibility = "visible";
        eleDentista.style.display = "block";
     
        const responseUsuario = await fetch("https://www.zaping.io:8448/usuario-lista/" + _tipoUsuario + "/" + _idUsuario);
        if (!responseUsuario.ok) {
          const message = `ERRO: ${responseUsuario.statusText}`;
          window.alert(message);
          return;
        }
        
        const recordsUsuario = await responseUsuario.json();
        
        let indexDentista = 0;
        let optionsDentista = [];
        const selectDentista = document.getElementById('dentista');
        let optDentista = document.createElement('option');
        optDentista.value = 0;
        optDentista.textContent = '';
        selectDentista.appendChild(optDentista);  
        let d = 0;
        for (let u = 1; u < recordsUsuario.length+1; u++) {
          if(recordsUsuario[u-1].nome.length > 0){
            if(recordsUsuario[u-1].tipoUsuario === "admin" || recordsUsuario[u-1].tipoUsuario === "dentista"){
              optDentista = document.createElement('option');
              optDentista.value = recordsUsuario[u-1]._id;;
              optDentista.textContent = recordsUsuario[u-1].nome;
              selectDentista.appendChild(optDentista);
              d++;
            }
          }
        }
      }
          
     const responseLab = await fetch("https://www.zaping.io:8448/radiologia-lista/planejador/" + _idUsuario);

     if (!responseLab.ok) {
       const message = `ERRO: ${responseLab.statusText}`;
       window.alert(message);
       return;
     }

     const recordsLab = await responseLab.json();
     aRadiologias = recordsLab;
     aItens = recordsLab;
     _itens = [];
     let item = {};
     for (let r = 0; r < aItens.length; r++) {
       item = {text: aItens[r].uf + " " + aItens[r].cidade + " " + aItens[r].descricao,
               value: aItens[r]._id}
       _itens.push(item);
     }

     const response = await fetch("https://www.zaping.io:8448/kit-sistema_modelo-lista");

     if (!response.ok) {
       const message = `ERRO: ${response.statusText}`;
       window.alert(message);
       return;
     }

     const records = await response.json();
     setRecords(records);

     const selectKit = document.getElementById('kit');
     let optKit = document.createElement('option');
     optKit.value = 0;
     optKit.textContent = '';
     selectKit.appendChild(optKit);
     let i = 1;
     for (i = 1; i < records[0].kit.length+1; i++) {
         optKit = document.createElement('option');
         optKit.value = i;
         optKit.textContent = records[0].kit[i-1].descricao;
         selectKit.appendChild(optKit);
     }
     document.querySelectorAll('#kit > option').forEach((option) => {
         if (optionsK.includes(option.value)) option.remove()
         else optionsK.push(option.value)
     })

     optionsS = [];
     const selectSistema = document.getElementById('sistema');
     let optSistema = document.createElement('option');
     optSistema.value = 0;
     optSistema.textContent = '';
     selectSistema.appendChild(optSistema);
     for (let is = 1; is < records[0].sistema.length+1; is++) {
         optSistema = document.createElement('option');
         optSistema.value = is;
         optSistema.textContent = records[0].sistema[is-1].sistema;
         selectSistema.appendChild(optSistema);
     }
     document.querySelectorAll('#sistema > option').forEach((optionS) => {
         if (optionsS.includes(optionS.value)) optionS.remove()
         else optionsS.push(optionS.value)
     })

   }
   document.getElementById("idOS").disabled = true;

   getRecords();

 }, [records.length]);

 function trataPossuiExame(prm) {
    if(prm === true) {
      document.getElementById('observacaoRadiologia').focus();
      document.getElementById("envioParaRadiologia").checked = false; // Check the checkbox
    }
 }
 
 function atualizaLink(campo, conteudoLink, linkContainer) {
  if (conteudoLink) {
    const linkElement = document.createElement('a');
    linkElement.href = conteudoLink;
    linkElement.target = "_blank";
    linkElement.textContent = conteudoLink;
    document.getElementById(linkContainer).appendChild(linkElement);
    document.getElementById(campo).style.display = 'none';    
  }
} 

 // These methods will update the state properties.
 function updateForm(value) {
   console.log(value)
   let elemCG = document.getElementById('div-cg');
   let elemPO = document.getElementById('div-po');

   let prm;
   if( value.servicoOpcoes ) {
     prm = value.servicoOpcoes;
   }else{
     prm = value.param;
   }
   if( prm === "servicoCirurgiaGuiada" ){
     elemCG.style.visibility = "visible";
     elemCG.style.display = "block";

     elemPO.style.visibility = "hidden";
     elemPO.style.display = "none";

     form.placeholder = "descrever o mais detalhado possível, como região a ser reabilitada, se possui extrações indicadas, tipo de componente que deseja utilizar, particularidades etc...";
   }else{
     if( prm === "servicoPrototipagemOssea" ){
       elemCG.style.visibility = "hidden";
       elemCG.style.display = "none";
       elemPO.style.visibility = "visible";
       elemPO.style.display = "block";

       form.placeholder = "descrever o mais detalhado possível para que possamos saber as áreas que necessitam maior fidelidade.";
     }else{
       elemCG.style.visibility = "hidden";
       elemCG.style.display = "none";
       elemPO.style.visibility = "hidden";
       elemPO.style.display = "none";

       if( prm === "servicoCirurgiaOrtognatica" ) {
         form.placeholder = "descrever o mais detalhado possível para direcionar o planejamento.";
       }
       if( prm === "servicoEnceramentoDigital" ) {
         form.placeholder = "descrever o mais detalhado possível para direcionar o planejamento, como quais elementos serão encerados, se espera alguma mudança em DVO, se o paciente espera grande aumento de volume dentario etc.";
       }
       if( prm === "servicoGuiaPeriodontal" ) {
         form.placeholder = "descrever o mais detalhado possível para direcionar o planejamento, como quais elementos serão operados, se já possui enceramento digital, se espera osteotomia ou somente gengivoplastia etc.";
       }
       if( prm === "servicoGuiaEndodontico" ) {
         form.placeholder = "descrever o mais detalhado possível para direcionar o planejamento, como quais dentes apresentam calcificação, se já foi tentado tratamento endodôntico convencional etc.";
       }
       if( prm === "servicoPlacaMiorrelaxante" ) {
         form.placeholder = "descrever o mais detalhado possível para direcionar o planejamento, como se há alguma preferencia pelo modelo da placa ou alguma particularidade.";
       }
       if( prm === "servicoModeloImpresso" ) {
         form.placeholder = "descrever o mais detalhado possível qual é o objetivo do modelo, se é para estudo, trabalho, se necessita ser zocalado etc.";
       }
       if( prm === "servicoOutro" ) {
         form.placeholder = "descrever o mais detalhado possível qual é o objetivo do projeto.";
       }

     }
   }

   if( value.sistema ){
     document.querySelectorAll('#modelo > option').forEach((optionM) => {
       optionM.remove()
     })

      optionsM = [];
      const selectModelo = document.getElementById('modelo');
      let optModelo = document.createElement('option');
      optModelo.value = 0;
      optModelo.textContent = "";
      selectModelo.appendChild(optModelo);
      for (let m = 0; m < records[0].sistema[value.sistema - 1].modelo.length; m++) {
          optModelo = document.createElement('option');
          optModelo.value = m;
          optModelo.textContent = records[0].sistema[value.sistema - 1].modelo[m];
          selectModelo.appendChild(optModelo);
      }
   }

   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 // This function will handle the submission.
  async function gravaOS(e, p) {
    if(!p){
      e.preventDefault();
    }

    if(form.nomePaciente === ""){
      toast.error('Favor preencher o Nome do Paciente!');
      document.getElementById("nomePaciente").focus();
    }else{
      if(form.planoTratamento === ""){
        toast.error('Favor preencher o Plano de Tratamento!');
        document.getElementById("planoTratamento").focus();
      }else{

        form.idUsuarioDentista = _idUsuario;

        if(_tipoUsuario === "dentista"){
          form.idUsuarioDentista = _idUsuario;          
        }else{
          form.idUsuarioDentista = form.dentista
        }

        if(form.servicoOpcoes === "servicoCirurgiaGuiada"){
          var lKit = document.getElementById("kit");
          form.kit = lKit.options[lKit.options.selectedIndex].innerText;
          var lSistema = document.getElementById("sistema");
          form.sistema = lSistema.options[lSistema.options.selectedIndex].innerText;
          var lModelo = document.getElementById("modelo");
          form.modelo = lModelo.options[lModelo.options.selectedIndex].innerText;
        }
        if(document.getElementById("envioParaRadiologia").checked){
          form.envioParaRadiologia = document.getElementById("envioParaRadiologia").checked;
          form.dataEnvioParaRadiologia = new Date();
          if(_radiologiaId.length > 0){
            form.radiologia.id = _radiologiaId;
          }else{
            form.radiologia.id = "";
            if(document.getElementById("txtRadiologiaNome").value.length > 0){
              if(document.getElementById("txtRadiologiaNome").value){
                form.radiologia.nome = document.getElementById("txtRadiologiaNome").value;
              }else{
                form.radiologia.nome = "";
              }
              if(document.getElementById("txtRadiologiaTelefone").value){
                form.radiologia.telefone = document.getElementById("txtRadiologiaTelefone").value;
              }else{
                form.radiologia.telefone = "";
              }
              if(document.getElementById("txtRadiologiaRuaNumero").value){
                form.radiologia.ruaNumero = document.getElementById("txtRadiologiaRuaNumero").value;
              }else{
                form.radiologia.ruaNumero = "";
              }
              if(document.getElementById("txtRadiologiaComplemento").value){
                form.radiologia.complemento = document.getElementById("txtRadiologiaComplemento").value;
              }else{
                form.radiologia.complemento = "";
              }
              if(document.getElementById("txtRadiologiaBairro").value){
                form.radiologia.bairro = document.getElementById("txtRadiologiaBairro").value;
              }else{
                form.radiologia.bairro = "";
              }
              if(document.getElementById("txtRadiologiaCidade").value){
                form.radiologia.cidade = document.getElementById("txtRadiologiaCidade").value;
              }else{
                form.radiologia.cidade = "";
              }
              if(document.getElementById("txtRadiologiaUf").value){
                form.radiologia.uf = document.getElementById("txtRadiologiaUf").value;
              }else{
                form.radiologia.uf = "";
              }

            }
          }
          form.possuiExame = false;
        }else{
          if(document.getElementById("possuiExame").checked){
            form.possuiExame = document.getElementById("possuiExame").checked;
          }
        }

        form.placeholder = "";

        const novaOS = { ...form };

        await fetch("https://www.zaping.io:8448/os-inclui", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(novaOS),
        })

        .then(()=>{
          toast.success('Cadastro realizado com sucesso. Envie seu paciente à radiologia com a requisição. Cobre seu paciente para que realize os exames de imagem o quanto antes, e não se preocupe, você será notificado quando recebermos os arquivos de imagem!', {autoClose: false, closeOnClick: true});
        })
        .catch(error => {
          toast.error('Erro ao gravar OS!');
          console.log(error);
          return;
        });
        
        if(form.servicoOpcoes === "servicoCirurgiaGuiada"){
          document.getElementById('kit').selectedIndex = 0;
          document.getElementById('sistema').selectedIndex = 0;
          document.getElementById('somenteProjeto').checked = false;
          document.getElementById('urgente').checked = false;
        }

        setForm({
          servicoOpcoes: null,
          nomePaciente: "",
          idOS: parseInt(Math.random() * 100000),
          dataOS: new Date(),
          planoTratamento: "",
          somenteProjeto: false,
          urgente: false,
          kit: null,
          sistema: null,
          modelo: null,
          abrangenciaOpcoes: null,
          tipoMaterial: "",
          placeholder: ""
        });

        navigate("/os-lista");
      }
    }
  }

 // This following section will display the form that takes the input from the user.
 return (

   <div className="os-inclui">
     <Sidebar />

     <div className="iConteudo">
       <div className="iContainer">
         <form onSubmit={gravaOS}>
           <br />

           <div className="form-group">
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoCirurgiaGuiada"
                 value="servicoCirurgiaGuiada"
                 checked={form.servicoOpcoes === "servicoCirurgiaGuiada"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoCirurgiaGuiada" className="form-check-label">Cirurgia Guiada</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoPrototipagemOssea"
                 value="servicoPrototipagemOssea"
                 checked={form.servicoOpcoes === "servicoPrototipagemOssea"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoPrototipagemOssea" className="form-check-label">Prototipagem Óssea</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoCirurgiaOrtognatica"
                 value="servicoCirurgiaOrtognatica"
                 checked={form.servicoOpcoes === "servicoCirurgiaOrtognatica"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoCirurgiaOrtognatica" className="form-check-label">Cirurgia Ortognática</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoEnceramentoDigital"
                 value="servicoEnceramentoDigital"
                 checked={form.servicoOpcoes === "servicoEnceramentoDigital"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoCirurgiaOrtognatica" className="form-check-label">Enceramento Digital</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoGuiaPeriodontal"
                 value="servicoGuiaPeriodontal"
                 checked={form.servicoOpcoes === "servicoGuiaPeriodontal"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoGuiaPeriodontal" className="form-check-label">Guia Periodontal</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoGuiaEndodontico"
                 value="servicoGuiaEndodontico"
                 checked={form.servicoOpcoes === "servicoGuiaEndodontico"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoGuiaEndodontico" className="form-check-label">Guia Endodôntico</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoPlacaMiorrelaxante"
                 value="servicoPlacaMiorrelaxante"
                 checked={form.servicoOpcoes === "servicoPlacaMiorrelaxante"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoPlacaMiorrelaxante" className="form-check-label">Placa Miorrelaxante</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoModeloImpresso"
                 value="servicoModeloImpresso"
                 checked={form.servicoOpcoes === "servicoModeloImpresso"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoModeloImpresso" className="form-check-label">Modelo Impresso</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="servicoOpcoes"
                 id="servicoOutro"
                 value="servicoOutro"
                 checked={form.servicoOpcoes === "servicoOutro"}
                 onChange={(e) => updateForm({ servicoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label htmlFor="servicoOutro" className="form-check-label">Outro</label>
             </div>
           </div>
           <br />

           <div className="row cliente" id="dentista-div">
              <div className="col-8">
                <label htmlFor="dentista" className="form-check-label">Cliente</label>
                <select id="dentista" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                  onChange={(e) => updateForm({ dentista: e.target.value, param: form.servicoOpcoes })}>
                </select>
              </div>
           </div>
           
           <div className="row">
              <div className="col-8">
                 <div className="form-group">
                   <label htmlFor="nomePaciente">Paciente</label>
                   <input
                     type="text"
                     className="form-control"
                     id="nomePaciente"
                     value={form.nomePaciente}
                     onChange={(e) => updateForm({ nomePaciente: e.target.value, param: form.servicoOpcoes })}
                   />
                 </div>
              </div>

              <div className="col-4">
                 <div className="form-group idOS">
                   <label htmlFor="idOS">OS</label>
                   <input
                     type="text"
                     className="form-control idOS"
                     id="idOS"
                     value={form.idOS}
                     onChange={(e) => updateForm({ idOS: e.target.value, param: form.servicoOpcoes })}
                   />
                 </div>
              </div>
           </div>

           <br />
           <div className="form-group">
             <label htmlFor="planoTratamento">Plano de Tratamento</label>
             <textarea
               type="text"
               className="form-control"
               id="planoTratamento"
               placeholder={form.placeholder}
               value={form.planoTratamento}
               onChange={(e) => updateForm({ planoTratamento: e.target.value, param: form.servicoOpcoes })}
             />
           </div>

           <br />
           <div className="row">
             <div className="col-6">
               <div className="form-check form-check-inline">
                 <input
                   className="form-check-input"
                   type="checkbox"
                   id="somenteProjeto"
                   onChange={(e) => updateForm({ somenteProjeto: e.target.checked, param: form.servicoOpcoes })}
                 />
                 <label htmlFor="somenteProjeto" className="form-check-label">Somente projeto (sem impressão 3D)</label>
               </div>
             </div>

             <div className="col-6">
               <div className="form-check form-check-inline">
                 <input
                   className="form-check-input"
                   type="checkbox"
                   id="urgente"
                   onChange={(e) => updateForm({ urgente: e.target.checked, param: form.servicoOpcoes })}
                 />
                 <label htmlFor="urgente" className="form-check-label">Urgente (prazo preferencial, acrécimo de 20%)</label>
               </div>
             </div>
           </div>

           <div id="div-po" className="esconde">
             <br />
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="abrangenciaOpcoes"
                 id="maxilaSomenteRebordo"
                 value="maxilaSomenteRebordo"
                 checked={form.abrangenciaOpcoes === "maxilaSomenteRebordo"}
                 onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblMaxilaSomenteRebordo" htmlFor="maxilaSomenteRebordo" className="form-check-label">Maxila Somente Rebordo</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="abrangenciaOpcoes"
                 id="maxilaEstendidaZigomatico"
                 value="maxilaEstendidaZigomatico"
                 checked={form.abrangenciaOpcoes === "maxilaEstendidaZigomatico"}
                 onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblMaxilaEstendidaZigomatico" htmlFor="maxilaEstendidaZigomatico" className="form-check-label">Maxila Estendida p/Zigomático</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="abrangenciaOpcoes"
                 id="mandibulaSomenteRebordo"
                 value="mandibulaSomenteRebordo"
                 checked={form.abrangenciaOpcoes === "mandibulaSomenteRebordo"}
                 onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblMandibulaSomenteRebordo" htmlFor="mandibulaSomenteRebordo" className="form-check-label">Mandíbula somente rebordo</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="abrangenciaOpcoes"
                 id="mandibulaEstendidaRamoCondilo"
                 value="mandibulaEstendidaRamoCondilo"
                 checked={form.abrangenciaOpcoes === "mandibulaEstendidaRamoCondilo"}
                 onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblMandibulaEstendidaRamoCondilo" htmlFor="mandibulaEstendidaRamoCondilo" className="form-check-label">Mandíbula estendida p/ramo e côndilo</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="abrangenciaOpcoes"
                 id="outras"
                 value="outras"
                 checked={form.abrangenciaOpcoes === "outras"}
                 onChange={(e) => updateForm({ abrangenciaOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblOutras" htmlFor="outras" className="form-check-label">Outras</label>
             </div>

             <br />
             <br />
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="tipoMaterial"
                 id="resina"
                 value="resina"
                 checked={form.tipoMaterial === "resina"}
                 onChange={(e) => updateForm({ tipoMaterial: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblResina" htmlFor="resina" className="form-check-label">Resina</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="tipoMaterial"
                 id="filamento"
                 value="filamento"
                 checked={form.tipoMaterial === "filamento"}
                 onChange={(e) => updateForm({ tipoMaterial: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblFilamento" htmlFor="filamento" className="form-check-label">Filamento</label>
             </div>
           </div>

           <div id="div-cg" className="esconde">
             <br />
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="tipoOpcoes"
                 id="arcoTotal"
                 value="arcoTotal"
                 checked={form.tipoOpcoes === "arcoTotal"}
                 onChange={(e) => updateForm({ tipoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblArcoTotal" htmlFor="arcoTotal" className="form-check-label">Arco Total</label>
             </div>
             <div className="form-check form-check-inline">
               <input
                 className="form-check-input"
                 type="radio"
                 name="tipoOpcoes"
                 id="implantesUnitarios"
                 value="implantesUnitarios"
                 checked={form.tipoOpcoes === "implantesUnitarios"}
                 onChange={(e) => updateForm({ tipoOpcoes: e.target.value, param: form.servicoOpcoes })}
               />
               <label id="lblImplantesUnitarios" htmlFor="implantesUnitarios" className="form-check-label">Implantes Unitários</label>
             </div>

             <br />
             <br />
             <label id="lblKit" htmlFor="kit" className="form-check-label">Kit Cirurgico Guiado</label>
             <select id="kit" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
               onChange={(e) => updateForm({ kit: e.target.value, param: form.servicoOpcoes })}>
             </select>
             <div className="row">
               <div className="col-6">
                 <label id="lblSistema" htmlFor="sistema" className="form-check-label">Sistema</label>
                 <select id="sistema" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                 onChange={(e) => updateForm({ sistema: e.target.value, param: form.servicoOpcoes })}>
                 </select>
               </div>

               <div className="col-6">
                 <label id="lblModelo" htmlFor="modelo" className="form-check-label">Modelo</label>
                 <select id="modelo" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                 onChange={(e) => updateForm({ modelo: e.target.value, param: form.servicoOpcoes })}>
                 </select>
               </div>
             </div>
           </div>

           <div className="row">
             <div className="col-6 pad-top0">
               <br />
               <div className="form-check form-check-inline">
                 <input
                   className="form-check-input"
                   type="checkbox"
                   id="envioParaRadiologia"
                   checked={form.envioParaRadiologia}
                   onChange={(e) => updEnvioLab(e.target.checked)}
                 />
                 <label htmlFor="envioParaRadiologia" className="form-check-label">Envio p/ Radiologia</label>

                 <input
                   type="text"
                   className="form-control dataEnvio"
                   id="dataEnvioParaRadiologia"
                   value={form.dataEnvioParaRadiologia}
                 />

               </div>
             </div>

             <div className="col-5 pad-top-10">
               <br />
               <label htmlFor="radiologia.nome" className="form-check-label">Radiologia</label>
               <ReactHTMLDatalist
                     name={"radiologia.nome"}
                     id={"radiologia.nome"}
                     onChange={trataRadiologia}
                     classNames={"form-select form-select-lg mb-3"}
                     options={_itens}
               />

               <div id="area-nova-radiologia" className="area-nova-radiologia esconde shadow1">
                 <input
                   type="text"
                   className="form-control"
                   placeholder="Nome nova Radiologia"
                   id="txtRadiologiaNome"
                 />
                 <input
                   type="text"
                   className="form-control"
                   placeholder="Telefone"
                   id="txtRadiologiaTelefone"
                 />
                 <input
                   type="text"
                   className="form-control"
                   placeholder="Rua e Número"
                   id="txtRadiologiaRuaNumero"
                 />
                 <input
                   type="text"
                   className="form-control"
                   placeholder="Complemento"
                   id="txtRadiologiaComplemento"
                 />
                 <input
                   type="text"
                   className="form-control"
                   placeholder="Bairro"
                   id="txtRadiologiaBairro"
                 />
                 <div className="row">
                   <div className="col-9">
                     <input
                       type="text"
                       className="form-control"
                       placeholder="Cidade"
                       id="txtRadiologiaCidade"
                     />
                   </div>
                   <div className="col-3">
                     <input
                       type="text"
                       className="form-control"
                       placeholder="UF"
                       id="txtRadiologiaUf"
                     />
                   </div>
                 </div>
               </div>
             </div>

             <div className="col-1 pad-top0 pad-bot-0">
               <div className="butInclui pad-top0 pad-bot-0">
                 <IconContext.Provider value={{ size: "1.5em"}}>
                   <button className="btn pad-top0 pad-bot-0" onClick={(a) => novaRadiologia(a)}>
                     <AiOutlinePlus />
                   </button>
                 </IconContext.Provider>
               </div>
             </div>

           </div>

           <div className="row">
             <div className="col-6">
               <input
                 type="button"
                 value="Gerar Requisição"
                 className="btn btn-primary shadow1"
                 onClick={(e) => geraRequisicao('/requisicao')}
               />
             </div>
           </div>

           <div className="row">
            <div className="col-3">
              <div className="form-check form-check-inline">
                <br />
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="possuiExame"
                  checked={form.possuiExame}
                  onChange={(e) => trataPossuiExame(e.target.checked)}
                />
                <label htmlFor="possuiExame" className="form-check-label">Possui Exame</label>
              </div>
            </div>

            <div className="col-9">
            </div>

          </div>           

           <div className="col-12 pad-top">
              <label htmlFor="observacaoRadiologia">Link Exames de Imagem</label>
              <textarea
                type="text"
                className="form-control"
                id="observacaoRadiologia"
                value={form.observacaoRadiologia}
                onBlur={(e) => atualizaLink("observacaoRadiologia", e.target.value, "linkContainer")}
                onChange={(e) => updateForm({ observacaoRadiologia: e.target.value, param: form.servicoOpcoes })}
              />
              <div id="linkContainer"></div>
            </div>

           <div className="form-group butSalvar">
             <br />
             <br />
             <input
               type="submit"
               value="Salvar"
               className="btn btn-primary shadow1"
             />
             <br />
             <br />
           </div>

         </form>
       </div>
     </div>

   </div>

 );
}
