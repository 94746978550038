import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

import './style/kit-altera.css';

import Sidebar from './sidebar';

export default function Edit() {

   let [form, setForm] = useState({
     eventoTxt: "",
     para: "",
     templateId: "",
     param: []
   });

   const params = useParams();
   const navigate = useNavigate();
   let _id = params.id.toString();

   function getStorage(prm) {
     let _prm = window.localStorage.getItem(prm);
     if(_prm) {
       return _prm;
     }else{
       toast.error('Favor Logar Novamente!');
       navigate("/login");
     }
   }

   useEffect(() => {

     async function fetchData() {
       console.log(_id)

       const response = await fetch(`https://www.zaping.io:8448/mensagem/` + _id);

       if (!response.ok) {
         toast.error('ERRO!');
         return;
       }

       let record = await response.json();

       if (!record) {
         toast.error('Mensagem Padrão não Encontrada!');
         navigate("/mensagem-lista");
         return;
       }
       setForm(record[0]);
     }

     fetchData();

     return;
   }, [params.id, navigate]);

   function updateForm(value) {

     return setForm((prev) => {
       return { ...prev, ...value };
     });
   }

   async function onClickSubmit() {
     let aParam = form.param.split(",");
     const alteraMensagem = {
       eventoTxt: form.eventoTxt,
       para: form.para,
       templateId: form.templateId,
       param: aParam
     }
       // This will send a post request to update the data in the database.
     await fetch(`https://www.zaping.io:8448/mensagem-altera/` + _id, {
       method: "POST",
       body: JSON.stringify(alteraMensagem),
       headers: {
         'Content-Type': 'application/json'
       },
     });
     toast.success('Mensagem Padrão Alterada com Sucesso!');

     navigate("/mensagem-lista");
   }

   // This following section will display the form that takes input from the user to update the data.
   return (

      <div className="usuario-inclui">
        <Sidebar />

        <div className="iConteudo">
          <div className="iContainer">
            <br />
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="eventoTxt">Evento</label>
                    <input
                      type="text"
                      className="form-control"
                      id="eventoTxt"
                      value={form.eventoTxt}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="para">Para</label>
                    <input
                      type="text"
                      className="form-control"
                      id="para"
                      value={form.para}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="templateId">Template Id (Whatsapp)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="templateId"
                      value={form.templateId}
                      onChange={(e) => updateForm({ templateId: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="param">Parâmetros (separado por vírgula)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="param"
                      value={form.param}
                      onChange={(e) => updateForm({ param: e.target.value })}
                    />
                  </div>
                </div>
              </div>

               <div className="form-group butSalvar">
                 <br />
                 <input
                   type="submit"
                   value="Salvar"
                   className="btn btn-primary shadow1"
                   onClick={(e) => onClickSubmit()}
                 />
               </div>

          </div>
        </div>

      </div>

  );

}
